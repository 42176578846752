import qs from 'qs';
import dayjs from 'dayjs';
import CONFIG from '@/config';
import { Logger } from '@/utils/Logger';
import { isEmpty } from 'lodash-es';
// constant tools
export function constantToMap(constant, keyName = 'key') {
  const map = Object.keys(constant).reduce((map, name) => {
    const mapKey = constant[name][keyName];
    map[mapKey] = constant[name];
    return map;
  }, {});
  return Object.freeze(map);
}

export function constantToList(constant) {
  const list = Object.values(constant);
  return Object.freeze(list);
}

export function constantToKeyMap(constant, keyName = 'key') {
  const keyMap = Object.keys(constant).reduce((map, name) => {
    map[name] = constant[name][keyName];
    return map;
  }, {});
  return Object.freeze(keyMap);
}

// 根据数组内某个key分组 => 转化为map  {a:[],b:[],c:[]}形式
export function groupingByKey(arr = [], key) {
  return arr.reduce((groupMap, item) => {
    groupMap[item[key]] = groupMap[item[key]] || [];
    groupMap[item[key]].push(item);
    return groupMap;
  }, {});
}

export function urlParse(url) {
  let obj = {},
    index = url.indexOf('?'),
    queryStr = url.substr(index + 1);

  if (index != -1) {
    obj = qs.parse(queryStr);
  }

  return obj;
}

export function timestampToDateString(timestamp) {
  if (!timestamp) return '';
  let time = timestamp;
  let dateString = '';
  const dayTimestamp = 24 * 60 * 60 * 1000;
  const hoursTimestamp = 60 * 60 * 1000;
  const minutesTimestamp = 60 * 1000;
  const secondsTimestamp = 1000;

  if (timestamp > dayTimestamp) {
    dateString += `${Math.floor(time / dayTimestamp)}天`;
    time = timestamp % dayTimestamp;
  }

  if (timestamp > hoursTimestamp) {
    dateString += `${`${Math.floor(time / hoursTimestamp)}`.padStart(2, 0)}时`;
    time = timestamp % hoursTimestamp;
  }

  if (timestamp > minutesTimestamp) {
    dateString += `${`${Math.floor(time / minutesTimestamp)}`.padStart(
      2,
      0
    )}分`;
    time = timestamp % minutesTimestamp;
  }

  if (timestamp > secondsTimestamp) {
    dateString += `${`${Math.floor(time / secondsTimestamp)}`.padStart(
      2,
      0
    )}秒`;
  }
  return dateString;
}

export function gtmToTimestamp(gmtString) {
  if (!gmtString) return 0;
  return dayjs(gmtString).valueOf();
}

export function dateFormat(value, fmt = 'YYYY-MM-DD hh:mm:ss') {
  if (!value) return '';
  return dayjs(value).format(fmt);
}

/**获取导航栏高度 */
export function getNavbarHeight(height, systemInfo) {
  systemInfo = systemInfo || uni.getSystemInfoSync();

  // #ifdef APP-PLUS || H5
  return height ? height : 44;
  // #endif
  // #ifdef MP
  let sysHeight = systemInfo.platform == 'ios' ? 44 : 48;
  return height ? height : sysHeight;
  // #endif
}

/**获取当前页面url */
export function currentPage() {
  const [page] = getCurrentPages();
  return page ? `/${page.route}` : '';
}

export function timeUnitFormat(diffTime) {
  const day = parseInt(`${diffTime / 60 / 60 / 24}`);
  const hour = parseInt(`${(diffTime / 60 / 60) % 24}`);
  const min = parseInt(`${(diffTime / 60) % 60}`);
  const sec = parseInt(`${diffTime % 60}`);
  const dayStr = diffTime > 24 * 60 * 60 ? `${day}天` : '';
  const hourStr = diffTime > 60 * 60 ? `${`${hour}`.padStart(2, '0')}小时` : '';
  const minStr = diffTime > 60 ? `${`${min}`.padStart(2, '0')}分` : '';
  const secStr = `${`${sec}`.padStart(2, '0')}秒`;

  return `${dayStr}${hourStr}${minStr}${secStr}`;
}

/**打开webview */
export function openWeb(url) {
  if (CONFIG.isBrowser) {
    window.open(url);
  } else {
    plus.runtime.openWeb(url);
  }
}
/**打开第三方浏览器 */
export function openBrowser(url) {
  if (CONFIG.isBrowser) {
    window.open(url);
  } else {
    plus.runtime.openURL(url, () => {
      plus.runtime.openURL(
        url,
        (e) => {
          Logger.log('openURL 打开失败', e);
        },
        'com.android.browser'
      );
    });
  }
}

export function compareVersion(v1, v2) {
  if (v1 && v2) {
    let v1Array = String(v1).split('.');
    let v2Array = String(v2).split('.');
    let index = 0;

    const fill = (item, num, isJoin = true) => {
      const arr = Array.from({ length: num }).map(() => item);
      if (isJoin) return arr.join('');
      return arr;
    };

    if (v1Array.length > v2Array.length) {
      v2Array = v2Array.concat(
        fill('0', v1Array.length - v2Array.length, false)
      );
    } else {
      v1Array = v1Array.concat(
        fill('0', v2Array.length - v1Array.length, false)
      );
    }

    while (v1Array.length > index) {
      let item1 = v1Array[index];
      let item2 = v2Array[index];

      const diff = ~~item1 - ~~item2;
      if (diff < 0) {
        return false;
      }
      if (diff > 0) {
        return true;
      }
      index++;
    }

    return false;
  }
  return false;
}

export function debounce(func, wait = 500, immediate = false) {
  let timer = null;
  return function (...param) {
    if (timer) {
      clearTimeout(timer);
    }
    if (immediate) {
      const callNow = !timer;
      timer = setTimeout(() => {
        timer = null;
      }, wait);
      if (callNow) {
        func.apply(this, param);
      }
    } else {
      timer = setTimeout(() => {
        console.log('setTimeout');
        func.apply(this, param);
      }, wait);
    }
  };
}

export function buildParamsForUrl(params) {
  return Object.entries(params)
    .reduce((urlParams, [key, value]) => {
      if (typeof value === 'object') {
        urlParams.push(`${key}=${JSON.stringify(value)}`);
      } else {
        urlParams.push(`${key}=${value}`);
      }
      return urlParams;
    }, [])
    .join('&');
}


export function proRedirectTo(url, additionParams = {}) {
  const currentParams = new URLSearchParams(window.location.search);

  let params = {};
  currentParams.forEach((value, key) => {
      params[key] = value;
  });

  if (!isEmpty(additionParams)) {
      params = { ...params, ...additionParams };
  }

  const queryString = Object.keys(params)
      .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
      .join('&');
  const fullUrl = `${url}?${queryString}`;

  window.location.href = fullUrl;
}

export function calDivisor(dividend, divisor, decimalDigits = 2) {
  return divisor === 0 ? 0 : +(dividend / divisor).toFixed(decimalDigits);
}
