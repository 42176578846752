import { snakeCase } from 'lodash-es';

type MergeIntersection<A> = A extends infer T
  ? { [Key in keyof T]: T[Key] }
  : never;

type ToPropertyPrefix<N extends string = ''> = N extends '' ? '' : `${N}_`;

type ToProperty<
  Property extends string,
  N extends string = ''
> = `${ToPropertyPrefix<N>}${Property}`;

type StringSnakeCase<
  T extends string,
  Result extends string = '',
  Prev extends string = ''
> = T extends `${infer F}${infer REST}`
  ? Prev extends ''
    ? F extends '_'
      ? StringSnakeCase<REST, '', F>
      : StringSnakeCase<REST, Lowercase<F>, F>
    : Result extends ''
    ? StringSnakeCase<REST, Lowercase<F>, F>
    : F extends '_'
    ? StringSnakeCase<REST, Result, F>
    : F extends Uppercase<F>
    ? Prev extends Uppercase<F>
      ? StringSnakeCase<REST, `${Result}${Lowercase<F>}`, F>
      : StringSnakeCase<REST, `${Result}_${Lowercase<F>}`, F>
    : StringSnakeCase<REST, `${Result}${F}`, F>
  : Result;
type StringUppercaseSnakeCase<T extends string> = Uppercase<StringSnakeCase<T>>;
type ToSingleKeyValue<
  T extends Record<PropertyKey, any>,
  K extends keyof T,
  KA extends keyof T = never
> = T extends {
  readonly [Z in K]: infer F;
}
  ? F extends PropertyKey
    ? {
        readonly [Key in StringUppercaseSnakeCase<
          KA extends never ? F : T[KA]
        >]: T[K];
      }
    : never
  : never;

type ToKeyValue<
  T extends ReadonlyArray<Record<PropertyKey, any>>,
  K extends keyof T[number],
  KA extends keyof T[number] = never
> = T extends readonly [infer A, ...infer B]
  ? B['length'] extends 0
    ? ToSingleKeyValue<A, K, KA>
    : MergeIntersection<ToSingleKeyValue<A, K, KA> & ToKeyValue<B, K, KA>>
  : [];

type ToSingleKeyMap<
  T extends Record<PropertyKey, any>,
  K extends keyof T
> = T extends {
  readonly [Z in K]: infer F;
}
  ? K extends PropertyKey
    ? F extends PropertyKey
      ? {
          readonly [Key in F]: T;
        }
      : never
    : never
  : never;

type ToKeyMap<
  T extends Readonly<Record<PropertyKey, any>[]>,
  K extends keyof T[number]
> = T extends readonly [infer A, ...infer B]
  ? B['length'] extends 0
    ? ToSingleKeyMap<A, K>
    : MergeIntersection<ToSingleKeyMap<A, K> & ToKeyMap<B, K>>
  : [];

export type DefineConstantsValue<
  T extends ReadonlyArray<Record<PropertyKey, unknown>>,
  N extends string,
  K extends keyof T[number] = 'key',
  KA extends keyof T[number] = K
> = MergeIntersection<
  {
    [Key in N]: ToKeyValue<T, K, KA>;
  } & {
    [Key in ToProperty<'MAP', N>]: ToKeyMap<T, K>;
  } & {
    [Key in ToProperty<'LIST', N>]: T;
  }
>;

export function defineConstants<
  T extends ReadonlyArray<Record<PropertyKey, unknown>>,
  N extends string,
  K extends keyof T[number] = 'key',
  KA extends keyof T[number] = K
>(list: T, namespace: N, key: K = 'key' as K, keyAlias?: KA) {
  const prefix = namespace ? `${namespace}_` : '';
  if (!keyAlias) {
    keyAlias = key as unknown as KA;
  }
  return {
    [namespace]: list.reduce(
      (obj, item) => ({
        ...obj,
        [snakeCase(item[keyAlias] as string).toUpperCase()]: item[key],
      }),
      {}
    ),
    [`${prefix}MAP`]: list.reduce(
      (obj, item) => ({
        ...obj,
        [item[key] as string]: item,
      }),
      {}
    ),
    [`${prefix}LIST`]: list,
  } as MergeIntersection<
    {
      [Key in N]: ToKeyValue<T, K, KA>;
    } & {
      [Key in ToProperty<'MAP', N>]: ToKeyMap<T, K>;
    } & {
      [Key in ToProperty<'LIST', N>]: T;
    }
  >;
}
