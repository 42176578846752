import uniCrazyRouter from 'uni-crazy-router';
import {
  WHITE_PAGE_LIST,
  PERMISSION_CODE_MAP,
  DISTRIBUTION_PAGE,
  routePageConfig,
} from './config';
import useUserStore from '@/store/useUserStore';
import { isEmpty } from 'lodash-es';
import CONFIG from '@/config/index.js';

export function setupRouter(app) {
  // 接收vue3的实例，并注册uni-crazy-router
  app.use(uniCrazyRouter);
}

uniCrazyRouter.beforeEach(async (to, from, next) => {
  const { url } = to;
  // #ifndef H5
  if (CONFIG.isGreatboss && DISTRIBUTION_PAGE.includes(url)) {
    uni.navigateTo({
      url: routePageConfig.register,
    });
  }
  // #endif
  if (WHITE_PAGE_LIST.includes(url)) {
    next();
    return;
  }
  const userStore = useUserStore();
  if (isEmpty(userStore.accountInfo)) {
    const custom = {
      isToast: false,
      isCallLogout: false,
    };
    await userStore.getUserInfo(custom);
  }
  const { isMainAccount, permissionCodeList } = userStore;
  if (!isMainAccount) {
    const foundKey = Object.keys(PERMISSION_CODE_MAP).find((page) => {
      return url.includes(page);
    });
    if (foundKey) {
      const permissionCode = PERMISSION_CODE_MAP[foundKey];
      if (permissionCodeList.includes(permissionCode)) {
        next();
      } else {
        uniCrazyRouter.afterNotNext(() => {
          uni.navigateTo({
            url: '/pages/permission/404',
          });
        });
        return;
      }
    }
  }
  next();
});
