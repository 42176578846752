import Request from 'luch-request';
import { logout } from '@/store/index.js';
import CONFIG from '@/config';
import useUserStore from '@/store/useUserStore';
import qs from 'qs';
import { Logger } from '@/utils/Logger';
import { LANGUAGE_LIST } from '../constants/language';
import { LANGUAGE_MODE } from '../constants/localStorageKey';
// erp request config

const locale = uni.getStorageSync(LANGUAGE_MODE) || uni.getLocale();
const xLanguage =
  LANGUAGE_LIST.find((item) => item.browerLanguage.includes(locale))
    ?.requestCode || 'en-us';

const globalConfig = {
  baseURL: CONFIG.isH5Debug
    ? CONFIG.prefix
    : `${CONFIG.baseURL}${CONFIG.prefix}`,
  header: {
    'Content-Type': 'application/x-www-form-urlencoded',
    ['X-language']: xLanguage,
  },
  withCredentials: true,
};
Logger.log(`request baseURL: `, globalConfig);
// course request config
const schoolConfig = {
  baseURL: CONFIG.isH5Debug ? CONFIG.coursePrefix : CONFIG.courseBaseURL,
  header: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
  withCredentials: true,
};

function createRequest(requestConfig) {
  const request = new Request(requestConfig);
  request.interceptors.request.use(
    async (config) => {
      const token = useUserStore().user?.token;
      config.header = config.header || {};
      config.header['authorization-token'] = token || '';
      const isSkipStringifyData =
        config.header['Content-Type'] &&
        config.header['Content-Type'].includes('multipart/form-data');
      if (config.method.toUpperCase() === 'POST' && !isSkipStringifyData) {
        const { data } = config;
        config.data = qs.stringify(data);
      }
      const custom = config?.custom || {};
      const { isLog = true } = custom;

      isLog &&
        Logger.log(
          `> request: `,
          config.method,
          config.url,
          JSON.stringify(config.params),
          JSON.stringify(config.data)
        );
      return config;
    },
    (config) => {
      return Promise.reject(config);
    }
  );

  request.interceptors.response.use(
    (response) => {
      const config = response?.config || {};
      const custom = response?.config?.custom || {};
      const statusCode = response?.statusCode;
      const { isToast = true, isCallLogout = true, isLog = true } = custom;

      if (statusCode === 200) {
        const { data } = response;
        isLog && Logger.log(' < response:', config.url, data);

        if (!data) {
          showMessage('网络异常', isToast, 'error');
          return Promise.reject();
        }

        if (isSuccess(data)) {
          return data;
        }

        if (isNotSign(data)) {
          if (isCallLogout) {
            useUserStore()
              .reLogin()
              .then((isReLoginSuccess) => {
                if (isReLoginSuccess) return;
                logout();
              });
          }
          return Promise.reject(data);
        }

        if (isFail(data)) {
          showMessage(data.reason, isToast, 'error');
          return Promise.reject(data);
        }

        return data;
      } else {
        isLog &&
          Logger.log(
            ' < response error:',
            'statusCode',
            statusCode,
            config.url,
            response
          );
        return Promise.reject(response);
      }
    },
    (response) => {
      const statusCode = response?.statusCode;
      const config = response?.config || {};
      const custom = config?.custom || {};
      const { isLog = true } = custom;
      isLog &&
        Logger.log(
          ' < response network error:',
          'statusCode',
          statusCode,
          config.url,
          response
        );
      showMessage('网络异常', true, 'error');
      return Promise.reject(response);
    }
  );
  return request;
}

// 成功响应
function isSuccess(data) {
  const isResultSuccess = data.result === 'success';
  return isResultSuccess;
}

// 失败响应
function isFail(data) {
  const isResultFail = data.result === 'fail';
  return isResultFail;
}

// 未登录响应
function isNotSign(data) {
  return data.result === 'fail' && data.code === 50001;
}

function showMessage(reason, isToast) {
  isToast !== false &&
    setTimeout(() => {
      uni.showToast({
        icon: 'none',
        title: reason || '请求失败',
      });
    }, 0);
}

export default createRequest(globalConfig);

export const courseRequest = createRequest(schoolConfig);
