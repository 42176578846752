import envMap from './env';
import { STORE_KEY } from '@/constants/store';
import defaultEnv from './env/default';
// import { Logger } from '@/utils/Logger';

const prefix = '/api/mobile_device';
const coursePrefix = '/course';
const courseBaseURL = 'https://school.91miaoshou.com';
const isBrowser = !!document;
const { MODE } = import.meta.env;

// 未打包环境
const isDebug = MODE === 'development';
const isH5Debug = isBrowser && isDebug;

const CONFIG = {
  isBrowser,
  isDebug,
  isH5Debug,
};

let selectEnv = defaultEnv;

// 在微信下 根据运行环境生成环境变量
// #ifdef MP-WEIXIN
selectEnv = getWXenv() || selectEnv;
// #endif

// 根据缓存设置config
initConfigByCache();

const isDev = CONFIG.env === 'dev';
const isProd = CONFIG.env === 'prod';
const isPre = CONFIG.env === 'pre';
const isGreatboss = CONFIG.env.includes('Greatboss');

Object.assign(CONFIG, {
  isDev,
  isProd,
  isPre,
  MODE,
  prefix,
  coursePrefix,
  courseBaseURL,
  isDebugger: !isBrowser && uni.getStorageSync(STORE_KEY.IS_DEBUG),
  isGreatboss,
});

// Logger.log(`CONFIG: `, CONFIG);

export default CONFIG;

function initConfigByCache() {
  const cacheSelectEnv = uni.getStorageSync(STORE_KEY.ENV_SELECT);

  if (envMap[cacheSelectEnv]?.env) {
    Object.assign(CONFIG, envMap[cacheSelectEnv]);
  } else {
    Object.assign(CONFIG, envMap[selectEnv]);
  }
}

function getWXenv() {
  const accountInfo = uni.getAccountInfoSync();
  // const env = 'trial';
  const env = accountInfo.miniProgram.envVersion;
  // 体验版和正式版强制修改环境
  switch (env) {
    case 'trial': // 体验版
      return 'pre';
    case 'release': // 正式版
      return 'prod';
    default:
      return 'pre';
  }
}
