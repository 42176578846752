import { routePageConfig } from '@/router/config';
import useUserStore from './useUserStore';
import { currentPage } from '@/utils/tools';
let isToLogin = false;

export async function logout(type = 'reLaunch', force = false) {
  if (force) {
    await useUserStore().logout();
  }
  let url = '/pages/account/login/index';

  // #ifdef H5
  url = routePageConfig.register;
  // #endif
  // 防止接口连续报错 重复跳转
  if (isToLogin || currentPage() === url) return;

  isToLogin = true;
  uni.$emit('logout');

  if (type === 'reLaunch') {
    return uni
      .reLaunch({
        url,
      })
      .finally(() => {
        isToLogin = false;
      });
  } else {
    return uni
      .redirectTo({
        url,
      })
      .finally(() => {
        isToLogin = false;
      });
  }
}

export function clearStorage() {
  uni.clearStorageSync();
}
