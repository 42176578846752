import CryptoJS from 'crypto-js';

const DEFAULT_SECRET_KEY = '@3438jj;siduf832';
const DEFAULT_SECRET_IV = '';

type EncryptOptions = {
  key?: string;
  iv?: string;
};

/**
 * aes加密
 */
export function aesEncrypt(data, options?: EncryptOptions) {
  const { key = DEFAULT_SECRET_KEY, iv = DEFAULT_SECRET_IV } = options || {};
  try {
    const dataHex = CryptoJS.enc.Utf8.parse(data);
    const secretKey = CryptoJS.enc.Utf8.parse(key);
    const secretIv = CryptoJS.enc.Utf8.parse(iv);
    const encrypted = CryptoJS.AES.encrypt(dataHex, secretKey, {
      iv: secretIv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
    return encrypted.toString();
  } catch (e) {
    return data;
  }
}

/**
 * 获取对象的aes加密数据
 */
export function getAesEncryptData<
  T extends Record<string, unknown>,
  K extends keyof T
>(data: T, encryptFields: K[]) {
  return encryptFields.reduce(
    (resultMap: { [P in K]?: T[K] }, encryptField) => {
      if (data[encryptField]) {
        resultMap[encryptField] = aesEncrypt(data[encryptField]);
      }
      return resultMap;
    },
    {}
  );
}
