export default {
  "components": {
    "Account": {
      "UnReceivedCodeLink": {
        "5xz3hmxq1380": "Tidak Ada Kode Verifikasi Yang Diterima?",
        "5xz3hmxq4ws0": "Tutup",
        "5xz3hmxq5800": "Mungkin Teridentifikasi Sebagai Spam, Periksa Spam",
        "5xz3hmxq5fc0": "Periksa Apakah Alamat Email Yang Anda Masukkan Benar atau Coba Mendaftar dengan Alamat Email Lain.",
        "5xz3hmxq5lo0": "Periksa Apakah Masalah Jaringan Saat Ini Normal.",
        "5xz3hmxq5s40": "Tidak Ada Kode Verifikasi Yang Diterima?"
      }
    },
    "Empty": {
      "5weyj6xvl9o0": "Tidak Ada Data Yang Tersedia"
    },
    "ProComponent": {
      "ProConfirmPopup": {
        "5weyne0t7t40": "Cepat",
        "5weyne0tctw0": "OK",
        "5weyne0td2o0": "Batalkan"
      }
    },
    "ScanCodeView": {
      "ScanCodeView": {
        "5weysudo0zk0": "Nomor Express Memiliki Karakter Ilegal. Silakan Pindai Kembali."
      },
      "ScanCameraApp": {
        "5weas1do0zk0": "Mendapatkan Informasi Kamera"
      }
    },
    "SearchBackBar": {
      "5weyueldnb00": "Filter"
    },
    "SelectView": {
      "5weyy43t5v00": "Masukkan Kata Kunci Pencarian",
      "5weyy43tasw0": "Tidak Ada Data Yang Tersedia"
    },
    "ShopTag": {
      "60aatigyeig0": "Lintas batas"
    },
    "Warehouse": {
      "ScanModeSelect": {
        "5wez10tdkk80": "Pilih Metode Pindai",
        "5wez10tdpow0": "Pemindaian Laser",
        "5wez10tdpxg0": "Pemindai Kamera"
      },
      "StockModeSelect": {
        "5wez1zd63kw0": "Pilih Metode Pergudangan",
        "5wez1zd68ag0": "Pilih Metode Keluar"
      },
      "WarehouseSelect": {
        "5wez1mdf9v80": "Pilih Tempat Penyimpanan",
        "5wez1mdfep80": "Tidak Ada Izin Gudang, Silakan Hubungi Akun Utama untuk Menambahkan"
      }
    }
  },
  "constants": {
    "package": {
      "5wskfpirawg0": "Pengajuan Tertunda",
      "5wskfpirer00": "Gagal",
      "5wskfpirey40": "Berhasil",
      "61y193pca400": "Persediaan Yang Tidak Terpakai",
      "61y193pcccg0": "Gunakan Persediaan",
      "61y193pccj40": "Gunakan Stok untuk Mengirim",
      "61y193pccnc0": "Gunakan Persediaan Pihak Ketiga",
      "61y193pccr00": "Gunakan Inventaris Pihak Ketiga untuk Mengirim Barang",
      "61y193pccv40": "Inventaris Pra-sibuk Dibatalkan",
      "62xov7r45u40": "Gunakan Persediaan Persediaan",
      "62xov7r488c0": "Dikirim dengan Persediaan Pemasok",
      "62xov7r48g00": "Semua",
      "62xov7r48k40": "Belum Dibayar",
      "62xov7r48ns0": "Menunggu Pemrosesan",
      "62xov7r48rs0": "Menunggu Dicetak",
      "62xov7r48vs0": "Menunggu Pick-Up",
      "62xov7r48zw0": "Dikirim",
      "62xov7r493g0": "Dalam Purnajual",
      "62xov7r49vg0": "Sudah Ditutup",
      "62xov7r4aa80": "Sudah Ditangguhkan",
      "62xov7r4af40": "Selesai",
      "62xov7r4akw0": "Barang Tambahan",
      "62xov7r4aps0": "Hadiah",
      "wait_seller_send": "Menunggu Dikirim",
      "refunding": "Pengembalian Dana Dalam Proses",
      "in_cancel": "Dibatalkan",
      "returned": "Dikembalikan",
      "lost": "Hilang",
      "failed": "Pengiriman Gagal",
      "wait_confirmed": "Tunggu Disetujui"
    },
    "platform": {
      "5zwog8ot6ow0": "手工订单",
      "5zwog8ot8zs0": "零售订单",
      "602uc1886b40": "Lazada Choice"
    },
    "shipment": {
      "5wsjxwq908g0": "Pindai Sekali",
      "5wsjxwq96nc0": "Pindai dan Cari Paket dan Kirim Pengiriman",
      "5wsjxwq970k0": "Pindai Dua Kali",
      "5wsjxwq97880": "Setelah Pemindaian, Cari Paket Terlebih Dahulu dan Pindai Lagi untuk Mengirimkan Barang Secara Otomatis.",
      "5wsjxwq97ek0": "Manual",
      "5wsjxwq97lo0": "Otomatis",
      "5wsjxwq97s80": "Abaikan",
      "5wsjxwq97yo0": "Wajib",
      "5yjh2ncp5go0": "Aktifkan",
      "5yjh2ncp6p80": "Tutup"
    },
    "warehouse": {
      "62xoyv9pqz40": "Item",
      "62xoyv9prkw0": "Kombinasi",
      "62xoyv9prpc0": "Beberapa Spesifikasi",
      "62xoyv9prsg0": "Gudang Pribadi",
      "62xoyv9pruw0": "Gudang Pihak Ketiga",
      "62xoyv9prxg0": "Pemasok"
    }
  },
  "page_subject": {
    "order": {
      "components": {
        "SelectOrderPackagePopup": {
          "62xp0pvghso0": "Pilih Paket",
          "62xp0pvgi6k0": "OK",
          "62xp1g1eos00": "Nomor Paket:",
          "62xp1g1ep5s0": "Nomor Waybill:",
          "6302m89rsa00": "Pindai Pesanan:"
        }
      },
      "orderDetail": {
        "components": {
          "EditAppNotePopup": {
            "62xpaforad40": "Batalkan",
            "62xpaforarg0": "Memilih Komentar",
            "62xpaforatw0": "Simpan",
            "62xpaforavw0": "Masukkan Pemilihan Keterangan"
          },
          "OrderHeaderInfo": {
            "62xpa6a0ixw0": "Status Pesanan",
            "62xpa6a0jdo0": "Nomor pesanan",
            "62xpa6a0jgo0": "Nomor paket",
            "62xpa6a0jk00": "Logistik",
            "62xpa6a0jls0": "Nomor resi"
          },
          "OrderItemsInfo": {
            "62xp9e9s1jw0": "{0} Satu Produk Tidak Cocok dengan Produk Gudang",
            "62xp9e9s26c0": "SKU Barang Tidak Berpasangan",
            "62xp9e9s2ao0": "SKU Platform",
            "62xp9e9s2dg0": "Sku Produk",
            "62xp9e9s2fs0": "Produk Parsel",
            "62xp9e9s2j00": "Barang Gudang",
            "62xp9e9s2l40": "Kombinasi",
            "630xvmqtrsg0": "Distribusi"
          },
          "OrderNoteInfo": {
            "62xp8zszea00": "Pesan Pembeli",
            "62xp8zszes00": "Keterangan Pedagang",
            "62xp8zszevw0": "Memilih Komentar"
          },
          "OrderTimeAndConsigneeInfo": {
            "62xp8jq79go0": "Waktu pesanan",
            "62xp8jq79wk0": "Waktu pembayaran",
            "62xp8jq7a080": "Penerima",
            "62xp8jq7a2w0": "Nomor Kontak",
            "62xp8jq7a580": "Alamat Penerima",
            "6303ysgdkgw0": "Pengingat:{0} Permintaan Resmi, Tampilan Desensitisasi Informasi Penerima"
          }
        },
        "hooks": {
          "useOrderDetail": {
            "62xp6xk9r7w0": "Menghemat",
            "62xp6xk9s0c0": "Berhasil Disimpan",
            "62xp6xk9s7k0": "Memuat"
          }
        },
        "index": {
          "62xp5jhypsk0": "Detail Pesanan",
          "62xp5jhyqh00": "Paket Lain dengan Pesanan Yang Sama"
        }
      }
    },
    "warehouse": {
      "components": {
        "ScanOrderPackageStatusErrorPopup": {
          "62xpfn9iq1o0": "Status Pesanan",
          "62xpfn9iqi80": "Nomor pesanan",
          "62xpfn9iqmc0": "Nomor paket",
          "62xpfn9iqow0": "Logistik",
          "62xpfn9iqr40": "Nomor resi",
          "62xpfn9iqxc0": "Detail Pesanan",
          "62xpfn9iqzw0": "Tutup"
        }
      },
      "scanSearchOrder": {
        "index": {
          "62xpe5xweg40": "Pindai dan Periksa Pesanan",
          "62xpe5xwez80": "Pindai atau Masukkan Nomor Paket/Nomor Tagihan/Nomor Pesanan",
          "62xpe5xwf2s0": "Pindai Informasi Paket Ya",
          "62xpe5xwf4o0": "Memuat",
          "62xpe5xwf6c0": "Paket Tidak Ditemukan."
        }
      }
    }
  },
  "pages": {
    "account": {
      "components": {
        "TwoFactorAuthenticationPopup": {
          "60ae807iv8k0": "Verifikasi Login",
          "60ae807ivnc0": "Anda ",
          "60ae807ivq00": "login di lokasi lain",
          "60ae807ivq01": ", ",
          "60ae807ivrw0": "Wajib verifikasi login untuk keamanan akun.",
          "60ae807ivtg0": "Kode Verifikasi",
          "60ae807ivvs0": "Segera Verifikasi",
          "60ae807ivys0": "Kode Verifikasi Email",
          "60ae807iw0s0": "Kode Verifikasi SMS",
          "60ae807iw3o0": "Silakan masukkan kode verifikasi!",
          "60ae807iw5w0": "Mendapatkan Kode Verifikasi",
          "60ae807iw7s0": "Kode verifikasi berhasil dikirim",
          "60ae8fkmn680": "Kirim Ulang{0}",
          "60gic918gtg0": "Verifikasi berhasil",
          "60gicqrsmqk0": "lokasi login"
        }
      },
      "currencyAndTimeZone": {
        "currencyAndTimeZone": {
          "index": {
            "60wmecfnlrg0": "Mata Uang",
            "60wmecfnmfo0": "Pilih Mata Uang",
            "60wmecfnmfo2": "Biaya, Keuntungan, dan Jumlah Barang Lainnya Dalam Sistem Akan Ditampilkan Dalam {0}. Setelah Pemilihan, Modifikasi Tidak Dapat Dilakukan.",
            "60wmecfnmjs0": "Zona Waktu",
            "60wmecfnmms0": "Pilih Zona Waktu",
            "60wmecfnmpo0": "Simpan",
            "60wmecfnms40": "Cepat",
            "60wmecfnmuo0": "Setelah Anda Memilih Mata Uang, Anda Tidak Dapat Mengubahnya Lagi. Yakin Ingin Memilih Mata Uang Saat Ini?",
            "60wmecfnmws0": "Berhasil Disimpan",
            "60wmecfnlrg4": "Pilih Mata Uang dan Zona Waktu"
          }
        }
      },
      "forget-password": {
        "index": {
          "5weq2lrg6780": "Nomor Ponsel",
          "5weq2lrg7a40": "Masukkan Nomor Ponsel Anda",
          "5weq2lrg7fg0": "Kata Sandi Baru",
          "5weq2lrg7io0": "Masukkan Kata Sandi",
          "5weq2lrg7lk0": "Kata Sandi Harus Minimal 8 Karakter, Termasuk Karakter, Angka, dan Simbol Khusus.",
          "5weq2lrg7os0": "Konfirmasi Kata Sandi",
          "5weq2lrg7s00": "Kode Verifikasi",
          "5weq2lrg7uw0": "Masukkan Kode Verifikasi",
          "5weq2lrg8580": "Reset Kata Sandi",
          "5weq2lrg88k0": "Format Nomor Telepon Salah.",
          "5weq2lrg8bg0": "Kata Sandi Yang Dimasukkan Dua Kali Tidak Konsisten.",
          "5weq2lrg8eg0": "Mendapatkan Kode Verifikasi",
          "5weq2lrg8h40": "Kode Verifikasi Terkirim",
          "5weq2lrg8jw0": "Berhasil Dimodifikasi"
        },
        "page": {
          "forgetPassword": "Lupa Kata Sandi"
        }
      },
      "login": {
        "5rnx1lpiie80": "Akun",
        "5rnx1lpiwp40": "Kata Sandi",
        "5rnx1lpiwz40": "Masuk",
        "5rnx1lpix540": "Lupa Kata Sandi",
        "5rnx1lpix9s0": "Daftarkan Akun",
        "5rnx1lpixes0": "Silakan masukkan email/nomor telepon/akun sub",
        "5rnx1lpixko0": "Email/Nomor telepon/Akun sub wajib diisi",
        "5rnx1lpixps0": "Akun Tidak Ada. Tulis Sekali Lagi.",
        "5rnx1lpixus0": "Kata Sandi Diperlukan",
        "5rnx1lpiy5g0": "Kata Sandinya Salah. Tulis Sekali Lagi.",
        "5rnx1lpiyk00": "Akun Tidak Ada.",
        "5rnx1lpiyqc0": "Kata Sandi Salah",
        "5rnx1lpiyv00": "Verifikasi",
        "5rnx1lpiyyc0": "Reakuisisi Kedua",
        "5rnx1lpiz2g0": "Ambil Kembali"
      },
      "register": {
        "register": {
          "placeholder": {
            "email": "Masukkan Alamat Email",
            "password": "Masukkan Kata Sandi",
            "password2": "Konfirmasi Kata Sandi Lagi",
            "mobile": "Masukkan Nomor Ponsel Anda",
            "emailValidateCode": "Masukkan Kode Verifikasi Email",
            "mobileValidateCode": "Silakan masukkan kode verifikasi ponsel"
          },
          "getValidateCodeBtn": "Verifikasi",
          "agreeCheckTipsBefore": "Saya Telah Membaca dan Setuju",
          "agreeCheckTipsAfter": {
            "userAgreement": "Perjanjian Layanan Pengguna,",
            "privacyAgreement": "Perjanjian Privasi"
          },
          "confirm": "Pendaftaran Gratis",
          "success": {
            "message": "Berhasil Terdaftar",
            "tips": "Ikuti Langkah-langkah Ini untuk Masuk dan Gunakan Easyboss",
            "copyLink": "Salin Tautan",
            "jumpToPc": "Masukkan Antarmuka Perangkat Lunak Komputer"
          },
          "validatorError": {
            "password": "Kata Sandi Harus Minimal 8 Karakter, Termasuk Karakter, Angka, dan Simbol Khusus.",
            "email": "Format Kotak Surat Salah",
            "mobile": "Format Nomor Telepon Salah.",
            "checkedAgree": "Pilih Protokol",
            "passwordNotSame": "Kata Sandi Yang Dimasukkan Dua Kali Tidak Konsisten."
          },
          "gettingValidateCode": "Mendapatkan Kode Verifikasi",
          "validateCodeSendSuccess": "Kode Verifikasi Terkirim",
          "mobileCountryCode": "Silakan Pilih Kode Area",
          "5weqrdhch7o0": "Daftar",
          "5weqrdhci4w0": "Kata Sandi Harus Minimal 8 Karakter, Termasuk Karakter, Angka, dan Simbol Khusus.",
          "5weqrdhci9k0": "Konfirmasi Kata Sandi",
          "5weqrdhcicw0": "Fuzhou Jianqiyun Network Technology Co., Ltd.",
          "5weqrdhcijs0": "Hak Cipta",
          "5weqrdhcimw0": "Siaga Icp Fujian No 2020020145-5",
          "index": {
            "61f8z24qhjg0": "Kode verifikasi grafis tidak benar"
          }
        },
        "copy": {
          "success": "Berhasil Disalin"
        },
        "page": {
          "register": "Daftar"
        },
        "greatboss": {
          "register": {
            "success": {
              "tips": "Ikuti Langkah-langkah Ini untuk Masuk dan Gunakan Greatboss"
            }
          }
        }
      }
    },
    "distribution": {
      "bind": {
        "protocol": {
          "index": {
            "60j4x24i71g0": "Pemberitahuan Kerja Sama Distribusi Easyboss",
            "60j4x24i71g2": "Pengguna Yang Terhormat, Selamat Datang untuk Menggunakan Fungsi Kerja Sama Distribusi Easyboss! Sebelum Menggunakan Fungsi Kerja Sama Distribusi Easyboss, Anda Harus Membaca dan Mematuhi Easyboss-Instruksi Kerja Sama Distribusi dengan Hati-hati.",
            "60j4x24i71g3": "1. Pemasok dan Distributor Dapat Melakukan Bisnis Kerja Sama Distribusi Melalui Alat Distribusi Easyboss. Kedua Belah Pihak Bekerja Sama Atas Basis Kemanusiaan dan Independen. Untuk Masalah Terkait Seperti Distribusi, Pesanan, Purnajual, Dana, dan Sebagainya Yang Timbul dari Kerja Sama, Harap Memberikan Penyelesaian Yang Ramah Kepada Pihak Distribusi. Easyboss Tidak Memberikan Jaminan Apa Pun dan Tidak Memiliki Kewajiban untuk Bekerja Sama dengan Kedua Pihak.",
            "60j4x24i71g4": "2. Informasi Produk Yang Diunggah dan Diedit Dalam Easyboss (Termasuk Tetapi Tidak Terbatas Pada Harga Pasokan, Deskripsi Produk dan Deskripsi, Gambar Relevan, Video, Dll.) Harus Diberikan Oleh Pemasok, Pemasok Akan Menjamin Legalitas Informasi Komoditas dan Perilaku Sendiri dan Tidak Akan Bergantung Pada Hak dan Kepentingan Pihak Ketiga Mana Pun. Semua Burung Yang Timbul dari Perilaku Yang Disebutkan Di Atas Pemasok Akan Ditanggung Oleh Pemasok Itu Sendiri, dan Easyboss Berhak Atas Tuntutan Yang Relevan.",
            "60j4x24i71g5": "3. Jika Anda Menggunakan Fungsi Distribusi Dana, Kedua Belah Pihak Harus Membayar Uang Offline; Easyboss Hanya Menyediakan Fungsi Bookkeeping, dan Tidak Menyediakan Pembersihan, pemisahan dan Layanan Terkait Dana Lainnya untuk Kedua Belah Pihak, atau Bertanggung Jawab Atas Perselisihan Yang Timbul Di Antaranya.",
            "60j4x24i71g6": "4. Distributor Mendorong Pesanan Ke Pemasok untuk Diproses. Karena Alasan Apa Pun, Pemasok Keterlambatan Pengiriman dan Pembatalan Pesanan. Dampaknya Akan Diselesaikan Oleh Kedua Distributor, Easyboss Tidak Memberikan Jaminan dan Tidak Memiliki Tanggung untuk Bekerja Sama dengan Kedua Pihak.",
            "60j4x24i71g8": "Undangan Distribusi"
          }
        },
        "bind": {
          "index": {
            "60j51capb2s0": "Pemasok {0} Undangan Anda untuk Membuat Kerja Sama Dengannya. Silakan Pilih Apakah Menerima Undangan Kerja Sama.",
            "60j51capb6s0": "Setelah Menerima Undangan, Mohon Tunggu Tinjauan Oleh Distributor. Setelah Peninjauan, Kerja Sama Dapat Ditampilkan.",
            "60j51capb9g0": "Anda Dapat Mengisi Informasi Aplikasi untuk Lulus Aplikasi Anda dengan Cepat.",
            "60j51capbcg0": "Saya Telah Membaca dan Setuju",
            "60j51capbfk0": "Belum Diterima",
            "60j51capbi40": "Terima",
            "60j51capbks0": "Klik Di Sini untuk Masuk",
            "60j51capbps0": "Klik",
            "5yg2sdxxi8t0": "Pemasok {0} Undangan Anda untuk Membuat Kerja Sama Dengannya. Silakan Masuk Ke Easyboss ERP Terlebih Dahulu.",
            "60j51capbps1": "Memuat..."
          }
        },
        "hooks": {
          "useDistributionBind": {
            "5yg2wlqzuo00": "Operasi Berhasil",
            "5yg2wlqzus40": "Cepat",
            "5yg2wlqzuus0": "Saya Sudah Tahu",
            "5yg2wlqzuxc0": "Tautan Undangan Kerja Sama Telah Kedaluwarsa. Silakan Hubungi Pemasok untuk Tautan Kerja Sama Undangan Baru.",
            "5yg2wlqzuxa0": "Anda Telah Bekerja Sama dengan Pemasok {0}!",
            "5yg2wlqzuxt0": "Anda Telah Berinisiatif Aplikasi Kerja Sama dengan Pemasok {0}! Tunggu Tinjauan Distributor",
            "5yg2wlqzuxi0": "Pemasok {0} Telah Diakhiri dengan Anda, Silakan Hubungi Pemasok",
            "5yg2wlqzuxp0": "Pemasok {0} Dalam Keadaan Tidak Normal dan Tidak Dapat Bekerja Sama untuk Saat Ini.",
            "5yg2wlqzuxh0": "Distributor Telah Menutup Proses Distribusi dan Tidak Dapat Bekerja Sama.",
            "5yg2wlqzuyr0": "Silakan Gunakan Akun Utama Anda untuk Masuk untuk Membangun Hubungan Kerja Sama.",
            "5yg2wlqzuo01": "Silakan Baca dan Setuju dengan Instruksi Kerja Sama Distribusi."
          }
        },
        "DistributionCoopConfirmDialog": {
          "index": {
            "5zqoed6x0k40": "Cepat",
            "5zqoed6x2500": "Saya Telah Membaca dan Setuju dengan {0}",
            "5zqoed6x2ds0": "Silakan Baca dan Setuju dengan Instruksi Kerja Sama Distribusi."
          }
        },
        "default": {
          "index": {
            "60j4x24i71g6-1": "Operasi Ini Berhasil. Silakan Masuk Ke {0} Di PC untuk Melihatnya.",
            "60j4x24i71g6-2": "Salin Tautan",
            "60j4x24i71g6-3": "Berhasil Disalin"
          }
        }
      },
      "constant": {
        "constant": {
          "distribution": {
            "5yg7j4qkamo0": "Kerja Sama",
            "5yg7j4qkb0o0": "Pemasok Diakhiri",
            "5yg7j4qkb3g0": "Distributor Diproduksi",
            "5yg7j4qkb6c0": "Distributor Ditolak",
            "5yg7j4qkb800": "Distributor Ditolak",
            "5yg7j4qkb9s0": "Untuk Diotorisasi Oleh Distributor",
            "5yg7j4qkbco0": "Untuk Diterima Oleh Distributor",
            "5yg7j4qkbe80": "Sebagai Otorisasi",
            "5yg7j4qkbfs0": "Ditolak",
            "5yg7j4qkbhg0": "Diakhiri",
            "5yg7j4qkbht0": "Status Abnormal"
          }
        },
        "distribution": {
          "constant": {
            "61qjub0xbi00": "Didistribusikan",
            "61qjub0xbx80": "Tidak Terdistribusi",
            "61qjub0xc040": "Taiwan, Tiongkok"
          }
        }
      },
      "site": {
        "Distribution": {
          "DistributeBtn": {
            "61qihbill1w0": "I Want Distribution",
            "61qihbill1g0": "Anda Belum Masuk Ke Dalam Hubungan Kerja Sama dengan Pemasok:{0}. Masukkan Hubungan Kerja Sama Sebelum Melanjutkan!",
            "61qihbilleo0": "Cepat",
            "61qihbillh00": "Untuk Mencapai Hubungan Kerja Sama, Silakan Mencapai Hubungan Kerja Sama Sebelum Operasi!",
            "61qihbillj00": "Tidak Ada Lagi Cepat Di Masa Depan",
            "61qihbilllg0": "Mulai Kerja Sama",
            "61qihbillng0": "Tutup",
            "61qihbillpg0": "Lanjutkan Distribusi",
            "61qihbillr00": "Batalkan",
            "61qihbill1g0-1": "Yakin Ingin Menambahkan Produk Ini Ke Daftar Produk Distribusi?",
            "61qihbill1g0-2": "Setelah Menambahkan, Anda Dapat Pergi Ke {0} Komputer> Daftar Produk Yang Saya Miliki Didistribusikan untuk Dilihat"
          },
          "InviteApplyConfirmPopup": {
            "61qilfdymfs0": "Cepat",
            "61qilfdymyo0": "Anda Telah Berinisiatif Aplikasi Kerja Sama dengan Pemasok:{0}! Tunggu Tinjauan Distributor",
            "61qilfdyn340": "Saya Sudah Tahu"
          }
        },
        "SkuList": {
          "index": {
            "61qinmmejwo0": "Daftar Spesifikasi"
          },
          "SkuItem": {
            "61qioccekzk0": "Persediaan",
            "61qioccelhw0": "Harga Pasokan"
          }
        },
        "components": {
          "AttributeInfo": {
            "61qiz6nds7c0": "Atribut Produk",
            "61qiz6ndsls0": "Kategori",
            "61qiz6ndspg0": "Area Distribusi",
            "61qiz6ndsss0": "Berat Paket",
            "61qiz6ndsv80": "Ukuran Paket",
            "61qiz6ndsxw0": "Rak Pajangan Supplier",
            "61qiz6ndt0g0": "Tidak Terbatas"
          },
          "RichNotes": {
            "61qj2yrwj600": "Deskripsi Terperinci"
          },
          "ShortNotes": {
            "61qj3nr7t3k0": "Deskripsi Sederhana"
          },
          "MainPlatformsList": {
            "61qj5zmp8r40": "Lebih Banyak Platform"
          },
          "DateTimePick": {
            "61qjpwjkg8o0": "Waktu Mulai",
            "61qjpwjkgr80": "Waktu Berakhir"
          },
          "BaseInfo": {
            "61qj1kkx0lw0-2": "Penjualan Dalam Tujuh Hari Terakhir {0}",
            "61qj1kkx12s0-2": "Penjualan Bulanan {0}"
          }
        },
        "supplier": {
          "index": {
            "61qj6pgipk80": "Kategori Utama",
            "61qj6pgipxg0": "Platform Utama",
            "61qj6pgipzs0": "Informasi Kontak",
            "61qj6pgiq1g0": "Tempat Pengiriman",
            "61qj6pgiq340": "Perkenalan",
            "61qj6pgiq4w0": "Kemampuan Layanan:",
            "61qj6pgiq840": "Distribusi Pesanan 48 Jam Tarif Pengiriman",
            "61qj6pgiq4j0": "Kapasitas Produk:",
            "61qj6pgiqbc0": "Barang Dapat Didistributor",
            "61qj6pgiqec0": "Produk Baru Telah Dirilis Dalam 30 Hari Terakhir",
            "61qj6pgiqg40": "Harga Jual Rata-rata",
            "61qj6pgiq9o0-1": "Jumlah Distributor",
            "61qj6pgiq6k0-1": "Rata-rata Pengiriman Harian"
          }
        },
        "Category": {
          "CategoryPopup": {
            "61qjaj2r6r40": "Pilihan Kategori",
            "61qjaj2rals0": "OK"
          },
          "ChildrenTree": {
            "61qjbnuzf9k0": "Semua",
            "61qjbnuzf9k4-0": "Opsi Kategori",
            "61qjbnuzf9k4-1": "Opsi Level 1",
            "61qjbnuzf9k4-2": "Opsi Level 2",
            "61qjbnuzf9k4-3": "Pilihan Level 3",
            "61qjbnuzf9k4-4": "Opsi Level 4",
            "61qjbnuzf9k4-5": "Opsi Lima Tingkat",
            "61qjbnuzf9k4-6": "Opsi Level 6",
            "61qjbnuzf9k4-7": "Pilihan Level 7",
            "61qjbnuzf9k4-8": "Opsi Level 8",
            "61qjbnuzf9k4-9": "Pilihan Level 9"
          },
          "index": {
            "61qjd2qzs340": "Filter Lainnya"
          }
        },
        "DistributionFilter": {
          "FilterDetailPopup": {
            "61qjg2lg1000": "Kategori",
            "61qjg2lg1io0": "Pilih Kategori",
            "61qjg2lg1mo0": "Rak Pajangan",
            "61qjg2lg1pg0": "Waktu Baru",
            "61qjg2lg1ro0": "Harga Pasokan",
            "61qjg2lg1u80": "Harga Pasokan Minimum",
            "61qjg2lg1yc0": "Harga Pasokan Maksimum",
            "61qjg2lg2100": "Setel ulang",
            "61qjg2lg23g0": "Permintaan"
          },
          "index": {
            "61qjjlbaqc40": "Filter"
          }
        },
        "hooks": {
          "useDistributionSite": {
            "61qjqy9ef800": "Semua",
            "61qjqy9efks0": "Pilih Produk Yang Akan Didistribusikan",
            "61qjqy9efmw0": "Operasi Berhasil"
          }
        },
        "site": {
          "index": {
            "61qjsg77ovk0": "Nama produk",
            "61qjsg77p8o0": "Memulai Aplikasi Kerja Sama",
            "61qjsg77pas0": "Setuju Undangan"
          }
        },
        "DistributionGoodsList": {
          "GoodItem": {
            "61qjl9nof8c0-1": "Persediaan {0}",
            "61qjl9nof8c6-1": "Penjualan Bulanan {0}"
          }
        },
        "CustomCategory": {
          "CategoryRow": {
            "61qjdxb7n900-0": "Rak Pajang Klasifikasi",
            "61qjdxb7n900-1": "Rak Pajangan Kelas Satu",
            "61qjdxb7n900-2": "Rak Display Sekunder",
            "61qjdxb7n900-3": "Rak Pajangan Tiga Tingkat",
            "61qjdxb7n900-4": "Rak Pajangan Empat Tingkat",
            "61qjdxb7n900-5": "Rak Pajangan Lima Tingkat",
            "61qjdxb7n900-6": "Rak Pajangan Enam Tingkat",
            "61qjdxb7n900-7": "Rak Display Tujuh Tingkat",
            "61qjdxb7n900-8": "Rak Pajangan Delapan Tingkat",
            "61qjdxb7n900-9": "Rak Pajangan Sembilan Tingkat"
          }
        },
        "goodsDetail": {
          "index": {
            "61qj4ddmz640-1": "Tutup",
            "61qj4ddmz640": "Detail Produk"
          }
        },
        "siteRelay": {
          "index": {
            "62xo50y17h00": "Cepat",
            "62xo50y18400": "Harap Konfirmasi Apakah Tautan Undangan Anda Benar.",
            "62xo50y18a80": "Konfirmasi"
          }
        }
      }
    },
    "my": {
      "my": {
        "components": {
          "InputAutoFocusSettingSelect": {
            "5yjh4axepj00": "Fokus Otomatis Kotak Input"
          },
          "ScanShipmentSettingSelect": {
            "5wsklmc59bk0": "Pindai Pengiriman"
          }
        }
      },
      "myIndex": {
        "5rnxe856up00": "Pusat Pribadi",
        "5rnxe856vpg0": "Versi Penerusan Muatan",
        "5rnxe856vw00": "Edisi Gratis",
        "5rnxe856vzo0": "Versi Berbayar",
        "5rnxe856w380": "Gudang",
        "5rnxe856w780": "Mode Pemindai",
        "5rnxe856wao0": "Keluar",
        "5rnxe856whs0": "Yakin Ingin Keluar?",
        "5rnxe856wq40": "Bahasa",
        "5rnxe856wv40": "Untuk Beralih Bahasa, Memulai Ulang Aplikasi.",
        "5rnxe856wha0": "Pindai Pengaturan Pengiriman",
        "5rnxe856whb0": "Memuat",
        "5rnxe856whb1": "Jangan Tekan Tombol Pemindaian Laser Lagi Saat Menggunakan Kamera untuk Memindai, Perangkat Mungkin Terjebak",
        "5rnxe856whb2": "Fokus Otomatis Kotak Input"
      }
    },
    "permission": {
      "404": {
        "60cyyf5ngl80": "Tidak ada izin",
        "60cyyf5nhjo0": "Tidak ada izin, silakan hubungi akun utama untuk menambahkan"
      }
    },
    "stat": {
      "HotGoodsSection": {
        "constant": {
          "5zmqkoscpn00": "Menurut SKU (Satuan)",
          "5zmqkoscqlo0": "Menurut SPU (Produk)"
        },
        "index": {
          "5zmr5k20gnk0": "10 produk terlaris",
          "5zmr5k20i840": "Spesifikasi",
          "5zmr5k20ifs0": "SKU platform",
          "5zmr5k20ikg0": "ID produk",
          "5zmr5k20iok0": "Nomor produk utama",
          "5zmr5k20it00": "Toko",
          "5zmr5k20ixc0": "Jumlah penjualan",
          "5zmr5k20j1g0": "Volume penjualan"
        }
      },
      "HotPlatformSection": {
        "index": {
          "5zmr5t9re380": "5 platform terlaris",
          "5zmr5t9rf3o0": "Peringkat",
          "5zmr5t9rf7k0": "Platform",
          "5zmr5t9rf9s0": "Jumlah penjualan"
        }
      },
      "HotShopSection": {
        "index": {
          "5zmr60rhpes0": "5 kedai terlaris",
          "5zmr60rhpyk0": "Peringkat",
          "5zmr60rhq100": "Toko",
          "5zmr60rhq300": "Platform",
          "5zmr60rhq4k0": "Jumlah penjualan"
        }
      },
      "StatShopSelect": {
        "index": {
          "5zmr8nz3oa40": "Pilih platform",
          "5zmr8nz3pac0": "Pilih laman",
          "5zmr8nz3pio0": "Pilih kedai"
        }
      },
      "TotalViewSection": {
        "constant": {
          "5zmqmu4tw1g0": "Total Pembayaran",
          "5zmqmu4twh40": "Perkiraan Omzet",
          "5zmqmu4twks0": "Jumlah pelanggan yang membayar",
          "5zmqmu4twns0": "Trend",
          "5zmqmu4twq80": "Jumlah pesanan pembayaran",
          "5zmqmu4twuk0": "Jumlah pelanggan pengembalian",
          "5zmqmu4twx00": "Jumlah pesanan pengembalian",
          "5zmqmu4twzg0": "Jumlah bayaran balik keseluruhan",
          "5zmqmu4tx3o0": "Keuntungan terkira",
          "5zmqmu4tx6g0": "Margin keuntungan"
        },
        "index": {
          "5zmr673frbk0": "Data hari ini, dikemaskini setiap 2 jam"
        }
      },
      "common": {
        "60a99nlkohc0": "Detail keuntungan",
        "60a9dq4u54g0": "Penjualan produk",
        "60a9d45degg0": "Statistik produk",
        "60a9eurfpww0": "Statistik bahan pembungkus",
        "60a9feawjog0": "Statistik kedai",
        "60a9ht0fpgc0": "Filter tanggal",
        "60a9lb8cd0w0": "Unit mata uang",
        "60a9lb8cdko0": "Menurut status pesanan",
        "60a9rrnp5rs0": "Statistik data",
        "5saylxfm3ps0": "Interval maksimal 3 bulan, silakan pilih kembali waktu pencarian"
      },
      "components": {
        "CoreIndicator": {
          "5zmr3p5s6280": "Indikator inti",
          "5zmr3p5s6s40": "Jumlah penjualan"
        },
        "CoreIndicatorChart": {
          "5zmr3x26fdo0": "Tren indikator inti",
          "5zmr3x26g500": "Statistik produk",
          "5zmr3x26g8g0": "Persentase (%)"
        },
        "DataStatisticsSection": {
          "5zmr2qhmwkw0": "Statistik data"
        },
        "DisbursementChartCard": {
          "5zmr43sgo9o0": "Analisis pengeluaran",
          "5zmr43sgomg0": "Persentase {0}: {1}%",
          "5zmr43sgopc0": "Total pengeluaran"
        },
        "GoodsItemSection": {
          "5zmr895tvq80": "Sku Produk",
          "60kms1u2s700": "spesifikasi"
        },
        "GoodsSection": {
          "5zmr2yq2c0k0": "ID Produk",
          "5zmr2yq2cek0": "Nomor barang utama",
          "5zmr2yq2ch80": "Platform",
          "5zmr2yq2cj00": "Toko",
          "5zmr2yq2ckk0": "Jumlah pesanan pembayaran",
          "5zmr2yq2cm80": "Volume penjualan",
          "5zmr2yq2cns0": "Jumlah total pembayaran",
          "5zmr2yq2cpc0": "Jumlah total pengembalian",
          "5zmr2yq2cr00": "Spesifikasi",
          "5zmr2yq2css0": "SKU Platform"
        },
        "OrderDistributionSection": {
          "5zmr7cert4c0": "Distribusi pesanan pembayaran"
        },
        "ProductDistributionSection": {
          "5zmr36t00b80": "Distribusi produk dengan penjualan"
        },
        "RankingTenList": {
          "5zmr4euc2940": "Analisis keuntungan teratas 10",
          "5zmr4euc2z40": "NO.",
          "5zmr4euc3300": "Profit",
          "5zmr4euc35s0": "Penjualan",
          "5zmr4euc3880": "Margin Profit",
          "5zmr4euc3bs0": "Total keuntungan"
        },
        "StatDropdown": {
          "5zmr95mf3q00": "Terpilih",
          "5zwtjniwy940": "Pengaruh"
        },
        "StatSearchBar": {
          "5zmr9lrwj5g0": "Masukkan",
          "5zmr9lrwknc0": "Filter"
        },
        "TableSection": {
          "5zmr7j0011s0": "Tanggal",
          "5zmr7j001yw0": "Jumlah pelanggan",
          "5zmr7j002340": "Jumlah pesanan",
          "5zmr7j002680": "Jumlah pesanan keseluruhan",
          "5zmr7j0028s0": "Jumlah bayaran balik"
        },
        "StatDetailPopup": {
          "5zmw7ihk8580": "Setel ulang",
          "5zmw7ihk9n80": "OK"
        },
        "QueryBar": {
          "5saylxfm3p80": "Interval maksimal 3 bulan, silakan pilih kembali waktu pencarian",
          "5saylxfm4400": "Hanya data 3 bulan terakhir yang dapat dicari, silakan pilih ulang waktu pencarian"
        },
        "StatRadio": {
          "60aa4y1tqd40": "Terpilih",
          "60aa4y1tqq80": "Pengaruh",
          "60aa4y1tqss0": "Semua"
        },
        "StatEmpty": {
          "60krhr1rgwk0": "Tidak Ada Data Yang Tersedia"
        }
      },
      "constant": {
        "5zmp81p6xo00": "Pesanan Dibayar",
        "5zmp81p6yuk0": "Total Pembayaran",
        "5zmp81p6yzs0": "Pesanan Retur",
        "5zmp81p6z2w0": "Jumlah total pengembalian",
        "5zmp81p6z5s0": "Ikhtisar data",
        "5zmp81p6z9k0": "Ikhtisar keuntungan",
        "5zmp81p6zcg0": "Detail keuntungan",
        "5zmp81p6zlk0": "Statistik produk",
        "5zmp81p6zug0": "Statistik volume penjualan",
        "5zmp81p70840": "Statistik pesanan",
        "5zmp81p70fk0": "Statistik kedai",
        "5zmp81p70j80": "Penjualan produk",
        "5zmp81p70lg0": "Statistik pendapatan dan perbelanjaan",
        "5zmp81p70o00": "Statistik bahan pembungkus",
        "5zmp81p70q40": "Per hari",
        "5zmp81p70sc0": "Per minggu",
        "5zmp81p70uk0": "Per bulan",
        "5zmp81p70wg0": "Kemarin",
        "5zmp81p70y80": "7 hari terakhir",
        "5zmp81p710g0": "30 hari terakhir",
        "5zmp81p713o0": "Tanggal Customize",
        "5zmp81p715c0": "Pengembalian Dana",
        "5zmp81p719c0": "Waktu pesanan",
        "5zmp81p71b00": "Waktu penyelesaian",
        "5zmp81p71dk0": "Waktu pembayaran",
        "5zmp81p71f80": "Waktu penghantaran",
        "5zmp81p71jg0": "Batas penghantaran",
        "5zmp81p71ok0": "Jumlah penjualan",
        "5zmp81p71rg0": "Jumlah bayaran kembali yang dijangka",
        "5zmp81p71t80": "Pembayaran sebenar oleh pembeli",
        "5zmp81p71v40": "Biaya produk",
        "5zmp81p71ws0": "Biaya lainnya",
        "5zmp81p71yg0": "Yuran pembungkusan",
        "5zmp81p71zs0": "Keuntungan",
        "5zmp81p72180": "Margin keuntungan kos",
        "5zmp81p72340": "Margin keuntungan penjualan",
        "5zmp81p724w0": "Menurut platform",
        "5zmp81p726c0": "Menurut laman web",
        "5zmp81p72800": "Menurut toko",
        "5zmp81p729c0": "Toko",
        "5zmp81p72aw0": "Platform",
        "5zmp81p72f00": "Laman web",
        "6021uie62280": "Penjualan"
      },
      "hooks": {
        "5zmp74as2c00": "Memuat"
      },
      "incomeAndExpenditureStatistics": {
        "constant": {
          "5zmqp7c2t700": "Persediaan akhir",
          "5zmqp7c2u6c0": "Persediaan awal",
          "5zmqp7c2udo0": "Pembelian masuk",
          "5zmqp7c2uhw0": "Penyesuaian inventori masuk",
          "5zmqp7c2ulk0": "Kelebihan inventori masuk",
          "5zmqp7c2uq80": "Pemindahan masuk",
          "5zmqp7c2uu80": "Pengembalian masuk",
          "5zmqp7c2uyg0": "Pengembalian manual masuk",
          "5zmqp7c2v3o0": "Lainnya masuk",
          "5zmqp7c2v800": "Penjualan keluar",
          "5zmqp7c2vc40": "Penyesuaian inventori keluar",
          "5zmqp7c2vgg0": "Kehilangan inventori keluar",
          "5zmqp7c2vkw0": "Pemindahan keluar",
          "5zmqp7c2vp40": "Penjualan manual keluar",
          "5zmqp7c2vtk0": "Lainnya keluar",
          "5zmqp7c2vzw0": "Sku Produk",
          "5zmqp7c2w480": "Nama produk"
        },
        "hooks": {
          "5zmr7yzqthc0": "Filter tanggal",
          "5zmr7yzqtsw0": "Pilih gudang"
        },
        "index": {
          "5zmr7qd04is0": "Statistik masuk dan keluar"
        }
      },
      "index": {
        "5zmp4zbj9s00": "Data real-time",
        "5zmp4zbjazc0": "Data harian, diperbarui setiap 2 jam",
        "5zmp4zbjb3k0": "Fungsi umum",
        "5zmvhhwoxlw0": "Unit mata uang"
      },
      "orderDetail": {
        "constant": {
          "60a9oxflttc0": "Pendapatan pesanan",
          "60a9oxflu740": "Jumlah pesanan asli",
          "60a9oxflu9o0": "Harga produk",
          "60a9oxfluc40": "Total biaya pengiriman",
          "60a9oxfluds0": "Biaya pengiriman yang dibayar oleh pembeli",
          "60a9oxflufk0": "Biaya pengiriman yang dibayar oleh penjual",
          "60a9oxfluhc0": "Diskon",
          "60a9oxfluiw0": "Biaya dan biaya",
          "60a9oxfluko0": "Komisi",
          "60a9oxflum40": "Biaya layanan",
          "60a9oxflunw0": "Biaya transaksi",
          "60a9oxflupk0": "Jumlah yang sebenarnya dibayar oleh pembeli",
          "60a9oxflur40": "Biaya pengiriman",
          "60a9oxflut80": "Biaya bahan kemasan",
          "60a9oxfluus0": "Biaya lain",
          "60a9oxfluwg0": "Biaya produk",
          "60a9oxfluyc0": "Keuntungan terkira",
          "60a9pmw41sg0": "Subsidi pengiriman Shopee",
          "60a9qjl9dao0": "Voucher penjual",
          "60a9qtr2gzk0": "Potongan koin Shopee"
        },
        "index": {
          "60a9ncf9km00": "Rincian pesanan",
          "60a9ncf9kys0": "Nomor pesanan:",
          "60a9ncf9l180": "Unit mata uang saat ini:",
          "60a9ncf9l2w0": "Situs:",
          "60a9ncf9l4o0": "Kurs"
        }
      },
      "orderStatistics": {
        "constant": {
          "5zmqukt4k5w0": "Menurut toko",
          "5zmqukt4loo0": "Rentang harga",
          "5zmqukt4lu40": "Tanggal",
          "5zmqukt4ly80": "Jumlah pelanggan (orang)",
          "5zmqukt4m1s0": "Jumlah pesanan (transaksi)",
          "5zmqukt4m600": "Jumlah total pesanan",
          "5zmqukt4m980": "Jumlah total pembayaran",
          "5zmqukt4mcw0": "Jumlah pesanan yang dibayar (transaksi)",
          "5zmqukt4mfo0": "Jumlah pelanggan yang membayar",
          "5zmqukt4mi40": "Jumlah total pengembalian",
          "5zmqukt4mlw0": "Jumlah pesanan pengembalian (transaksi)",
          "5zmqukt4mqs0": "Jumlah pelanggan yang mendapat pengembalian",
          "5zmqukt4muc0": "Rata-rata nilai pesanan",
          "5zmqukt4nj40": "Jumlah pesanan yang valid",
          "5zmqukt4o100": "Total penjualan yang valid",
          "5zmqukt4o134": "Kategori SKU {0}",
          "5zmqukt4o155": "{0} atau lebih kategori SKU",
          "5zmqukt4o177": "Jumlah kategori SKU"
        },
        "hooks": {
          "5zmr6ze850o0": "Filter tanggal"
        },
        "index": {
          "5zmr6pphrw00": "Statistik pesanan",
          "5zmr6pphsek0": "Filter",
          "5zmr6pphsi40": "Grafik tren indikator data"
        }
      },
      "overview": {
        "constant": {
          "5zmqj6xm7gs0": "Data real-time",
          "5zmqj6xm8840": "Platform terlaris",
          "5zmqj6xm8bs0": "Toko terlaris",
          "5zmqj6xm8ec0": "Produk terlaris"
        },
        "index": {
          "5zmr4qhws3s0": "Ikhtisar data"
        }
      },
      "packagingMaterialsStatistics": {
        "components": {
          "60aa48gpg940": "Statistik data"
        },
        "constant": {
          "60aa3i4w8840": "Jumlah total kemasan",
          "60aa3i4w8mk0": "Jumlah penggunaan pesanan",
          "60aa3i4w91s0": "Jumlah total",
          "60aa3i4w9940": "Kode",
          "60aa3i4w9b00": "Nama",
          "60aa3i4w9cs0": "Peringkat",
          "60aa3i4w9e80": "Harga unit rata-rata",
          "60aa3i4w9g00": "Subtotal"
        }
      },
      "productSales": {
        "components": {
          "60aa1gn2j3o0": "Spesifikasi",
          "60aa1gn2jms0": "Sku Produk",
          "60aa24ijr500": "Statistik data"
        },
        "constant": {
          "60aa0cmsgck0": "Jumlah jenis produk",
          "60aa0cmsgwk0": "Volume penjualan produk",
          "60aa0cmsh000": "Biaya produk",
          "60aa0cmsh280": "Jumlah barang yang dikembalikan",
          "60aa0cmsh4g0": "Jumlah pembayaran balik",
          "60aa0cmsh780": "Jumlah penjualan",
          "60aa0cmsh9s0": "Biaya pengembalian",
          "60aa0cmshbw0": "Jumlah pesanan penjualan",
          "60aa0vmcdtw0": "Sku Produk",
          "60aa0vmcec00": "Nama produk"
        }
      },
      "productStatistics": {
        "components": {
          "60a9yyz55n00": "Grafik tren indikator data",
          "60a9yyz565o0": "Persentase (%)"
        },
        "constants": {
          "60a9xumyah40": "Tanggal",
          "60a9xumyav40": "Total produk",
          "60a9xumyay40": "Jumlah produk yang dijual",
          "60a9xumyb000": "Proporsi produk yang dijual",
          "60a9xumyb1s0": "Jumlah produk dengan penjualan",
          "60a9xumyb5k0": "Tingkat omset",
          "60a9xumyb740": "Jumlah produk baru yang dijual",
          "60a9xumyb8s0": "Proporsi produk baru yang dijual"
        }
      },
      "profitDetail": {
        "components": {
          "60a9u37djso0": "Rincian pesanan",
          "60a9u37dkc40": "Rincian toko",
          "60a9vuvtmfs0": "Toko:",
          "60a9vuvtms80": "Situs:",
          "60a9wn2oggk0": "ID Produk",
          "60a9wn2ogv80": "SKU Platform",
          "60a9wn2ogz40": "Sku Produk",
          "60a9wn2oh1s0": "Biaya produk",
          "60a9wn2oh4s0": "Jumlah wang dikembalikan oleh platform:",
          "60a9wn2oh7g0": "Keuntungan:",
          "60a9wn2oha00": "Margin keuntungan penjualan:"
        },
        "constant": {
          "60a9mn7mnho0": "Berdasarkan rincian pesanan",
          "60a9mn7mo0g0": "Menurut toko",
          "60a9mn7mo4c0": "Nomor pesanan",
          "60a9mn7mo780": "Nomor paket",
          "60a9mn7mo9g0": "Nomor resi",
          "60a9mn7mod80": "Judul produk",
          "60a9mn7mofk0": "ID Produk",
          "60a9mn7mohs0": "SKU Platform",
          "60a9mn7mok40": "Sku Produk",
          "60a9mn7momg0": "Waktu pesanan",
          "60a9mn7mook0": "Waktu penyelesaian",
          "60a9mn7mor40": "Waktu pembayaran",
          "60a9mn7mouc0": "Waktu penghantaran",
          "60a9mn7moww0": "Batas waktu pengiriman",
          "60a9skxqv140": "Jumlah pesanan",
          "60a9skxqvhc0": "Jumlah penjualan",
          "60a9skxqvko0": "Pembayaran sebenar oleh pembeli",
          "60a9skxqvn80": "Jumlah bayaran kembali yang dijangka",
          "60a9skxqvpo0": "Jumlah yang diselesaikan",
          "60a9skxqvs00": "Jumlah tunggakan",
          "60a9skxqvug0": "Biaya",
          "60a9skxqvyo0": "Biaya produk",
          "60a9skxqw0o0": "Biaya iklan",
          "60a9skxqw380": "Biaya operasional",
          "60a9skxqw5g0": "Biaya kemasan",
          "60a9skxqw7w0": "Biaya lain",
          "60a9skxqwbg0": "Keuntungan",
          "60a9skxqwdw0": "Margin keuntungan kos",
          "60a9skxqwgg0": "Margin keuntungan jualan",
          "60a9skxqwiw0": "Data hari ini",
          "60a9skxqwl40": "Data kemarin",
          "60a9skxqwnw0": "Data ringkasan",
          "60a9v7pp1n00": "Perkiraan Jumlah Pembayaran",
          "60a9v7pp2qs0": "Jumlah asal pesanan",
          "60a9v7pp30g0": "Pembayaran sebenar oleh pembeli",
          "60a9v7pp33g0": "Biaya bahan kemasan",
          "60a9v7pp3680": "Biaya lainnya",
          "60a9v7pp38w0": "Keuntungan",
          "60a9v7pp3bo0": "Margin keuntungan kos",
          "60a9v7pp3fo0": "Margin keuntungan jualan",
          "60y9bytj9u80": "Biaya penanganan gudang pihak ketiga",
          "60y9bytjb480": "Biaya pengiriman gudang pihak ketiga"
        }
      },
      "profitsOverview": {
        "constant": {
          "5zmqie08y6k0": "Indikator inti",
          "5zmqie08yp40": "Tren indikator inti",
          "5zmqie08ytg0": "Analisis pengeluaran",
          "5zmqie08ywk0": "Analisis keuntungan",
          "5zmqie08yzo0": "Jumlah pembayaran platform",
          "5zmqie08z2o0": "Banding bulan",
          "5zmqie08z5c0": "Biaya bahan kemasan",
          "5zmqie08z7w0": "Pembayaran sebenar oleh pembeli",
          "5zmqie08zak0": "Keuntungan",
          "5zmqie08zd40": "Biaya produk",
          "5zmqie08zfo0": "Margin keuntungan kos",
          "5zmqie08zig0": "Biaya lain",
          "5zmqie08zks0": "Margin keuntungan jualan"
        },
        "index": {
          "5zmr3g7jskw0": "Gambaran keuntungan"
        }
      },
      "saleDetail": {
        "index": {
          "5zmr52c50ng0": "Ikhtisar data",
          "5zmr52c517g0": "Rincian statistik volume penjualan produk",
          "5zmr52c51bk0": "Grafik tren indikator data",
          "5zmvji9oxrk0": "ID Produk",
          "5zmvji9oz0w0": "SKU Platform",
          "5zmvji9oz700": "Platform",
          "5zmvji9ozao0": "Toko",
          "5zmvjnye99g0": "Dimensi produk",
          "5zmvlw6cgzg0": "spesifikasi",
          "603i2ug6f3k0": "Waktu Mulai",
          "603i2ug6gbs0": "Waktu Berakhir",
          "603i2ug6gjc0": "Anda Hanya Dapat Meminta Data Dalam 6 Bulan Terakhir. Pilih Waktu Permintaan Kembali."
        }
      },
      "salesStatistics": {
        "constant": {
          "5zmqf0amgts0": "Menurut SPU (Produk)",
          "5zmqf0ami0c0": "Menurut SKU (Satuan)",
          "5zmqf0ami6o0": "Menurut toko",
          "5zmqf0amib40": "Rentang harga",
          "5zmqf0amifc0": "Rentang penjualan",
          "5zmqf0amilc0": "Total produk",
          "5zmqf0amiqg0": "Jumlah pesanan yang dibayar",
          "5zmqf0amiuo0": "Volume penjualan",
          "5zmqf0amj000": "Jumlah total pembayaran",
          "5zmqf0amj440": "Jumlah pesanan pengembalian",
          "5zmqf0amj8c0": "Jumlah pembayaran balik",
          "5zmqf0amjcw0": "Judul produk",
          "5zmqf0amjhc0": "ID Produk",
          "5zmqf0amjkw0": "SKU Platform"
        },
        "hooks": {
          "5zmr1xvtlyw0": "Filter tanggal"
        },
        "index": {
          "5zmr18mjbic0": "Statistik penjualan"
        }
      },
      "shopStatistics": {
        "components": {
          "60a9ixf7pqs0": "Rincian toko",
          "60a9ixf7q7w0": "Toko:",
          "60a9ixf7qcg0": "Jumlah pelanggan:",
          "60a9ixf7qfc0": "Jumlah pesanan:",
          "60aacanra200": "Statistik data"
        },
        "constant": {
          "60a9gbqw2w80": "Jumlah pelanggan",
          "60a9gbqw3os0": "Jumlah pesanan",
          "60a9gbqw3sk0": "Jumlah total pesanan",
          "60a9gbqw3v80": "Total Pembayaran",
          "60a9gbqw3xg0": "Jumlah pembayaran balik",
          "60a9gbqw3zw0": "Rata-rata nilai pesanan",
          "60a9gbqw42c0": "Jumlah pesanan pembayaran",
          "60a9gbqw4d40": "Jumlah pelanggan yang membayar",
          "60a9gbqw4i40": "Jumlah pesanan pengembalian",
          "60a9gbqw4po0": "Jumlah pelanggan yang meminta pengembalian"
        }
      }
    },
    "warehouse": {
      "baseIndex": {
        "5wfvehrixdc0": "Paket Sudah Dimasukkan Ke Dalam Penyimpanan Hari Ini.",
        "5wfvehrixtk0": "Sku Di Rak Hari Ini",
        "5wfvehrj1ts0": "Pilih Tempat Penyimpanan",
        "5wfvehrj1zs0": "Tanda Terima",
        "5wfvehrj24w0": "Penyimpanan Manual",
        "5wfvehrj29k0": "Manual Keluar",
        "5wfvehrj2ig0": "Item Memetik",
        "5wfvehrj2ng0": "Permintaan Produk",
        "5wfvehriixa0": "Gudang",
        "5wfvehrj2na0": "Pindai Pengiriman",
        "61s2n6t0kpc0": "Pemindai Inspeksi",
        "619ereolw8g0": "Pengembalian masuk",
        "62xp0659vcg0": "Pindai dan Periksa Pesanan"
      },
      "components": {
        "packageItem": {
          "5rnxjrj9pt00": "Masukkan/Pindai Nomor Pesanan Logistik Lalu Nomor Pesanan Pembelian.",
          "5rnxl2ugdn80": "Ketik untuk Disimpan",
          "5rnxl2uge480": "Jumlah Data Yang Akan Disimpan",
          "5rnxl2ugey80": "Keterangan"
        }
      },
      "goodsSearch": {
        "baseIndex": {
          "5weqyxxhmtg0": "Permintaan Produk",
          "5weqyxxhnfg0": "Pindai Produk Sku/Kode Produk",
          "5weqyxxhnjw0": "Pindai Informasi Produk",
          "5weqyxxhnms0": "Masukkan Sku Produk/Kode Produk",
          "5weqyxxhnpk0": "Memuat",
          "5weqyxxhnsk0": "Produk Ini Tidak Ditemukan."
        },
        "components": {
          "GoodsCard": {
            "GoodsInfo": {
              "5wer21zf7rk0": "Kode Produk",
              "5wer21zf8jg0": "Volume Penjualan Dalam 7/30/60 Hari Terakhir"
            },
            "index": {
              "5wer2vtlaf40": "Sub-Product"
            },
            "StorageInfo": {
              "5wer3yv4lx40": "Posisi",
              "5wer3yv4mgo0": "Total Persediaan",
              "5wer3yv4o8g0": "Tersedia",
              "5wer3yv4ofw0": "Pra-pekerjaan"
            }
          }
        }
      },
      "processStorage": {
        "baseIndex": {
          "5rny2tblyc00": "Produk Pindai",
          "5rny2tblz7g0": "Masukkan/Pindai Sku Produk dan Kode Produk",
          "5rny2tblzds0": "Akhiri Gudang Ini",
          "5rny2tblzkw0": "Keluar",
          "5rny2tblzps0": "Pergudangan",
          "5rny2tblztk0": "Item Tidak Ditemukan",
          "5rny2tblzx00": "Kombinasi Tidak Dapat Dimasukkan Ke Dalam Penyimpanan.",
          "5rny2tbm00g0": "Memuat",
          "5rny3yibbno0": "Berhasil",
          "5rny51km7e40": "Jumlah Keluar Tidak Dapat Melebihi",
          "5rolykv54d00": "Masukkan Sku Produk"
        },
        "components": {
          "GoodsPopup": {
            "5rnxodldrdc0": "Spesies",
            "5rnxodlds640": "Buah",
            "5rnxodldsdc0": "Kirimkan"
          },
          "SetQuantityDialog": {
            "5rnxpb2qwsg0": "Masukkan Jumlah",
            "5rnxu9u7r2c0": "Kali Ini",
            "5ron09jvyh80": "Nomor",
            "5rnxpb2qxg00": "Kuantitas Tidak Boleh Kosong.",
            "5rnxu9u7q4w0": "Jumlah Tidak Boleh Melebihi"
          }
        },
        "processGoodsWithStoragePage": {
          "baseIndex": {
            "5rnxzgpf7i00": "Komoditas",
            "5rnxzgpf8co0": "Nama:",
            "5rnxzgpf8io0": "Spesifikasi:",
            "5rnxzgpf8mg0": "Jumlah Objek Yang Akan Disimpan:",
            "5rnxzgpf8q40": "Kali Ini",
            "5rnxzgpf8tk0": "Tanpa Posisi",
            "5rnxzgpf8ww0": "Tidak Ada Posisi Yang Dipilih",
            "5rnxzgpf90g0": "Hapus",
            "5rnxzgpf93s0": "Ubah Posisi",
            "5rnxzgpf97c0": "Keluar",
            "5rnxzgpf9ao0": "Pergudangan",
            "5rnxzgpf9e80": "Posisi Tidak Boleh Kosong. Silakan Cek Posisi.",
            "5rnxzgpf9hs0": "Operasi",
            "5rnxzgpf9l80": "Pengecualian Data, Silakan Hubungi Layanan Pelanggan",
            "5rny0rcmwbk0": "Dalam Proses",
            "5werec5q0lc0": "OK"
          }
        },
        "scanStorageCellPage": {
          "baseIndex": {
            "5rny1og9cek0": "Posisi Pindai",
            "5rny1og9d740": "Silakan Pindai/Masukkan Nomor Posisi",
            "5rny1og9ddc0": "Silakan Pindai Posisi",
            "5rny1og9dgw0": "Querying",
            "5roninktevk0": "Produk Ini Tidak Tersedia Saat Posisi Ini."
          }
        }
      },
      "putaway": {
        "baseIndex": {
          "5rnyj390bf00": "Paket Pindai",
          "5rnyj390c1o0": "Masukkan/Pindai Nomor Pesanan Logistik Lalu Nomor Pesanan Pembelian.",
          "5rnxm6vsad80": "Pindai Paketnya Ya Ka",
          "5rnyj390c7g0": "Pesanan Pembelian Akan Ditandatangani",
          "5rnyj390cb40": "Memuat",
          "5rnyj390ce80": "Tidak Ada Data Yang Tersedia",
          "5rnyj390cho0": "Pesanan Pembelian Berhasil Ditatangkan"
        },
        "components": {
          "WaitReceivePurchaseOrderList": {
            "5rnyh09ydbg0": "Tidak Ada Data Yang Tersedia"
          }
        },
        "purchaseOrderInfoPage": {
          "baseIndex": {
            "5rnyibnc0h40": "Paket Pindai",
            "5rnyibnc14g0": "Nama:",
            "5rnyibnc1a00": "Spesifikasi:",
            "5rnyibnc1dw0": "Kode:",
            "5rnyibnc1hc0": "Ini Sudah Berakhir",
            "5rnyibnc1l00": "Penyimpanan Langsung",
            "5rnyibnc1ow0": "Pindai Barang untuk Penyimpanan",
            "5rnyibnc1sg0": "Setelah Persediaan Langsung Dimasukkan Ke Dalam Penyimpanan, Persediaan Akan Meningkat. Anda Yakin Ingin Memasukkannya Ke Dalam Penyimpanan?",
            "5rnyibnc1w80": "Pergudangan..."
          }
        }
      },
      "scanInspection": {
        "components": {
          "GoBackConfirmPopup": {
            "61x928ywr8g0": "Return Akan Menghapus Data. Anda Yakin Ingin Kembali?"
          },
          "LogisticsGroupPopup": {
            "61s2ulkrbng0": "Pilih Tanggal Logistik"
          },
          "PackageInfoContainer": {
            "61s2u6o99vg0": "Pesan Pembeli",
            "61s2u6o9ad40": "Keterangan Pedagang",
            "61s2u6o9ag40": "Catatan Lokal",
            "61s2u6o9ahw0": "Sku Produk",
            "61s2u6o9ak00": "Kuantitas Komoditas",
            "61s2u6o9als0": "Terverifikasi"
          },
          "ResetConfirmPopup": {
            "61s2tn0lhys0": "Setelah Berhemat, Informasi Paket Yang Dipindai Akan Dikosongkan. Yakin Ingin Melanjutkan?",
            "61s2tn0lifc0": "Belum Disimpan",
            "61s2tn0liig0": "Lanjut Berhemat"
          },
          "ScanSettingPopup": {
            "61s2t7h33f00": "Pengaturan Pindai",
            "61s2t7h348w0": "Pengiriman Otomatis Setelah Berhasil Inspeksi",
            "61s2t7h34e40": "Simpan"
          }
        },
        "constant": {
          "61s2rr94jzk0": "Pindai SKU/GTIN",
          "61s2rr94g7o0": "Pindai atau Masukkan Nomor Paket/Nomor Tagihan"
        },
        "hooks": {
          "useLogisticsGroupList": {
            "61s2s5zka4k0": "Semua Logistik"
          },
          "useScanInspection": {
            "61s2sk0fjss0": "Paket Yang Dipindai Saat Ini Telah Diverifikasi.",
            "61s2sk0fnpk0": "Memuat",
            "61s2sk0fnwg0": "Produk Tidak Termasuk Dalam Paket.",
            "61s2sk0fo0w0": "Produk Sudah Diverifikasi",
            "61s2sk0fo4o0": "Dikirim",
            "61s2sk0fo840": "Beberapa Produk Sedang Dihitung. Setelah Persediaan Selesai, Inventaris Pra-sibuk Akan Secara Otomatis Dikurangi.",
            "61s2sk0fob00": "Pengiriman Gagal",
            "61s2sk0fod40": "Berhasil Dikirim",
            "61s2sk0fofs0": "Menghemat",
            "61wcj39boc80": "Paket ini tidak ditemukan atau paket pesanan sudah dikirim",
            "62hjyf276wk0": "Pemindaian dan Pemeriksaan Berhasil"
          }
        },
        "index": {
          "61s2pxoyy5s0": "Pemindai Inspeksi",
          "61s2pxoz2380": "Pengaturan",
          "61s2pxoz29w0": "Rekam",
          "61s2pxoz2ds0": "Pindai Informasi Paket Ya"
        },
        "verifiedListPage": {
          "index": {
            "61s304k9b880": "Catatan Terverifikasi",
            "61s304k9cfo0": "Nomor Pesanan/Nomor Paket/Metode Logistik/Nomor Tagihan",
            "61s304k9cm40": "Nomor paket",
            "61s304k9cos0": "Nomor pesanan",
            "61s304k9d7g0": "Informasi Logistik",
            "61s304k9dck0": "Status Pengiriman",
            "61s304k9dkw0": "Semua",
            "61s304k9dno0": "Berhasil Dikirim",
            "61s304k9dqc0": "Pengiriman Gagal"
          }
        }
      },
      "scanReturn": {
        "components": {
          "WaitReturnGoodsList": {
            "619evhn1suw0": "Ketik untuk Disimpan",
            "619evhn1txg0": "Jumlah Data Yang Akan Disimpan",
            "619evhn1u580": "Tidak Ada Data Yang Tersedia"
          }
        },
        "hooks": {
          "useReturnGoods": {
            "619eub7jj1s0": "Pergudangan...",
            "61wzjfwnawk0": "Jumlah barang masuk tidak boleh melebihi {0}"
          },
          "useScan": {
            "619euwhlq5o0": "Memuat",
            "619euwhlqyg0": "Tidak Ada Data Yang Tersedia"
          }
        },
        "returnOrderInfoPage": {
          "index": {
            "619etj4urs80": "Pengembalian masuk",
            "619etj4usrk0": "Nama:",
            "619etj4usxs0": "Spesifikasi:",
            "619etj4ut1s0": "Ini Sudah Berakhir",
            "619etj4ut540": "Penyimpanan Langsung",
            "619etj4ut980": "Pindai Barang untuk Penyimpanan",
            "619etj4uteg0": "Setelah Persediaan Langsung Dimasukkan Ke Dalam Penyimpanan, Persediaan Akan Meningkat. Anda Yakin Ingin Memasukkannya Ke Dalam Penyimpanan?"
          }
        },
        "constant": {
          "619espdpd100": "Paket Pindai",
          "619espdpe9k0": "Pesanan pengembalian menunggu untuk masuk ke gudang"
        },
        "index": {
          "619es2vhols0": "Pindai Paketnya Ya Ka",
          "619es2vho3o0": "Pengembalian masuk",
          "619es2vhoi80": "Masukkan/pindai nomor resi, nomor pesanan, atau nomor paket",
          "619es2vhooo0": "Pesanan pengembalian berhasil dimasukkan ke gudang"
        }
      },
      "scanShipment": {
        "components": {
          "List": {
            "5wsld4f61gw0": "Tidak Ada Data Yang Tersedia"
          },
          "OrderPackageItemMedia": {
            "5wsld4f61gw0": "1. Gambar/Video Yang Diunggah Dapat Dilihat Dalam Ruang Gambar.",
            "5wsld4f61gw1": "2. Video Tidak Boleh Melebihi 30M dan Durasi Tidak Boleh Melebihi 1 Menit.",
            "5wsld4f61gw2": "Unggah Gambar",
            "5wsld4f61gw3": "Unggah Video",
            "5wsld4f61gw4": "Pilih Video",
            "5wsld4f61gw5": "Gambar",
            "5wsld4f61gw6": "Video",
            "5wsld4f61gw7": "Durasi Tidak Boleh Lebih dari 1 Menit."
          },
          "PackageItemGoods": {
            "5wsldq8io7w0": "Spesifikasi"
          },
          "ShipmentButton": {
            "5wsle2o8bvc0": "Pengiriman"
          }
        },
        "hooks": {
          "useList": {
            "5wslbuh0fts0": "Paket Sudah Dipindai Kali Ini.",
            "5wslbuh0j1c0": "Memuat",
            "5wslbuh0j8g0": "Tidak Ada Data Yang Tersedia"
          },
          "useShipmentPackage": {
            "5wslcgxgpio0": "Dalam Operasi",
            "5wslcgxgu5g0": "Pengiriman Gagal",
            "5wslcgxgug00": "Berhasil Dikirim"
          }
        },
        "index": {
          "5wsl89cm3gw0": "Pindai Pengiriman",
          "5wsl89cm7z80": "Pindai atau Masukkan Nomor Paket/Nomor Tagihan",
          "5wsl89cm8980": "Dipindai",
          "5wsl89cm8ds0": "Pindai Informasi Paket Ya",
          "5wsl89cm8hc0": "Memuat",
          "5wsl89cm8hc1": "Pengiriman Otomatis Setelah Pemindaian"
        },
        "scannedListPage": {
          "index": {
            "5wslasiw3rc0": "Dipindai",
            "5wslasiw8r40": "Yakin Ingin Menghapus Paket?",
            "5wslasiw9200": "Setelah Mengembalikan, Daftar Paket Yang Dipindai Akan Dihapus. Yakin Ingin Melanjutkan?",
            "5wslasiw9940": "Semua",
            "5wslasiw9f40": "Tidak Ada Paket Pengiriman Yang Tersedia"
          }
        }
      },
      "singlePick": {
        "baseIndex": {
          "5wesij4wgig0": "Item Memetik",
          "5wesij4whps0": "Pemindaian/Waktu Gelombang Masuk",
          "5wesij4whv40": "Yakin Ingin Menandai Sebagai Lengkap?",
          "5wesij4whyk0": "Untuk Menghindari Pemilihan Berulang, Kami Menyarankan Anda Konfirmasi dengan Pengguna Kepada Siapa Akun Anda atau Memeriksa Log Operasi Sebelum Memilih.",
          "5wesij4wi1s0": "Setelah Menandai, Gelombang {0} Tidak Dapat Dipilih.",
          "5wesij4wi5s0": "Akun:{0} Beroperasi Memilih Pada {1}",
          "5wesij4wi0s0": "Dan Gelombang Akan Masuk Ke Keadaan untuk Dikemas."
        },
        "components": {
          "WaveList": {
            "WaveItem": {
              "5wesqu5fcgs0": "DIPILIH/untuk Dipilih",
              "5wesqu5fi8s0": "Informasi Produk",
              "5wesqu5fihk0": "Waktu Kreasi",
              "5wesqu5fimg0": "Pendiri",
              "5wesqu5fiv00": "Selengkapnya",
              "5wesqu5fizs0": "Mulai Memilih",
              "5wesqu5fj3w0": "Tandai Lengkap",
              "5wesqu5fj800": "Memilih Log",
              "5wesqu5fjck0": "{0} SKU, Total {1}"
            }
          }
        },
        "hooks": {
          "useOperate": {
            "5wespf869ys0": "Memuat",
            "5wespf86efs0": "Lanjutkan Memetik",
            "5wespf86et80": "Memilih Log",
            "5wespf86eys0": "Status Gelombang Telah Diperbarui. Gulir Ke Bawah untuk Menyegarkan Halaman untuk Melihatnya."
          }
        },
        "subPages": {
          "pickLog": {
            "baseIndex": {
              "5weyeh0dlbs0": "Memilih Log"
            },
            "components": {
              "LogItem": {
                "5weygrs8su40": "Sku Produk",
                "5weygrs8xvc0": "Memilih Kuantitas"
              }
            }
          },
          "picking": {
            "baseIndex": {
              "5wey0wt55a40": "Pindai/Masukkan Sku dan Kode Produk",
              "5wey0wt64ns0": "Sku,",
              "5wey0wt650g0": "Total",
              "5wey0wt65b00": "Pengaruh",
              "5wey1e5fupo0": "Tersisa Memilih:"
            },
            "components": {
              "SetPickQuantityDialog": {
                "5wey82q92us0": "Isi Jumlah Yang Dipilih",
                "5wey82q99rg0": "Masukkan",
                "5wey82q9a100": "Masukkan Jumlah Yang Dipilih",
                "5wey82q9a1g0": "Jumlah Yang Dipilih Tidak Boleh Lebih Besar dari Jumlah Yang Dipilih:{0}"
              },
              "SkuItem": {
                "5wey9z9uhxs0": "Kode Produk",
                "5wey9z9uoe00": "Posisi",
                "5wey9z9uook0": "Dipilih",
                "5wey9z9uoyw0": "Untuk Dipilih"
              },
              "WavePickFinishDialog": {
                "5weycylwol80": "Cepat",
                "5weycylwsss0": "Saya Sudah Tahu",
                "5weycylwt1c0": "Pemilihan Gelombang Saat Ini Telah Selesai!"
              }
            },
            "hooks": {
              "usePicking": {
                "5wey5ujb4xs0": "Gelombang Saat Ini Tidak Ada.",
                "5wey5ujbaeo0": "Sku Item:{0} Siap Dipilih"
              }
            }
          }
        }
      }
    }
  },
  "system": {
    "confirm": "OK",
    "cancel": "Batalkan",
    "pleaseSelect": "Pilih",
    "5rnxe856we80": "Cepat",
    "5rnxe856wl00": "Konfirmasi",
    "loading": "Memuat",
    "copySuccess": "Berhasil Disalin",
    "emptyShelfCell": "Tanpa Posisi",
    "versionInfo": "Informasi Versi",
    "language": {
      "toggle": "Ganti Bahasa",
      "selectEmpty": "Pilih Bahasa"
    },
    "tabBar": {
      "stat": "Data",
      "home": "Gudang",
      "my": "Tambang"
    },
    "update": {
      "title": "Perbarui Segera",
      "forceUpdate": "Temukan Versi Baru dan Mulai Ulang Segera.",
      "fail": "Pembaharuan Gagal",
      "newVersion": "Versi Baru Ditemukan",
      "updateNow": "Perbarui Sekarang",
      "updateLater": "Kabar Terbaru"
    }
  }
}