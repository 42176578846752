import dev from './dev'
import pre from './pre'
import prod from './prod'
import devGreatboss from './dev.greatboss';
import preGreatboss from './pre.greatboss';
import prodGreatboss from './prod.greatboss';

export default {
  dev,
  pre,
  prod,
  devGreatboss,
  preGreatboss,
  prodGreatboss,
}
