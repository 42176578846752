import CONFIG from '@/config/index.js';
// #ifdef APP-PLUS
import {
  debuggerModule,
  installDebugger,
} from '../uni_modules/imengyu-IMDebuggerWindow/common/debuggerExtern.js';
// #endif

const isDebugger = CONFIG.isDebugger

function initDebugger() {
  if (isDebugger) {
    // #ifdef MP-WEIXIN
    uni.setEnableDebug({
      enableDebug: true,
    });
    // #endif

    // #ifdef APP-PLUS
    installDebugger({
      enableRequestInterceptor: false,
      showGlobalFloatWindow: true,
    });
    // #endif
    return;
  }
}

export default {
  onLaunch: () => {
    initDebugger();
  },
  onError(err, type) {
    // #ifdef APP-PLUS
    if (isDebugger && debuggerModule) debuggerModule.addAppErr(err);
    // #endif
  },
  onUnhandledRejection(err) {
    // #ifdef APP-PLUS
    if (isDebugger && debuggerModule) debuggerModule.addAppErr(err);
    // #endif
  },
};
