<script>
  import updateManager from '@/mixins/updateManager';
  import authorization from '@/mixins/authorization';
  import debug from '@/mixins/debug';
  import { setTabBarLocale } from '@/hooks';

  export default {
    mixins: [authorization, updateManager, debug],
    onLaunch: () => {
      setTabBarLocale();
    },
    methods: {}
  };
</script>

<style lang="scss">
  @import './uni_modules/vk-uview-ui/index.scss';
  @import '@/static/styles/uview-reset.scss';
  @import '@/static/styles/index.scss';
  @import '@/static/styles/mixins.scss';
  @import '@/static/styles/iconfont/iconfont.css';

  /* #ifndef APP-NVUE */
  page {
    height: 100%;
  }

  /* #endif */
</style>
