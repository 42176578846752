export default {
  "components": {
    "Account": {
      "UnReceivedCodeLink": {
        "5xz3hmxq1380": "Không Nhận Được Mã Xác Minh?",
        "5xz3hmxq4ws0": "Đóng Cửa",
        "5xz3hmxq5800": "Có Thể Được Xác Định Là Thư Rác, Vui Lòng Kiểm Tra Trong Thư Rác",
        "5xz3hmxq5fc0": "Vui Lòng Kiểm Tra Xem Hộp Thư Bạn Đã Nhập Có Chính Xác Không Hoặc Thử Đăng Ký Bằng Một Hộp Thư Khác",
        "5xz3hmxq5lo0": "Vui Lòng Kiểm Tra Xem Sự Cố Mạng Hiện Tại Có Bình Thường Không",
        "5xz3hmxq5s40": "Không Nhận Được Mã Xác Minh?"
      }
    },
    "Empty": {
      "5weyj6xvl9o0": "Không Có Dữ Liệu"
    },
    "ProComponent": {
      "ProConfirmPopup": {
        "5weyne0t7t40": "Mẹo",
        "5weyne0tctw0": "Chắc Chắn",
        "5weyne0td2o0": "Hủy Bỏ"
      }
    },
    "ScanCodeView": {
      "ScanCodeView": {
        "5weysudo0zk0": "Có Các Ký Tự Bất Hợp Pháp Trong Số Chuyển Phát Nhanh Này, Vui Lòng Quét Lại"
      },
      "ScanCameraApp": {
        "5weas1do0zk0": "Đang Lấy Thông Tin Máy Ảnh"
      }
    },
    "SearchBackBar": {
      "5weyueldnb00": "Sàng Lọc"
    },
    "SelectView": {
      "5weyy43t5v00": "Vui Lòng Nhập Từ Khóa Tìm Kiếm",
      "5weyy43tasw0": "Không Có Dữ Liệu"
    },
    "ShopTag": {
      "60aatigyeig0": "Vượt biên"
    },
    "Warehouse": {
      "ScanModeSelect": {
        "5wez10tdkk80": "Vui Lòng Chọn Phương Pháp Quét",
        "5wez10tdpow0": "Quét Laser",
        "5wez10tdpxg0": "Máy Ảnh Quét"
      },
      "StockModeSelect": {
        "5wez1zd63kw0": "Vui Lòng Chọn Phương Thức Lưu Trữ",
        "5wez1zd68ag0": "Vui Lòng Chọn Phương Thức Xuất Kho"
      },
      "WarehouseSelect": {
        "5wez1mdf9v80": "Vui Lòng Chọn Kho",
        "5wez1mdfep80": "Không Có Quyền Kho, Vui Lòng Liên Hệ Với Tài Khoản Chính Để Thêm"
      }
    }
  },
  "constants": {
    "package": {
      "5wskfpirawg0": "Đang Chờ Gửi",
      "5wskfpirer00": "Thất Bại",
      "5wskfpirey40": "Thành Công",
      "61y193pca400": "Hàng Tồn Kho Chưa Sử Dụng",
      "61y193pcccg0": "Sử Dụng Hàng Tồn Kho",
      "61y193pccj40": "Sử Dụng Hàng Tồn Kho Để Vận Chuyển",
      "61y193pccnc0": "Sử Dụng Hàng Tồn Kho Của Bên Thứ Ba",
      "61y193pccr00": "Sử Dụng Hàng Tồn Kho Của Bên Thứ Ba Để Vận Chuyển",
      "61y193pccv40": "Hàng Tồn Kho Trước Đã Bị Hủy",
      "62xov7r45u40": "Sử Dụng Hàng Tồn Kho Của Nhà Cung Cấp",
      "62xov7r488c0": "Sử Dụng Hàng Tồn Kho Của Nhà Cung Cấp Để Vận Chuyển",
      "62xov7r48g00": "Tất Cả",
      "62xov7r48k40": "Chưa thanh toán",
      "62xov7r48ns0": "Chờ xử lý",
      "62xov7r48rs0": "Chờ in",
      "62xov7r48vs0": "Chờ lấy hàng",
      "62xov7r48zw0": "Đã gửi hàng",
      "62xov7r493g0": "Đang hậu mãi",
      "62xov7r49vg0": "Đã đóng",
      "62xov7r4aa80": "Đã tạm giữ",
      "62xov7r4af40": "Đã hoàn tất",
      "62xov7r4akw0": "Tăng thêm hàng hóa",
      "62xov7r4aps0": "Qùa tặng",
      "wait_seller_send": "Chờ gửi hàng",
      "refunding": "Đang hoàn tiền",
      "in_cancel": "Đang hủy",
      "returned": "Đã trả lại",
      "lost": "Bị mất",
      "failed": "Giao hàng thất bại",
      "wait_confirmed": "Chờ xét duyệt"
    },
    "platform": {
      "5zwog8ot6ow0": "手工订单",
      "5zwog8ot8zs0": "零售订单",
      "602uc1886b40": "Lazada Choice"
    },
    "shipment": {
      "5wsjxwq908g0": "Quét 1 Lần",
      "5wsjxwq96nc0": "Quét Tìm Kiếm Gói Hàng Và Gửi Giao Hàng",
      "5wsjxwq970k0": "Quét 2 Lần",
      "5wsjxwq97880": "Sau Khi Quét, Hãy Tìm Gói Hàng Trước Và Quét Lại Trước Khi Giao Hàng Tự Động",
      "5wsjxwq97ek0": "Thủ Công",
      "5wsjxwq97lo0": "Tự Động",
      "5wsjxwq97s80": "Bỏ Qua",
      "5wsjxwq97yo0": "Bắt Buộc",
      "5yjh2ncp5go0": "Mở",
      "5yjh2ncp6p80": "Đóng Cửa"
    },
    "warehouse": {
      "62xoyv9pqz40": "Sản Phẩm",
      "62xoyv9prkw0": "Sản Phẩm Kết Hợp",
      "62xoyv9prpc0": "Nhiều Thông Số Kỹ Thuật",
      "62xoyv9prsg0": "Kho Tự Vận Hành",
      "62xoyv9pruw0": "Kho Của Bên Thứ Ba",
      "62xoyv9prxg0": "Nhà Cung Cấp"
    }
  },
  "page_subject": {
    "order": {
      "components": {
        "SelectOrderPackagePopup": {
          "62xp0pvghso0": "Vui Lòng Chọn Gói",
          "62xp0pvgi6k0": "Chắc Chắn",
          "62xp1g1eos00": "Số Bưu Kiện:",
          "62xp1g1ep5s0": "Số Vận Đơn:",
          "6302m89rsa00": "Quét Đơn Đặt Hàng:"
        }
      },
      "orderDetail": {
        "components": {
          "EditAppNotePopup": {
            "62xpaforad40": "Hủy Bỏ",
            "62xpaforarg0": "Nhận Xét Chọn Hàng",
            "62xpaforatw0": "Lưu",
            "62xpaforavw0": "Vui Lòng Nhập Nhận Xét Chọn Hàng"
          },
          "OrderHeaderInfo": {
            "62xpa6a0ixw0": "Trạng Thái Đơn Hàng",
            "62xpa6a0jdo0": "Số đơn hàng",
            "62xpa6a0jgo0": "Số gói hàng",
            "62xpa6a0jk00": "Hậu Cần",
            "62xpa6a0jls0": "Số vận đơn"
          },
          "OrderItemsInfo": {
            "62xp9e9s1jw0": "Có {0} Sản Phẩm Không Khớp Với Sản Phẩm Kho",
            "62xp9e9s26c0": "Sản Phẩm Chưa Ghép Đôi SKU",
            "62xp9e9s2ao0": "SKU của nền tảng",
            "62xp9e9s2dg0": "Sản Phẩm SKU",
            "62xp9e9s2fs0": "Sản Phẩm Bọc",
            "62xp9e9s2j00": "Hàng Hóa Kho",
            "62xp9e9s2l40": "Sản Phẩm Kết Hợp",
            "630xvmqtrsg0": "Phân Phối"
          },
          "OrderNoteInfo": {
            "62xp8zszea00": "Tin Nhắn Của Người Mua",
            "62xp8zszes00": "Ghi Chú Kinh Doanh",
            "62xp8zszevw0": "Nhận Xét Chọn Hàng"
          },
          "OrderTimeAndConsigneeInfo": {
            "62xp8jq79go0": "Thời gian đặt hàng",
            "62xp8jq79wk0": "Thời gian thanh toán",
            "62xp8jq7a080": "Người Nhận",
            "62xp8jq7a2w0": "Số Liên Lạc",
            "62xp8jq7a580": "Địa Chỉ Nhận Hàng",
            "6303ysgdkgw0": "Nhắc Nhở:{0} Yêu Cầu Chính Thức, Hiển Thị Giải Mẫn Cảm Thông Tin Người Nhận"
          }
        },
        "hooks": {
          "useOrderDetail": {
            "62xp6xk9r7w0": "Đang Lưu",
            "62xp6xk9s0c0": "Lưu Thành Công",
            "62xp6xk9s7k0": "Đang Tải"
          }
        },
        "index": {
          "62xp5jhypsk0": "Chi Tiết Đặt Hàng",
          "62xp5jhyqh00": "Các Gói Khác Với Đơn Đặt Hàng"
        }
      }
    },
    "warehouse": {
      "components": {
        "ScanOrderPackageStatusErrorPopup": {
          "62xpfn9iq1o0": "Trạng Thái Đơn Hàng",
          "62xpfn9iqi80": "Số đơn hàng",
          "62xpfn9iqmc0": "Số gói hàng",
          "62xpfn9iqow0": "Hậu Cần",
          "62xpfn9iqr40": "Số vận đơn",
          "62xpfn9iqxc0": "Chi Tiết Đặt Hàng",
          "62xpfn9iqzw0": "Đóng Cửa"
        }
      },
      "scanSearchOrder": {
        "index": {
          "62xpe5xweg40": "Quét Danh Sách Kiểm Tra",
          "62xpe5xwez80": "Quét Hoặc Nhập Số Bưu Kiện/Số Vận Đơn/Số Đơn Đặt Hàng",
          "62xpe5xwf2s0": "Vui Lòng Quét Thông Tin Bưu Kiện",
          "62xpe5xwf4o0": "Đang Tải",
          "62xpe5xwf6c0": "Gói Hàng Không Được Tìm Thấy"
        }
      }
    }
  },
  "pages": {
    "account": {
      "components": {
        "TwoFactorAuthenticationPopup": {
          "60ae807iv8k0": "Xác minh đăng nhập",
          "60ae807ivnc0": "Bạn đang ",
          "60ae807ivq00": "đăng nhập từ một vị trí lạ",
          "60ae807ivq01": "",
          "60ae807ivrw0": "Để bảo vệ tài khoản của bạn, vui lòng xác minh trước khi đăng nhập.",
          "60ae807ivtg0": "Lấy mã xác minh",
          "60ae807ivvs0": "Xác minh ngay",
          "60ae807ivys0": "Vui lòng nhập mã xác minh e-mail",
          "60ae807iw0s0": "Vui lòng nhập mã OTP",
          "60ae807iw3o0": "Vui lòng nhập mã xác minh!",
          "60ae807iw5w0": "Đang Lấy Mã Xác Minh",
          "60ae807iw7s0": "Mã xác minh đã được gửi thành công",
          "60ae8fkmn680": "Gửi lại{0}",
          "60gic918gtg0": "Xác minh thành công",
          "60gicqrsmqk0": ""
        }
      },
      "currencyAndTimeZone": {
        "currencyAndTimeZone": {
          "index": {
            "60wmecfnlrg0": "Loại Tiền Tệ",
            "60wmecfnmfo0": "Vui Lòng Chọn Tiền Tệ",
            "60wmecfnmfo2": "Chi Phí Hàng Hóa, Lợi Nhuận, V. V. Trong Hệ Thống Sẽ Được Hiển Thị Bằng {0}; Sau Khi Chọn, Không Hỗ Trợ Sửa Đổi",
            "60wmecfnmjs0": "Múi Giờ",
            "60wmecfnmms0": "Vui Lòng Chọn Múi Giờ",
            "60wmecfnmpo0": "Lưu",
            "60wmecfnms40": "Mẹo",
            "60wmecfnmuo0": "Sau Khi Chọn Đơn Vị Tiền Tệ, Nó Không Hỗ Trợ Sửa Đổi Lại. Bạn Có Chắc Chắn Chọn Đơn Vị Tiền Tệ Hiện Tại Không?",
            "60wmecfnmws0": "Lưu Thành Công",
            "60wmecfnlrg4": "Chọn Tiền Tệ Và Múi Giờ"
          }
        }
      },
      "forget-password": {
        "index": {
          "5weq2lrg6780": "Số Điện Thoại Di Động",
          "5weq2lrg7a40": "Vui Lòng Nhập Số Điện Thoại Của Bạn",
          "5weq2lrg7fg0": "Mật Khẩu Mới",
          "5weq2lrg7io0": "Vui Lòng Nhập Mật Khẩu",
          "5weq2lrg7lk0": "Mật Khẩu Có Ít Nhất 8 Ký Tự, Bao Gồm Ký Tự, Số Và Ký Hiệu Đặc Biệt",
          "5weq2lrg7os0": "Xác Nhận Mật Khẩu",
          "5weq2lrg7s00": "Mã Xác Minh",
          "5weq2lrg7uw0": "Vui Lòng Nhập Mã Xác Minh",
          "5weq2lrg8580": "Đặt Lại Mật Khẩu",
          "5weq2lrg88k0": "Định Dạng Số Điện Thoại Di Động Không Chính Xác",
          "5weq2lrg8bg0": "Mật Khẩu Nhập Hai Lần Không Nhất Quán",
          "5weq2lrg8eg0": "Đang Lấy Mã Xác Minh",
          "5weq2lrg8h40": "Mã Xác Minh Đã Được Gửi",
          "5weq2lrg8jw0": "Sửa Đổi Thành Công"
        },
        "page": {
          "forgetPassword": "Quên Mật Khẩu"
        }
      },
      "login": {
        "5rnx1lpiie80": "Tài Khoản",
        "5rnx1lpiwp40": "Mật Khẩu",
        "5rnx1lpiwz40": "Đăng Nhập",
        "5rnx1lpix540": "Quên Mật Khẩu",
        "5rnx1lpix9s0": "Đăng Ký Tài Khoản",
        "5rnx1lpixes0": "Vui lòng nhập email/số điện thoại/tài khoản con",
        "5rnx1lpixko0": "Email/Số điện thoại/Tài khoản con bắt buộc",
        "5rnx1lpixps0": "Tài Khoản Không Tồn Tại, Vui Lòng Nhập Lại",
        "5rnx1lpixus0": "Mật Khẩu Bắt Buộc",
        "5rnx1lpiy5g0": "Mật Khẩu Sai, Vui Lòng Nhập Lại",
        "5rnx1lpiyk00": "Tài Khoản Không Tồn Tại",
        "5rnx1lpiyqc0": "Mật Khẩu Không Chính Xác",
        "5rnx1lpiyv00": "Mã xác nhận",
        "5rnx1lpiyyc0": "Lấy Lại Trong Vài Giây",
        "5rnx1lpiz2g0": "Lấy Lại"
      },
      "register": {
        "register": {
          "placeholder": {
            "email": "Vui Lòng Nhập Địa Chỉ Email",
            "password": "Vui Lòng Nhập Mật Khẩu",
            "password2": "Xác Nhận Lại Mật Khẩu",
            "mobile": "Vui Lòng Nhập Số Điện Thoại Của Bạn",
            "emailValidateCode": "Vui Lòng Nhập Mã Xác Minh Email",
            "mobileValidateCode": "Vui lòng nhập mã xác minh di động"
          },
          "getValidateCodeBtn": "Mã xác nhận",
          "agreeCheckTipsBefore": "Tôi Đã Đọc Và Đồng Ý",
          "agreeCheckTipsAfter": {
            "userAgreement": "Thỏa Thuận Dịch Vụ Người Dùng,",
            "privacyAgreement": "Thỏa Thuận Riêng Tư"
          },
          "confirm": "Đăng Ký Miễn Phí",
          "success": {
            "message": "Đăng Ký Thành Công",
            "tips": "Vui Lòng Đăng Nhập Và Sử Dụng Easyboss Như Sau",
            "copyLink": "Sao Chép Liên Kết",
            "jumpToPc": "Vào Giao Diện Phần Mềm Phía Máy Tính"
          },
          "validatorError": {
            "password": "Mật Khẩu Có Ít Nhất 8 Ký Tự, Bao Gồm Ký Tự, Số Và Ký Hiệu Đặc Biệt",
            "email": "Định Dạng Hộp Thư Không Chính Xác",
            "mobile": "Định Dạng Số Điện Thoại Di Động Không Chính Xác",
            "checkedAgree": "Vui Lòng Chọn Thỏa Thuận",
            "passwordNotSame": "Mật Khẩu Nhập Hai Lần Không Nhất Quán"
          },
          "gettingValidateCode": "Đang Lấy Mã Xác Minh",
          "validateCodeSendSuccess": "Mã Xác Minh Đã Được Gửi",
          "mobileCountryCode": "Vui Lòng Chọn Mã Vùng",
          "5weqrdhch7o0": "Đăng Ký",
          "5weqrdhci4w0": "Mật Khẩu Có Ít Nhất 8 Ký Tự, Bao Gồm Ký Tự, Số Và Ký Hiệu Đặc Biệt",
          "5weqrdhci9k0": "Xác Nhận Mật Khẩu",
          "5weqrdhcicw0": "Phúc Châu Jianqiyun Network Technology Co., Ltd.",
          "5weqrdhcijs0": "Bản Quyền",
          "5weqrdhcimw0": "Min Icp Số 2020020145-5",
          "index": {
            "61f8z24qhjg0": "Mã xác minh đồ họa không đúng"
          }
        },
        "copy": {
          "success": "Sao Chép Thành Công"
        },
        "page": {
          "register": "Đăng Ký"
        },
        "greatboss": {
          "register": {
            "success": {
              "tips": "Vui Lòng Đăng Nhập Và Sử Dụng Greatboss Như Sau"
            }
          }
        }
      }
    },
    "distribution": {
      "bind": {
        "protocol": {
          "index": {
            "60j4x24i71g0": "\"Easyboss · Hướng Dẫn Hợp Tác Phân Phối\"",
            "60j4x24i71g2": "Kính Gửi Người Dùng, Chào Mừng Bạn Sử Dụng Chức Năng Hợp Tác Phân Phối Easyboss! Trước Khi Sử Dụng Chức Năng Hợp Tác Phân Phối Easyboss, Bạn Nên Đọc Kỹ Và Tuân Thủ “Hướng Dẫn Hợp Tác Phân Phối Easyboss”.",
            "60j4x24i71g3": "1. Các Nhà Cung Cấp Và Nhà Phân Phối Có Thể Thực Hiện Hợp Tác Kinh Doanh Phân Phối Thông Qua Các Công Cụ Phân Phối Easyboss. Cả Hai Bên Sẽ Hợp Tác Trên Cơ Sở Tự Nguyện Và Độc Lập. Về Các Vấn Đề Liên Quan Đến Phân Phối, Đơn Đặt Hàng, Hậu Mãi Và Vốn Phát Sinh Trong Hợp Tác, vui Lòng Cung Cấp Cho Các Nhà Phân Phối Một Giải Pháp Thân Thiện. Easyboss Không Cung Cấp Bất Kỳ Đảm Bảo Nào Và Không Có Bất Kỳ Nghĩa Vụ Nào Đối Với Sự Hợp Tác Giữa Hai Bên.",
            "60j4x24i71g4": "2. Thông Tin Sản Phẩm Được Tải Lên Và Chỉnh Sửa Trên Easyboss (Bao Gồm Nhưng Không Giới Hạn Ở Giá Cung Cấp, Mô Tả Và Mô Tả Sản Phẩm, Hình Ảnh, Video Liên Quan, V. V.) Do Nhà Cung Cấp Cung Cấp, nhà Cung Cấp Phải Đảm Bảo Tính Hợp Pháp Của Thông Tin Sản Phẩm Và Hành Động Của Mình Và Không Xâm Phạm Đến Quyền Và Lợi Ích Của Bất Kỳ Bên Thứ Ba Nào. Mọi Hậu Quả Phát Sinh Từ Các Hành Động Trên Của Nhà Cung Cấp Sẽ Do Nhà Cung Cấp Tự Chịu Và Ông Chủ Có Quyền Truy Tố Liên Quan.",
            "60j4x24i71g5": "3. Nếu Bạn Sử Dụng Chức Năng \"Phân Phối Quỹ\", Cả Hai Bên Nên Thanh Toán Tiền Ngoại Tuyến; Easyboss Chỉ Cung Cấp Chức Năng Kế Toán Và Không Cung Cấp Thanh Toán Bù Trừ, Phân Chia Tài Khoản Và Các Dịch Vụ Liên Quan Đến Quỹ Khác Cho Các Giao Dịch Quỹ Giữa Hai Bên. Nó Cũng Không Chịu Trách Nhiệm Về Các Tranh Chấp Phát Sinh.",
            "60j4x24i71g6": "4. Nhà Phân Phối Đẩy Đơn Hàng Đến Nhà Cung Cấp Để Xử Lý, Vì Bất Kỳ Lý Do Gì, Nhà Cung Cấp Sẽ Trì Hoãn Việc Giao Hàng Và Hủy Đơn Hàng. Easyboss Không Cung Cấp Bất Kỳ Đảm Bảo Nào Và Không Có Bất Kỳ Nghĩa Vụ Nào Đối Với Sự Hợp Tác Giữa Hai Bên.",
            "60j4x24i71g8": "Lời Mời Phân Phối"
          }
        },
        "bind": {
          "index": {
            "60j51capb2s0": "Nhà Cung Cấp {0} Mời Bạn Thiết Lập Hợp Tác Với Anh Ta, Vui Lòng Chọn Có Chấp Nhận Lời Mời Hợp Tác Hay Không",
            "60j51capb6s0": "Sau Khi Chấp Nhận Lời Mời, Vui Lòng Kiên Nhẫn Chờ Nhà Cung Cấp Xem Xét, Sau Khi Xem Xét Được Thông Qua, Sự Hợp Tác Có Thể Được Hiển Thị",
            "60j51capb9g0": "Bạn Có Thể Điền Thông Tin Ứng Dụng Để Cung Cấp Thông Tin Nhanh Chóng Cho Đơn Đăng Ký Của Bạn",
            "60j51capbcg0": "Tôi Đã Đọc Và Đồng Ý",
            "60j51capbfk0": "Không Chấp Nhận",
            "60j51capbi40": "Chấp Nhận",
            "60j51capbks0": "Bấm Vào Đây Để Đăng Nhập",
            "60j51capbps0": "Bấm Vào",
            "5yg2sdxxi8t0": "Nhà Cung Cấp {0} Mời Bạn Thiết Lập Quan Hệ Đối Tác Với Anh Ta, Vui Lòng Đăng Nhập Easyboss ERP Trước",
            "60j51capbps1": "Đang Tải..."
          }
        },
        "hooks": {
          "useDistributionBind": {
            "5yg2wlqzuo00": "Hoạt Động Thành Công",
            "5yg2wlqzus40": "Mẹo",
            "5yg2wlqzuus0": "Tôi Đã Biết",
            "5yg2wlqzuxc0": "Liên Kết Mời Hợp Tác Đã Hết Hạn, Vui Lòng Liên Hệ Với Nhà Cung Cấp Để Yêu Cầu Liên Kết Mời Hợp Tác Mới",
            "5yg2wlqzuxa0": "Bạn Đã Thiết Lập Quan Hệ Hợp Tác Với Nhà Cung Cấp {0}!",
            "5yg2wlqzuxt0": "Bạn Đã Bắt Đầu Đơn Đăng Ký Hợp Tác Với Nhà Cung Cấp {0}! Vui Lòng Chờ Nhà Cung Cấp Xem Xét",
            "5yg2wlqzuxi0": "Nhà Cung Cấp {0} Đã Chấm Dứt Hợp Tác Với Bạn, Vui Lòng Liên Hệ Với Nhà Cung Cấp",
            "5yg2wlqzuxp0": "Nhà Cung Cấp {0} Đang Ở Trạng Thái Bất Thường Và Tạm Thời Không Thể Hợp Tác",
            "5yg2wlqzuxh0": "Nhà Cung Cấp Đã Đóng Quy Trình Phân Phối, Không Thể Hợp Tác",
            "5yg2wlqzuyr0": "Vui Lòng Đăng Nhập Bằng Tài Khoản Chính Để Thiết Lập Mối Quan Hệ Hợp Tác",
            "5yg2wlqzuo01": "Vui Lòng Đọc Và Đồng Ý Với Các Hướng Dẫn Hợp Tác Phân Phối"
          }
        },
        "DistributionCoopConfirmDialog": {
          "index": {
            "5zqoed6x0k40": "Mẹo",
            "5zqoed6x2500": "Tôi Đã Đọc Và Đồng Ý Với {0}",
            "5zqoed6x2ds0": "Vui Lòng Đọc Và Đồng Ý Với Các Hướng Dẫn Hợp Tác Phân Phối"
          }
        },
        "default": {
          "index": {
            "60j4x24i71g6-1": "Thao Tác Thành Công, Vui Lòng Vào PC Để Đăng Nhập {0} Để Xem",
            "60j4x24i71g6-2": "Sao Chép Liên Kết",
            "60j4x24i71g6-3": "Sao Chép Thành Công"
          }
        }
      },
      "constant": {
        "constant": {
          "distribution": {
            "5yg7j4qkamo0": "Hợp Tác",
            "5yg7j4qkb0o0": "Nhà Cung Cấp Đã Chấm Dứt",
            "5yg7j4qkb3g0": "Nhà Phân Phối Đã Chấm Dứt",
            "5yg7j4qkb6c0": "Nhà Cung Cấp Đã Từ Chối",
            "5yg7j4qkb800": "Nhà Phân Phối Đã Từ Chối",
            "5yg7j4qkb9s0": "Đang Chờ Nhà Cung Cấp Ủy Quyền",
            "5yg7j4qkbco0": "Được Nhà Phân Phối Chấp Nhận",
            "5yg7j4qkbe80": "Được Ủy Quyền",
            "5yg7j4qkbfs0": "Đã Từ Chối",
            "5yg7j4qkbhg0": "Đã Chấm Dứt",
            "5yg7j4qkbht0": "Trạng Thái Bất Thường"
          }
        },
        "distribution": {
          "constant": {
            "61qjub0xbi00": "Đã Phân Phối",
            "61qjub0xbx80": "Không Phân Phối",
            "61qjub0xc040": "Đài Loan, Trung Quốc"
          }
        }
      },
      "site": {
        "Distribution": {
          "DistributeBtn": {
            "61qihbill1w0": "Tôi Muốn Phân Phối",
            "61qihbill1g0": "Bạn Chưa Đạt Được Quan Hệ Hợp Tác Với Nhà Cung Cấp:{0}, Vui Lòng Hoạt Động Sau Khi Đạt Được Quan Hệ Hợp Tác!",
            "61qihbilleo0": "Mẹo",
            "61qihbillh00": "Để Đạt Được Mối Quan Hệ Hợp Tác, Vui Lòng Hoạt Động Sau Khi Đạt Được Mối Quan Hệ Hợp Tác!",
            "61qihbillj00": "Không Còn Nhắc Trong Tương Lai",
            "61qihbilllg0": "Khởi Xướng Hợp Tác",
            "61qihbillng0": "Đóng Cửa",
            "61qihbillpg0": "Tiếp Tục Phân Phối",
            "61qihbillr00": "Hủy Bỏ",
            "61qihbill1g0-1": "Quyết Định Thêm Sản Phẩm Này Vào Danh Sách Sản Phẩm Phân Phối?",
            "61qihbill1g0-2": "Sau Khi Thêm, Bạn Có Thể Truy Cập {0} Máy Tính> Danh Sách Sản Phẩm Tôi Đã Phân Phối Để Xem"
          },
          "InviteApplyConfirmPopup": {
            "61qilfdymfs0": "Mẹo",
            "61qilfdymyo0": "Bạn Đã Bắt Đầu Đơn Đăng Ký Hợp Tác Với Nhà Cung Cấp:{0}! Vui Lòng Chờ Nhà Cung Cấp Xem Xét",
            "61qilfdyn340": "Tôi Đã Biết"
          }
        },
        "SkuList": {
          "index": {
            "61qinmmejwo0": "Danh Sách Thông Số Kỹ Thuật"
          },
          "SkuItem": {
            "61qioccekzk0": "Hàng Tồn Kho",
            "61qioccelhw0": "Giá Cung Cấp"
          }
        },
        "components": {
          "AttributeInfo": {
            "61qiz6nds7c0": "Thuộc Tính Hàng Hóa",
            "61qiz6ndsls0": "Danh Mục",
            "61qiz6ndspg0": "Khu Vực Phân Phối",
            "61qiz6ndsss0": "Trọng Lượng Gói",
            "61qiz6ndsv80": "Kích Thước Gói",
            "61qiz6ndsxw0": "Giá Trưng Bày Nhà Cung Cấp",
            "61qiz6ndt0g0": "Không Giới Hạn"
          },
          "RichNotes": {
            "61qj2yrwj600": "Mô Tả Chi Tiết"
          },
          "ShortNotes": {
            "61qj3nr7t3k0": "Mô Tả Đơn Giản"
          },
          "MainPlatformsList": {
            "61qj5zmp8r40": "Nhiều Nền Tảng Hơn"
          },
          "DateTimePick": {
            "61qjpwjkg8o0": "Thời Gian Bắt Đầu",
            "61qjpwjkgr80": "Thời Gian Kết Thúc"
          },
          "BaseInfo": {
            "61qj1kkx0lw0-2": "Doanh Số Bán Hàng Trong Bảy Ngày Qua {0}",
            "61qj1kkx12s0-2": "Doanh Số Hàng Tháng {0}"
          }
        },
        "supplier": {
          "index": {
            "61qj6pgipk80": "Danh Mục Chính",
            "61qj6pgipxg0": "Nền Tảng Chính",
            "61qj6pgipzs0": "Phương Thức Liên Hệ",
            "61qj6pgiq1g0": "Nơi Giao Hàng",
            "61qj6pgiq340": "Giới Thiệu",
            "61qj6pgiq4w0": "Khả Năng Phục Vụ:",
            "61qj6pgiq840": "Phân Phối Đơn Đặt Hàng 48H Tỷ Lệ Giao Hàng",
            "61qj6pgiq4j0": "Khả Năng Hàng Hóa:",
            "61qj6pgiqbc0": "Hàng Hóa Có Thể Phân Phối",
            "61qj6pgiqec0": "Mới Trong 30 Ngày Qua",
            "61qj6pgiqg40": "Giá Bán Trung Bình",
            "61qj6pgiq9o0-1": "Số Nhà Phân Phối",
            "61qj6pgiq6k0-1": "Số Lượng Giao Hàng Trung Bình Hàng Ngày"
          }
        },
        "Category": {
          "CategoryPopup": {
            "61qjaj2r6r40": "Lựa Chọn Danh Mục",
            "61qjaj2rals0": "Xác Định Lựa Chọn"
          },
          "ChildrenTree": {
            "61qjbnuzf9k0": "Tất Cả",
            "61qjbnuzf9k4-0": "Tùy Chọn Danh Mục",
            "61qjbnuzf9k4-1": "Tùy Chọn Cấp Một",
            "61qjbnuzf9k4-2": "Tùy Chọn Cấp Hai",
            "61qjbnuzf9k4-3": "Tùy Chọn Cấp Ba",
            "61qjbnuzf9k4-4": "Tùy Chọn Bốn Cấp",
            "61qjbnuzf9k4-5": "Năm Cấp Độ Tùy Chọn",
            "61qjbnuzf9k4-6": "Tùy Chọn Sáu Cấp Độ",
            "61qjbnuzf9k4-7": "Bảy Cấp Độ Tùy Chọn",
            "61qjbnuzf9k4-8": "Tám Cấp Độ Tùy Chọn",
            "61qjbnuzf9k4-9": "Chín Cấp Độ Tùy Chọn"
          },
          "index": {
            "61qjd2qzs340": "Sàng Lọc Nhiều Hơn"
          }
        },
        "DistributionFilter": {
          "FilterDetailPopup": {
            "61qjg2lg1000": "Danh Mục",
            "61qjg2lg1io0": "Vui Lòng Chọn Danh Mục",
            "61qjg2lg1mo0": "Giá Trưng Bày",
            "61qjg2lg1pg0": "Thời Gian Mới",
            "61qjg2lg1ro0": "Giá Cung Cấp",
            "61qjg2lg1u80": "Giá Cung Cấp Thấp Nhất",
            "61qjg2lg1yc0": "Giá Cung Cấp Cao Nhất",
            "61qjg2lg2100": "Đặt lại",
            "61qjg2lg23g0": "Truy Vấn"
          },
          "index": {
            "61qjjlbaqc40": "Sàng Lọc"
          }
        },
        "hooks": {
          "useDistributionSite": {
            "61qjqy9ef800": "Tất Cả",
            "61qjqy9efks0": "Vui Lòng Chọn Sản Phẩm Bạn Muốn Phân Phối",
            "61qjqy9efmw0": "Hoạt Động Thành Công"
          }
        },
        "site": {
          "index": {
            "61qjsg77ovk0": "Tên sản phẩm",
            "61qjsg77p8o0": "Bắt Đầu Ứng Dụng Hợp Tác",
            "61qjsg77pas0": "Chấp Nhận Lời Mời"
          }
        },
        "DistributionGoodsList": {
          "GoodItem": {
            "61qjl9nof8c0-1": "Hàng Tồn Kho {0}",
            "61qjl9nof8c6-1": "Doanh Số Hàng Tháng {0}"
          }
        },
        "CustomCategory": {
          "CategoryRow": {
            "61qjdxb7n900-0": "Phân Loại Giá Trưng Bày",
            "61qjdxb7n900-1": "Giá Trưng Bày Hạng Nhất",
            "61qjdxb7n900-2": "Giá Trưng Bày Phụ",
            "61qjdxb7n900-3": "Giá Trưng Bày Ba Cấp",
            "61qjdxb7n900-4": "Giá Trưng Bày Bốn Cấp",
            "61qjdxb7n900-5": "Giá Trưng Bày Năm Cấp",
            "61qjdxb7n900-6": "Giá Trưng Bày Sáu Cấp",
            "61qjdxb7n900-7": "Giá Trưng Bày Cấp Bảy",
            "61qjdxb7n900-8": "Giá Trưng Bày Cấp Tám",
            "61qjdxb7n900-9": "Giá Trưng Bày Chín Cấp"
          }
        },
        "goodsDetail": {
          "index": {
            "61qj4ddmz640-1": "Đóng Cửa",
            "61qj4ddmz640": "Chi Tiết Sản Phẩm"
          }
        },
        "siteRelay": {
          "index": {
            "62xo50y17h00": "Mẹo",
            "62xo50y18400": "Vui Lòng Xác Nhận Nếu Liên Kết Lời Mời Của Bạn Là Chính Xác",
            "62xo50y18a80": "Xác Nhận"
          }
        }
      }
    },
    "my": {
      "my": {
        "components": {
          "InputAutoFocusSettingSelect": {
            "5yjh4axepj00": "Hộp Nhập Liệu Tự Động Lấy Nét"
          },
          "ScanShipmentSettingSelect": {
            "5wsklmc59bk0": "Quét Giao Hàng"
          }
        }
      },
      "myIndex": {
        "5rnxe856up00": "Trung Tâm Cá Nhân",
        "5rnxe856vpg0": "Phiên Bản Giao Nhận Hàng Hóa",
        "5rnxe856vw00": "Phiên Bản Miễn Phí",
        "5rnxe856vzo0": "Phiên Bản Trả Phí",
        "5rnxe856w380": "Kho Hàng",
        "5rnxe856w780": "Phương Thức Quét",
        "5rnxe856wao0": "Đăng Xuất Khỏi Đăng Nhập",
        "5rnxe856whs0": "Xác Nhận Rằng Bạn Muốn Đăng Xuất?",
        "5rnxe856wq40": "Ngôn Ngữ",
        "5rnxe856wv40": "Chuyển Đổi Ngôn Ngữ Cần Khởi Động Lại Ứng Dụng",
        "5rnxe856wha0": "Quét Cài Đặt Giao Hàng",
        "5rnxe856whb0": "Đang Tải",
        "5rnxe856whb1": "Khi Sử Dụng Máy Ảnh Để Quét, Không Nhấn Nút Quét Laser Nữa, Thiết Bị Có Thể Bị Kẹt",
        "5rnxe856whb2": "Hộp Nhập Liệu Tự Động Lấy Nét"
      }
    },
    "permission": {
      "404": {
        "60cyyf5ngl80": "Không có quyền",
        "60cyyf5nhjo0": "Không có quyền, vui lòng liên hệ tài khoản chính để thêm"
      }
    },
    "stat": {
      "HotGoodsSection": {
        "constant": {
          "5zmqkoscpn00": "Theo SKU (Sản phẩm đơn)",
          "5zmqkoscqlo0": "Theo SPU (Sản phẩm)"
        },
        "index": {
          "5zmr5k20gnk0": "Top 10 sản phẩm bán chạy nhất",
          "5zmr5k20i840": "Quy Cách",
          "5zmr5k20ifs0": "SKU nền tảng",
          "5zmr5k20ikg0": "ID sản phẩm",
          "5zmr5k20iok0": "Số sản phẩm chính",
          "5zmr5k20it00": "Cửa hàng",
          "5zmr5k20ixc0": "Doanh số",
          "5zmr5k20j1g0": "Doanh số"
        }
      },
      "HotPlatformSection": {
        "index": {
          "5zmr5t9re380": "Top 5 nền tảng bán chạy nhất",
          "5zmr5t9rf3o0": "Xếp hạng",
          "5zmr5t9rf7k0": "Nền tảng",
          "5zmr5t9rf9s0": "Doanh số"
        }
      },
      "HotShopSection": {
        "index": {
          "5zmr60rhpes0": "Top 5 cửa hàng bán chạy nhất",
          "5zmr60rhpyk0": "Xếp hạng",
          "5zmr60rhq100": "Cửa hàng",
          "5zmr60rhq300": "Nền tảng",
          "5zmr60rhq4k0": "Doanh số"
        }
      },
      "StatShopSelect": {
        "index": {
          "5zmr8nz3oa40": "Chọn nền tảng",
          "5zmr8nz3pac0": "Chọn trang",
          "5zmr8nz3pio0": "Chọn cửa hàng"
        }
      },
      "TotalViewSection": {
        "constant": {
          "5zmqmu4tw1g0": "Tổng số tiền thanh toán",
          "5zmqmu4twh40": "Dự kiến thu thực tế",
          "5zmqmu4twks0": "Số lượng khách hàng thanh toán",
          "5zmqmu4twns0": "So với hôm qua",
          "5zmqmu4twq80": "Số lượng đơn hàng thanh toán",
          "5zmqmu4twuk0": "Số lượng khách hàng hoàn trả",
          "5zmqmu4twx00": "Số lượng đơn hàng hoàn trả",
          "5zmqmu4twzg0": "Tổng số tiền hoàn trả",
          "5zmqmu4tx3o0": "Lợi nhuận ước tính",
          "5zmqmu4tx6g0": "Tỷ lệ lợi nhuận"
        },
        "index": {
          "5zmr673frbk0": "Dữ liệu cho ngày hôm nay, cập nhật mỗi 2 giờ"
        }
      },
      "common": {
        "60a99nlkohc0": "Chi tiết lợi nhuận",
        "60a9dq4u54g0": "Bán hàng sản phẩm",
        "60a9d45degg0": "Thống kê sản phẩm",
        "60a9eurfpww0": "Thống kê vật liệu đóng gói",
        "60a9feawjog0": "Thống kê cửa hàng",
        "60a9ht0fpgc0": "Bộ lọc ngày",
        "60a9lb8cd0w0": "Đơn vị tiền tệ",
        "60a9lb8cdko0": "Theo trạng thái đơn hàng",
        "60a9rrnp5rs0": "Thống kê dữ liệu",
        "5saylxfm3ps0": "Khoảng thời gian tối đa là 3 tháng, vui lòng chọn lại thời gian tìm kiếm"
      },
      "components": {
        "CoreIndicator": {
          "5zmr3p5s6280": "Chỉ số cốt lõi",
          "5zmr3p5s6s40": "Lượng bán hàng"
        },
        "CoreIndicatorChart": {
          "5zmr3x26fdo0": "Xu hướng chỉ số cốt lõi",
          "5zmr3x26g500": "Thống kê sản phẩm",
          "5zmr3x26g8g0": "Phần trăm (%)"
        },
        "DataStatisticsSection": {
          "5zmr2qhmwkw0": "Thống kê dữ liệu"
        },
        "DisbursementChartCard": {
          "5zmr43sgo9o0": "Phân tích chi phí",
          "5zmr43sgomg0": "Tỷ lệ {0}: {1}%",
          "5zmr43sgopc0": "Tổng chi phí"
        },
        "GoodsItemSection": {
          "5zmr895tvq80": "Sản Phẩm SKU",
          "60kms1u2s700": "đặc điểm"
        },
        "GoodsSection": {
          "5zmr2yq2c0k0": "ID Sản phẩm",
          "5zmr2yq2cek0": "Số mục chính",
          "5zmr2yq2ch80": "Nền tảng",
          "5zmr2yq2cj00": "Cửa hàng",
          "5zmr2yq2ckk0": "Số lượng đơn hàng thanh toán",
          "5zmr2yq2cm80": "Số lượng bán ra",
          "5zmr2yq2cns0": "Tổng số tiền thanh toán",
          "5zmr2yq2cpc0": "Tổng số tiền hoàn lại",
          "5zmr2yq2cr00": "Quy Cách",
          "5zmr2yq2css0": "SKU của nền tảng"
        },
        "OrderDistributionSection": {
          "5zmr7cert4c0": "Phân phối đơn hàng thanh toán"
        },
        "ProductDistributionSection": {
          "5zmr36t00b80": "Phân phối sản phẩm có doanh số"
        },
        "RankingTenList": {
          "5zmr4euc2940": "Phân tích lợi nhuận TOP10",
          "5zmr4euc2z40": "Xếp hạng",
          "5zmr4euc3300": "Lợi nhuận",
          "5zmr4euc35s0": "Doanh số",
          "5zmr4euc3880": "Tỷ suất lợi nhuận bán hàng",
          "5zmr4euc3bs0": "Tổng lợi nhuận"
        },
        "StatDropdown": {
          "5zmr95mf3q00": "Đã chọn",
          "5zwtjniwy940": "Cái"
        },
        "StatSearchBar": {
          "5zmr9lrwj5g0": "Vui Lòng Nhập",
          "5zmr9lrwknc0": "Sàng Lọc"
        },
        "TableSection": {
          "5zmr7j0011s0": "Ngày",
          "5zmr7j001yw0": "Số lượng khách hàng",
          "5zmr7j002340": "Số lượng đơn hàng",
          "5zmr7j002680": "Tổng số tiền đơn hàng",
          "5zmr7j0028s0": "Số tiền hoàn lại"
        },
        "StatDetailPopup": {
          "5zmw7ihk8580": "Đặt lại",
          "5zmw7ihk9n80": "Chắc Chắn"
        },
        "QueryBar": {
          "5saylxfm3p80": "Khoảng thời gian tối đa là 3 tháng, vui lòng chọn lại thời gian tìm kiếm",
          "5saylxfm4400": "Chỉ hỗ trợ tra cứu dữ liệu trong 3 tháng quá, vui lòng chọn lại thời gian tra cứu"
        },
        "StatRadio": {
          "60aa4y1tqd40": "Đã chọn",
          "60aa4y1tqq80": "Cái",
          "60aa4y1tqss0": "Tất Cả"
        },
        "StatEmpty": {
          "60krhr1rgwk0": "Không Có Dữ Liệu"
        }
      },
      "constant": {
        "5zmp81p6xo00": "Số lượng đơn hàng thanh toán",
        "5zmp81p6yuk0": "Tổng số tiền thanh toán",
        "5zmp81p6yzs0": "Số lượng đơn hàng hoàn lại",
        "5zmp81p6z2w0": "Tổng số tiền hoàn lại",
        "5zmp81p6z5s0": "Tổng quan dữ liệu",
        "5zmp81p6z9k0": "Tổng quan lợi nhuận",
        "5zmp81p6zcg0": "Chi tiết lợi nhuận",
        "5zmp81p6zlk0": "Thống kê sản phẩm",
        "5zmp81p6zug0": "Thống kê doanh số",
        "5zmp81p70840": "Thống kê đơn hàng",
        "5zmp81p70fk0": "Thống kê cửa hàng",
        "5zmp81p70j80": "Bán hàng sản phẩm",
        "5zmp81p70lg0": "Thống kê thu chi",
        "5zmp81p70o00": "Thống kê vật liệu đóng gói",
        "5zmp81p70q40": "Theo ngày",
        "5zmp81p70sc0": "Theo tuần",
        "5zmp81p70uk0": "Theo tháng",
        "5zmp81p70wg0": "Hôm qua",
        "5zmp81p70y80": "7 ngày gần đây",
        "5zmp81p710g0": "30 ngày gần đây",
        "5zmp81p713o0": "Ngày tùy chỉnh",
        "5zmp81p715c0": "Số tiền hoàn lại",
        "5zmp81p719c0": "Thời gian đặt hàng",
        "5zmp81p71b00": "Thời gian thanh toán",
        "5zmp81p71dk0": "Thời gian thanh toán",
        "5zmp81p71f80": "Thời gian giao hàng",
        "5zmp81p71jg0": "Thời hạn giao hàng",
        "5zmp81p71ok0": "Lượng bán hàng",
        "5zmp81p71rg0": "Số tiền hoàn trả ước tính",
        "5zmp81p71t80": "Thanh toán thực tế của người mua",
        "5zmp81p71v40": "Chi phí sản phẩm",
        "5zmp81p71ws0": "Chi phí khác",
        "5zmp81p71yg0": "Phí đóng gói",
        "5zmp81p71zs0": "Lợi nhuận",
        "5zmp81p72180": "Tỷ suất lợi nhuận chi phí",
        "5zmp81p72340": "Tỷ suất lợi nhuận bán hàng",
        "5zmp81p724w0": "Theo nền tảng",
        "5zmp81p726c0": "Theo trang web",
        "5zmp81p72800": "Theo cửa hàng",
        "5zmp81p729c0": "Cửa hàng",
        "5zmp81p72aw0": "Nền tảng",
        "5zmp81p72f00": "Trang web",
        "6021uie62280": "Doanh số"
      },
      "hooks": {
        "5zmp74as2c00": "Đang Tải"
      },
      "incomeAndExpenditureStatistics": {
        "constant": {
          "5zmqp7c2t700": "Hàng tồn cuối kỳ",
          "5zmqp7c2u6c0": "Hàng tồn đầu kỳ",
          "5zmqp7c2udo0": "Nhập hàng mua",
          "5zmqp7c2uhw0": "Điều chỉnh tồn kho vào",
          "5zmqp7c2ulk0": "Tăng tồn kho",
          "5zmqp7c2uq80": "Chuyển khoản vào",
          "5zmqp7c2uu80": "Trả hàng vào",
          "5zmqp7c2uyg0": "Trả hàng thủ công vào",
          "5zmqp7c2v3o0": "Khác vào",
          "5zmqp7c2v800": "Xuất hàng bán",
          "5zmqp7c2vc40": "Điều chỉnh tồn kho ra",
          "5zmqp7c2vgg0": "Thiếu tồn kho",
          "5zmqp7c2vkw0": "Chuyển khoản ra",
          "5zmqp7c2vp40": "Bán thủ công",
          "5zmqp7c2vtk0": "Khác ra",
          "5zmqp7c2vzw0": "Sản Phẩm SKU",
          "5zmqp7c2w480": "Tên sản phẩm"
        },
        "hooks": {
          "5zmr7yzqthc0": "Bộ lọc ngày",
          "5zmr7yzqtsw0": "Chọn kho"
        },
        "index": {
          "5zmr7qd04is0": "Thống kê nhập và xuất"
        }
      },
      "index": {
        "5zmp4zbj9s00": "Dữ liệu thời gian thực",
        "5zmp4zbjazc0": "Dữ liệu hàng ngày, cập nhật mỗi 2 giờ",
        "5zmp4zbjb3k0": "Chức năng phổ biến",
        "5zmvhhwoxlw0": "Đơn vị tiền tệ"
      },
      "orderDetail": {
        "constant": {
          "60a9oxflttc0": "Doanh số đơn hàng",
          "60a9oxflu740": "Số tiền đơn hàng ban đầu",
          "60a9oxflu9o0": "Giá sản phẩm",
          "60a9oxfluc40": "Tổng phí vận chuyển",
          "60a9oxfluds0": "Phí vận chuyển được thanh toán bởi người mua",
          "60a9oxflufk0": "Phí vận chuyển được thanh toán bởi người bán",
          "60a9oxfluhc0": "Giảm giá",
          "60a9oxfluiw0": "Phí và chi phí",
          "60a9oxfluko0": "Hoa hồng",
          "60a9oxflum40": "Phí dịch vụ",
          "60a9oxflunw0": "Phí giao dịch",
          "60a9oxflupk0": "Số tiền thực sự được thanh toán bởi người mua",
          "60a9oxflur40": "Phí vận chuyển",
          "60a9oxflut80": "Phí đóng gói",
          "60a9oxfluus0": "Chi phí khác",
          "60a9oxfluwg0": "Chi phí sản phẩm",
          "60a9oxfluyc0": "Lợi nhuận ước tính",
          "60a9pmw41sg0": "Trợ cấp vận chuyển Shopee",
          "60a9qjl9dao0": "Voucher của người bán",
          "60a9qtr2gzk0": "Giảm giá bằng tiền Shopee"
        },
        "index": {
          "60a9ncf9km00": "Chi tiết đơn hàng",
          "60a9ncf9kys0": "Số đơn hàng:",
          "60a9ncf9l180": "Đơn vị tiền tệ hiện tại:",
          "60a9ncf9l2w0": "Trang web:",
          "60a9ncf9l4o0": "Tỷ giá:"
        }
      },
      "orderStatistics": {
        "constant": {
          "5zmqukt4k5w0": "Theo cửa hàng",
          "5zmqukt4loo0": "Khoảng giá",
          "5zmqukt4lu40": "Ngày",
          "5zmqukt4ly80": "Số lượng khách hàng (cá nhân)",
          "5zmqukt4m1s0": "Số lượng đơn hàng (giao dịch)",
          "5zmqukt4m600": "Tổng số đơn hàng",
          "5zmqukt4m980": "Tổng số tiền thanh toán",
          "5zmqukt4mcw0": "Số đơn hàng đã thanh toán",
          "5zmqukt4mfo0": "Số khách hàng đã thanh toán",
          "5zmqukt4mi40": "Tổng số tiền hoàn trả",
          "5zmqukt4mlw0": "Số đơn hàng hoàn trả",
          "5zmqukt4mqs0": "Số khách hàng đã được hoàn tiền",
          "5zmqukt4muc0": "Trung bình mỗi đơn hàng",
          "5zmqukt4nj40": "Số đơn hàng hợp lệ",
          "5zmqukt4o100": "Tổng doanh số hợp lệ",
          "5zmqukt4o134": "{0} danh mục SKU",
          "5zmqukt4o155": "{0} hoặc nhiều hơn danh mục SKU",
          "5zmqukt4o177": "Số danh mục SKU"
        },
        "hooks": {
          "5zmr6ze850o0": "Bộ lọc ngày"
        },
        "index": {
          "5zmr6pphrw00": "Thống kê đơn hàng",
          "5zmr6pphsek0": "Sàng Lọc",
          "5zmr6pphsi40": "Biểu đồ xu hướng chỉ số dữ liệu"
        }
      },
      "overview": {
        "constant": {
          "5zmqj6xm7gs0": "Dữ liệu thời gian thực",
          "5zmqj6xm8840": "Nền tảng bán chạy nhất",
          "5zmqj6xm8bs0": "Cửa hàng bán chạy nhất",
          "5zmqj6xm8ec0": "Sản phẩm bán chạy nhất"
        },
        "index": {
          "5zmr4qhws3s0": "Tổng quan dữ liệu"
        }
      },
      "packagingMaterialsStatistics": {
        "components": {
          "60aa48gpg940": "Thống kê dữ liệu"
        },
        "constant": {
          "60aa3i4w8840": "Tổng số lượng bao bì",
          "60aa3i4w8mk0": "Số lượng đơn hàng được sử dụng",
          "60aa3i4w91s0": "Tổng số tiền",
          "60aa3i4w9940": "Mã",
          "60aa3i4w9b00": "Tên",
          "60aa3i4w9cs0": "Xếp hạng",
          "60aa3i4w9e80": "Giá trung bình trên mỗi đơn vị",
          "60aa3i4w9g00": "Tổng cộng"
        }
      },
      "productSales": {
        "components": {
          "60aa1gn2j3o0": "Quy Cách",
          "60aa1gn2jms0": "Sản Phẩm SKU",
          "60aa24ijr500": "Thống kê dữ liệu"
        },
        "constant": {
          "60aa0cmsgck0": "Số loại sản phẩm",
          "60aa0cmsgwk0": "Doanh số sản phẩm",
          "60aa0cmsh000": "Chi phí sản phẩm",
          "60aa0cmsh280": "Số lượng hàng hóa được trả lại",
          "60aa0cmsh4g0": "Số tiền hoàn trả",
          "60aa0cmsh780": "Doanh số bán hàng",
          "60aa0cmsh9s0": "Chi phí hoàn tiền",
          "60aa0cmshbw0": "Số lượng đơn hàng bán ra",
          "60aa0vmcdtw0": "Sản Phẩm SKU",
          "60aa0vmcec00": "Tên sản phẩm"
        }
      },
      "productStatistics": {
        "components": {
          "60a9yyz55n00": "Biểu đồ xu hướng chỉ số dữ liệu",
          "60a9yyz565o0": "Phần trăm (%)"
        },
        "constants": {
          "60a9xumyah40": "Ngày",
          "60a9xumyav40": "Tổng số sản phẩm",
          "60a9xumyay40": "Số lượng sản phẩm đang bán",
          "60a9xumyb000": "Tỷ lệ sản phẩm đang bán",
          "60a9xumyb1s0": "Số lượng sản phẩm đã bán",
          "60a9xumyb5k0": "Tỷ lệ doanh số",
          "60a9xumyb740": "Số lượng sản phẩm mới đang bán",
          "60a9xumyb8s0": "Tỷ lệ sản phẩm mới đang bán"
        }
      },
      "profitDetail": {
        "components": {
          "60a9u37djso0": "Chi tiết đơn hàng",
          "60a9u37dkc40": "Chi tiết cửa hàng",
          "60a9vuvtmfs0": "Cửa hàng:",
          "60a9vuvtms80": "Trang web:",
          "60a9wn2oggk0": "ID Sản phẩm",
          "60a9wn2ogv80": "SKU của nền tảng",
          "60a9wn2ogz40": "Sản Phẩm SKU",
          "60a9wn2oh1s0": "Chi phí sản phẩm",
          "60a9wn2oh4s0": "Số tiền hoàn lại bởi nền tảng:",
          "60a9wn2oh7g0": "Lợi nhuận:",
          "60a9wn2oha00": "Tỷ lệ lợi nhuận bán hàng:"
        },
        "constant": {
          "60a9mn7mnho0": "Dựa trên chi tiết đơn hàng",
          "60a9mn7mo0g0": "Theo cửa hàng",
          "60a9mn7mo4c0": "Số đơn hàng",
          "60a9mn7mo780": "Số gói hàng",
          "60a9mn7mo9g0": "Số vận đơn",
          "60a9mn7mod80": "Tiêu đề sản phẩm",
          "60a9mn7mofk0": "ID Sản phẩm",
          "60a9mn7mohs0": "SKU của nền tảng",
          "60a9mn7mok40": "Sản Phẩm SKU",
          "60a9mn7momg0": "Thời gian đặt hàng",
          "60a9mn7mook0": "Thời gian thanh toán",
          "60a9mn7mor40": "Thời gian thanh toán",
          "60a9mn7mouc0": "Thời gian giao hàng",
          "60a9mn7moww0": "Thời hạn giao hàng",
          "60a9skxqv140": "Số lượng đơn hàng",
          "60a9skxqvhc0": "Doanh số",
          "60a9skxqvko0": "Thanh toán thực tế của người mua",
          "60a9skxqvn80": "Số tiền hoàn trả ước tính",
          "60a9skxqvpo0": "Số tiền đã giải quyết",
          "60a9skxqvs00": "Số tiền chờ giải quyết",
          "60a9skxqvug0": "Chi phí",
          "60a9skxqvyo0": "Chi phí sản phẩm",
          "60a9skxqw0o0": "Chi phí quảng cáo",
          "60a9skxqw380": "Chi phí vận hành",
          "60a9skxqw5g0": "Chi phí bao bì",
          "60a9skxqw7w0": "Chi phí khác",
          "60a9skxqwbg0": "Lợi nhuận",
          "60a9skxqwdw0": "Tỷ lệ lợi nhuận chi phí",
          "60a9skxqwgg0": "Tỷ lệ lợi nhuận bán hàng",
          "60a9skxqwiw0": "Dữ liệu hôm nay",
          "60a9skxqwl40": "Dữ liệu ngày hôm qua",
          "60a9skxqwnw0": "Dữ liệu tổng hợp",
          "60a9v7pp1n00": "Số Tiền Thanh Toán Ước Lượng",
          "60a9v7pp2qs0": "Số tiền đơn hàng gốc",
          "60a9v7pp30g0": "Thanh toán thực tế của người mua",
          "60a9v7pp33g0": "Phí đóng gói",
          "60a9v7pp3680": "Chi phí khác",
          "60a9v7pp38w0": "Lợi nhuận",
          "60a9v7pp3bo0": "Tỷ lệ lợi nhuận chi phí",
          "60a9v7pp3fo0": "Tỷ lệ lợi nhuận bán hàng",
          "60y9bytj9u80": "Phí xử lý kho bên thứ ba",
          "60y9bytjb480": "Phí vận chuyển kho bên thứ ba"
        }
      },
      "profitsOverview": {
        "constant": {
          "5zmqie08y6k0": "Chỉ số cốt lõi",
          "5zmqie08yp40": "Xu hướng chỉ số cốt lõi",
          "5zmqie08ytg0": "Phân tích chi phí",
          "5zmqie08ywk0": "Phân tích lợi nhuận",
          "5zmqie08yzo0": "Số tiền trả lại từ nền tảng",
          "5zmqie08z2o0": "So với tháng trước",
          "5zmqie08z5c0": "Phí đóng gói",
          "5zmqie08z7w0": "Thanh toán thực tế của người mua",
          "5zmqie08zak0": "Lợi nhuận",
          "5zmqie08zd40": "Chi phí sản phẩm",
          "5zmqie08zfo0": "Tỷ lệ lợi nhuận chi phí",
          "5zmqie08zig0": "Chi phí khác",
          "5zmqie08zks0": "Tỷ lệ lợi nhuận bán hàng"
        },
        "index": {
          "5zmr3g7jskw0": "Tổng quan lợi nhuận"
        }
      },
      "saleDetail": {
        "index": {
          "5zmr52c50ng0": "Tổng quan dữ liệu",
          "5zmr52c517g0": "Chi tiết thống kê sản lượng sản phẩm",
          "5zmr52c51bk0": "Biểu đồ xu hướng chỉ số dữ liệu",
          "5zmvji9oxrk0": "ID Sản phẩm",
          "5zmvji9oz0w0": "SKU của nền tảng",
          "5zmvji9oz700": "Nền tảng",
          "5zmvji9ozao0": "Cửa hàng",
          "5zmvjnye99g0": "Chiều sản phẩm",
          "5zmvlw6cgzg0": "đặc điểm",
          "603i2ug6f3k0": "Thời Gian Bắt Đầu",
          "603i2ug6gbs0": "Thời Gian Kết Thúc",
          "603i2ug6gjc0": "สามารถสืบค้นข้อมูลได้ในช่วง 6 เดือนที่ผ่านมาเท่านั้น โปรดเลือกเวลาสืบค้นอีกครั้ง"
        }
      },
      "salesStatistics": {
        "constant": {
          "5zmqf0amgts0": "Theo SPU (Sản phẩm)",
          "5zmqf0ami0c0": "Theo SKU (Sản phẩm đơn)",
          "5zmqf0ami6o0": "Theo cửa hàng",
          "5zmqf0amib40": "Phạm vi giá",
          "5zmqf0amifc0": "Phạm vi bán hàng",
          "5zmqf0amilc0": "Tổng số sản phẩm",
          "5zmqf0amiqg0": "Số lượng đơn hàng đã thanh toán",
          "5zmqf0amiuo0": "Doanh số",
          "5zmqf0amj000": "Tổng số tiền thanh toán",
          "5zmqf0amj440": "Số lượng đơn hàng hoàn trả",
          "5zmqf0amj8c0": "Số tiền hoàn trả",
          "5zmqf0amjcw0": "Tiêu đề sản phẩm",
          "5zmqf0amjhc0": "ID Sản phẩm",
          "5zmqf0amjkw0": "SKU của nền tảng"
        },
        "hooks": {
          "5zmr1xvtlyw0": "Bộ lọc ngày"
        },
        "index": {
          "5zmr18mjbic0": "Thống kê doanh số"
        }
      },
      "shopStatistics": {
        "components": {
          "60a9ixf7pqs0": "Chi tiết cửa hàng",
          "60a9ixf7q7w0": "Cửa hàng:",
          "60a9ixf7qcg0": "Số lượng khách hàng:",
          "60a9ixf7qfc0": "Số lượng đơn hàng:",
          "60aacanra200": "Thống kê dữ liệu"
        },
        "constant": {
          "60a9gbqw2w80": "Số lượng khách hàng",
          "60a9gbqw3os0": "Số lượng đơn hàng",
          "60a9gbqw3sk0": "Tổng số đơn hàng",
          "60a9gbqw3v80": "Tổng số tiền thanh toán",
          "60a9gbqw3xg0": "Số tiền hoàn trả",
          "60a9gbqw3zw0": "Trung bình mỗi đơn hàng",
          "60a9gbqw42c0": "Số lượng đơn hàng thanh toán",
          "60a9gbqw4d40": "Số lượng khách hàng thanh toán",
          "60a9gbqw4i40": "Số lượng đơn hàng hoàn trả",
          "60a9gbqw4po0": "Số lượng khách hàng yêu cầu hoàn tiền"
        }
      }
    },
    "warehouse": {
      "baseIndex": {
        "5wfvehrixdc0": "Gói Hàng Đã Được Lưu Trữ Ngày Hôm Nay",
        "5wfvehrixtk0": "Đã Lên Kệ Hôm Nay SKU",
        "5wfvehrj1ts0": "Vui Lòng Chọn Kho",
        "5wfvehrj1zs0": "Ký Nhận",
        "5wfvehrj24w0": "Nhập Kho Thủ Công",
        "5wfvehrj29k0": "Thủ Công Ra Khỏi Kho",
        "5wfvehrj2ig0": "Chọn Sản Phẩm Duy Nhất",
        "5wfvehrj2ng0": "Yêu Cầu Hàng Hóa",
        "5wfvehriixa0": "Kho Hàng",
        "5wfvehrj2na0": "Quét Giao Hàng",
        "61s2n6t0kpc0": "Kiểm Tra Quét",
        "619ereolw8g0": "Trả hàng vào",
        "62xp0659vcg0": "Quét Danh Sách Kiểm Tra"
      },
      "components": {
        "packageItem": {
          "5rnxjrj9pt00": "Vui Lòng Nhập/Quét Số Theo Dõi Hậu Cần Và Số Theo Dõi Mua Hàng",
          "5rnxl2ugdn80": "Loại Bảo Quản",
          "5rnxl2uge480": "Số Lượng Lưu Trữ",
          "5rnxl2ugey80": "Ghi Chú"
        }
      },
      "goodsSearch": {
        "baseIndex": {
          "5weqyxxhmtg0": "Yêu Cầu Hàng Hóa",
          "5weqyxxhnfg0": "Quét Sản Phẩm SKU/Mã Sản Phẩm",
          "5weqyxxhnjw0": "Vui Lòng Quét Thông Tin Sản Phẩm",
          "5weqyxxhnms0": "Vui Lòng Nhập Mã Sản Phẩm/Mã Sản Phẩm",
          "5weqyxxhnpk0": "Đang Tải",
          "5weqyxxhnsk0": "Không Tìm Thấy Sản Phẩm Này"
        },
        "components": {
          "GoodsCard": {
            "GoodsInfo": {
              "5wer21zf7rk0": "Mã Sản Phẩm",
              "5wer21zf8jg0": "Gần 7/30/60 Ngày Bán Hàng"
            },
            "index": {
              "5wer2vtlaf40": "Sản Phẩm Phụ"
            },
            "StorageInfo": {
              "5wer3yv4lx40": "Vị Trí",
              "5wer3yv4mgo0": "Tổng Lượng Hàng Tồn Kho",
              "5wer3yv4o8g0": "Có Sẵn",
              "5wer3yv4ofw0": "Chiếm Trước"
            }
          }
        }
      },
      "processStorage": {
        "baseIndex": {
          "5rny2tblyc00": "Quét Hàng Hóa",
          "5rny2tblz7g0": "Vui Lòng Nhập/Quét Sản Phẩm, Mã Sản Phẩm",
          "5rny2tblzds0": "Kết Thúc Lưu Trữ Này",
          "5rny2tblzkw0": "Xuất Kho",
          "5rny2tblzps0": "Lưu Trữ",
          "5rny2tblztk0": "Không Tìm Thấy Sản Phẩm",
          "5rny2tblzx00": "Kết Hợp Không Được Lưu Trữ",
          "5rny2tbm00g0": "Đang Tải",
          "5rny3yibbno0": "Thành Công",
          "5rny51km7e40": "Số Lượng Chuyến Đi Không Được Vượt Quá",
          "5rolykv54d00": "Vui Lòng Nhập Sản Phẩm SKU"
        },
        "components": {
          "GoodsPopup": {
            "5rnxodldrdc0": "Loại",
            "5rnxodlds640": "Miếng",
            "5rnxodldsdc0": "Gửi"
          },
          "SetQuantityDialog": {
            "5rnxpb2qwsg0": "Vui Lòng Nhập Số Lượng",
            "5rnxu9u7r2c0": "Lần Này",
            "5ron09jvyh80": "Số",
            "5rnxpb2qxg00": "Số Lượng Không Được Để Trống",
            "5rnxu9u7q4w0": "Số Lượng Không Được Vượt Quá"
          }
        },
        "processGoodsWithStoragePage": {
          "baseIndex": {
            "5rnxzgpf7i00": "Hàng Hóa",
            "5rnxzgpf8co0": "Tên:",
            "5rnxzgpf8io0": "Đặc Điểm Kỹ Thuật:",
            "5rnxzgpf8mg0": "Số Lượng Lưu Trữ:",
            "5rnxzgpf8q40": "Lần Này",
            "5rnxzgpf8tk0": "Không Có Vị Trí",
            "5rnxzgpf8ww0": "Vị Trí Không Được Chọn",
            "5rnxzgpf90g0": "Xóa",
            "5rnxzgpf93s0": "Thay Đổi Vị Trí",
            "5rnxzgpf97c0": "Xuất Kho",
            "5rnxzgpf9ao0": "Lưu Trữ",
            "5rnxzgpf9e80": "Vị Trí Không Được Trống, Vui Lòng Kiểm Tra Vị Trí",
            "5rnxzgpf9hs0": "Đang Hoạt Động",
            "5rnxzgpf9l80": "Dữ Liệu Bất Thường, Vui Lòng Liên Hệ Với Dịch Vụ Khách Hàng",
            "5rny0rcmwbk0": "Đang",
            "5werec5q0lc0": "Chắc Chắn"
          }
        },
        "scanStorageCellPage": {
          "baseIndex": {
            "5rny1og9cek0": "Quét Vị Trí",
            "5rny1og9d740": "Vui Lòng Quét/Nhập Số Vị Trí",
            "5rny1og9ddc0": "Vui Lòng Quét Vị Trí",
            "5rny1og9dgw0": "Đang Truy Vấn",
            "5roninktevk0": "Sản Phẩm Không Có Hàng Tồn Kho Tại Vị Trí Này"
          }
        }
      },
      "putaway": {
        "baseIndex": {
          "5rnyj390bf00": "Gói Quét",
          "5rnyj390c1o0": "Vui Lòng Nhập/Quét Số Theo Dõi Hậu Cần Và Số Theo Dõi Mua Hàng",
          "5rnxm6vsad80": "Vui Lòng Quét Bưu Kiện",
          "5rnyj390c7g0": "Đơn Mua Hàng Được Ký",
          "5rnyj390cb40": "Đang Tải",
          "5rnyj390ce80": "Không Có Dữ Liệu",
          "5rnyj390cho0": "Nhận Đơn Đặt Hàng Thành Công"
        },
        "components": {
          "WaitReceivePurchaseOrderList": {
            "5rnyh09ydbg0": "Không Có Dữ Liệu"
          }
        },
        "purchaseOrderInfoPage": {
          "baseIndex": {
            "5rnyibnc0h40": "Gói Quét",
            "5rnyibnc14g0": "Tên:",
            "5rnyibnc1a00": "Đặc Điểm Kỹ Thuật:",
            "5rnyibnc1dw0": "Mã:",
            "5rnyibnc1hc0": "Đã Đến Cuối",
            "5rnyibnc1l00": "Lưu Trữ Trực Tiếp",
            "5rnyibnc1ow0": "Quét Kho Hàng",
            "5rnyibnc1sg0": "Sau Khi Nhập Kho Trực Tiếp, Lượng Hàng Tồn Kho Sẽ Tăng Lên.",
            "5rnyibnc1w80": "Đang Lưu Trữ..."
          }
        }
      },
      "scanInspection": {
        "components": {
          "GoBackConfirmPopup": {
            "61x928ywr8g0": "Trả Về Sẽ Xóa Dữ Liệu, Xác Nhận Trả Về?"
          },
          "LogisticsGroupPopup": {
            "61s2ulkrbng0": "Vui Lòng Chọn Hậu Cần"
          },
          "PackageInfoContainer": {
            "61s2u6o99vg0": "Tin Nhắn Của Người Mua",
            "61s2u6o9ad40": "Ghi Chú Kinh Doanh",
            "61s2u6o9ag40": "Ghi Chú Địa Phương",
            "61s2u6o9ahw0": "Sản Phẩm SKU",
            "61s2u6o9ak00": "Số Lượng Hàng Hóa",
            "61s2u6o9als0": "Đã Xác Minh"
          },
          "ResetConfirmPopup": {
            "61s2tn0lhys0": "Sau Khi Lưu, Thông Tin Gói Hiện Được Quét Sẽ Được Xóa Và Bạn Có Thể Tiếp Tục Không?",
            "61s2tn0lifc0": "Không Lưu",
            "61s2tn0liig0": "Tiếp Tục Lưu"
          },
          "ScanSettingPopup": {
            "61s2t7h33f00": "Cài Đặt Quét",
            "61s2t7h348w0": "Sau Khi Kiểm Tra Thành Công, Giao Hàng Tự Động",
            "61s2t7h34e40": "Lưu"
          }
        },
        "constant": {
          "61s2rr94jzk0": "Quét Sản Phẩm SKU/Gtin",
          "61s2rr94g7o0": "Quét Hoặc Nhập Số Bưu Kiện/Số Vận Đơn"
        },
        "hooks": {
          "useLogisticsGroupList": {
            "61s2s5zka4k0": "Tất Cả Hậu Cần"
          },
          "useScanInspection": {
            "61s2sk0fjss0": "Gói Quét Hiện Tại Đã Được Xác Minh",
            "61s2sk0fnpk0": "Đang Tải",
            "61s2sk0fnwg0": "Không Có Sản Phẩm Trong Gói",
            "61s2sk0fo0w0": "Sản Phẩm Đã Được Xác Minh",
            "61s2sk0fo4o0": "Đang Vận Chuyển",
            "61s2sk0fo840": "Một Số Sản Phẩm Đang Được Kiểm Kê, Sau Khi Kiểm Kê Xong Sẽ Tự Động Trừ Hàng Tồn Kho",
            "61s2sk0fob00": "Giao Hàng Thất Bại",
            "61s2sk0fod40": "Giao Hàng Thành Công",
            "61s2sk0fofs0": "Đang Lưu",
            "61wcj39boc80": "Không tìm kiếm được gói hàng này hoặc gói hàng đã được gửi",
            "62hjyf276wk0": "Quét Và Kiểm Tra Thành Công"
          }
        },
        "index": {
          "61s2pxoyy5s0": "Kiểm Tra Quét",
          "61s2pxoz2380": "Cài Đặt",
          "61s2pxoz29w0": "Hồ Sơ",
          "61s2pxoz2ds0": "Vui Lòng Quét Thông Tin Bưu Kiện"
        },
        "verifiedListPage": {
          "index": {
            "61s304k9b880": "Hồ Sơ Xác Minh",
            "61s304k9cfo0": "Số Đơn Hàng/Số Bưu Kiện/PHƯƠNG THỨC Hậu Cần/Số Vận Đơn",
            "61s304k9cm40": "Số gói hàng",
            "61s304k9cos0": "Số đơn hàng",
            "61s304k9d7g0": "Thông Tin Hậu Cần",
            "61s304k9dck0": "Tình Trạng Giao Hàng",
            "61s304k9dkw0": "Tất Cả",
            "61s304k9dno0": "Giao Hàng Thành Công",
            "61s304k9dqc0": "Giao Hàng Thất Bại"
          }
        }
      },
      "scanReturn": {
        "components": {
          "WaitReturnGoodsList": {
            "619evhn1suw0": "Loại Bảo Quản",
            "619evhn1txg0": "Số Lượng Lưu Trữ",
            "619evhn1u580": "Không Có Dữ Liệu"
          }
        },
        "hooks": {
          "useReturnGoods": {
            "619eub7jj1s0": "Đang Lưu Trữ...",
            "61wzjfwnawk0": "Số lượng hàng nhập không được vượt quá {0}"
          },
          "useScan": {
            "619euwhlq5o0": "Đang Tải",
            "619euwhlqyg0": "Không Có Dữ Liệu"
          }
        },
        "returnOrderInfoPage": {
          "index": {
            "619etj4urs80": "Trả hàng vào",
            "619etj4usrk0": "Tên:",
            "619etj4usxs0": "Đặc Điểm Kỹ Thuật:",
            "619etj4ut1s0": "Đã Đến Cuối",
            "619etj4ut540": "Lưu Trữ Trực Tiếp",
            "619etj4ut980": "Quét Kho Hàng",
            "619etj4uteg0": "Sau Khi Nhập Kho Trực Tiếp, Lượng Hàng Tồn Kho Sẽ Tăng Lên."
          }
        },
        "constant": {
          "619espdpd100": "Gói Quét",
          "619espdpe9k0": "Đơn hàng trả hàng đang chờ để nhập vào kho"
        },
        "index": {
          "619es2vhols0": "Vui Lòng Quét Bưu Kiện",
          "619es2vho3o0": "Trả hàng vào",
          "619es2vhoi80": "Vui lòng nhập/quét số vận đơn, số đơn hàng hoặc số kiện hàng",
          "619es2vhooo0": "Đơn hàng trả hàng đã được nhập thành công vào kho"
        }
      },
      "scanShipment": {
        "components": {
          "List": {
            "5wsld4f61gw0": "Không Có Dữ Liệu"
          },
          "OrderPackageItemMedia": {
            "5wsld4f61gw0": "1. Hình Ảnh/Video Đã Tải Lên Có Thể Được Xem Trong Không Gian Hình Ảnh",
            "5wsld4f61gw1": "2. Video Không Được Vượt Quá 30M Và Thời Lượng Không Được Vượt Quá 1 Phút",
            "5wsld4f61gw2": "Tải Lên Hình Ảnh",
            "5wsld4f61gw3": "Tải Lên Video",
            "5wsld4f61gw4": "Chọn Video",
            "5wsld4f61gw5": "Hình Ảnh",
            "5wsld4f61gw6": "Video",
            "5wsld4f61gw7": "Thời Lượng Không Quá 1 Phút"
          },
          "PackageItemGoods": {
            "5wsldq8io7w0": "Quy Cách"
          },
          "ShipmentButton": {
            "5wsle2o8bvc0": "Giao Hàng"
          }
        },
        "hooks": {
          "useList": {
            "5wslbuh0fts0": "Gói Hàng Đã Được Quét Lần Này",
            "5wslbuh0j1c0": "Đang Tải",
            "5wslbuh0j8g0": "Không Có Dữ Liệu"
          },
          "useShipmentPackage": {
            "5wslcgxgpio0": "Đang Hoạt Động",
            "5wslcgxgu5g0": "Giao Hàng Thất Bại",
            "5wslcgxgug00": "Giao Hàng Thành Công"
          }
        },
        "index": {
          "5wsl89cm3gw0": "Quét Giao Hàng",
          "5wsl89cm7z80": "Quét Hoặc Nhập Số Bưu Kiện/Số Vận Đơn",
          "5wsl89cm8980": "Đã Quét",
          "5wsl89cm8ds0": "Vui Lòng Quét Thông Tin Bưu Kiện",
          "5wsl89cm8hc0": "Đang Tải",
          "5wsl89cm8hc1": "Giao Hàng Tự Động Sau Khi Quét"
        },
        "scannedListPage": {
          "index": {
            "5wslasiw3rc0": "Đã Quét",
            "5wslasiw8r40": "Bạn Có Chắc Chắn Muốn Xóa Gói Hàng Không?",
            "5wslasiw9200": "Sau Khi Quay Lại, Danh Sách Các Gói Đã Quét Sẽ Bị Xóa, Chắc Chắn Để Tiếp Tục?",
            "5wslasiw9940": "Tất Cả",
            "5wslasiw9f40": "Không Có Gói Giao Hàng"
          }
        }
      },
      "singlePick": {
        "baseIndex": {
          "5wesij4wgig0": "Chọn Sản Phẩm Duy Nhất",
          "5wesij4whps0": "Quét/Nhập Số Sóng",
          "5wesij4whv40": "Chắc Chắn Bạn Muốn Đánh Dấu Trạng Thái Hoàn Thành?",
          "5wesij4whyk0": "Để Tránh Việc Lấy Hàng Nhiều Lần, Bạn Nên Xác Nhận Với Người Dùng Tài Khoản Hoặc Kiểm Tra Nhật Ký Thao Tác Trước Khi Bắt Đầu Lấy Hàng",
          "5wesij4wi1s0": "Sau Khi Đánh Dấu, Sóng {0} Sẽ Không Thể Chọn Hàng",
          "5wesij4wi5s0": "Tài Khoản:{0} Hoạt Động Lấy Hàng Tại {1}",
          "5wesij4wi0s0": "Và Sóng Sẽ Chuyển Sang Trạng Thái Đóng Gói"
        },
        "components": {
          "WaveList": {
            "WaveItem": {
              "5wesqu5fcgs0": "Đã Chọn/Sẽ Chọn",
              "5wesqu5fi8s0": "Thông Tin Sản Phẩm",
              "5wesqu5fihk0": "Tạo Thời Gian",
              "5wesqu5fimg0": "Người Sáng Tạo",
              "5wesqu5fiv00": "Thêm",
              "5wesqu5fizs0": "Bắt Đầu Chọn Hàng",
              "5wesqu5fj3w0": "Đánh Dấu Hoàn Thành",
              "5wesqu5fj800": "Nhật Ký Lấy Hàng",
              "5wesqu5fjck0": "{0} Các Loại Sku, Tổng Cộng {1}"
            }
          }
        },
        "hooks": {
          "useOperate": {
            "5wespf869ys0": "Đang Tải",
            "5wespf86efs0": "Tiếp Tục Lấy Hàng",
            "5wespf86et80": "Nhật Ký Lấy Hàng",
            "5wespf86eys0": "Trạng Thái Sóng Đã Được Cập Nhật, Vui Lòng Thả Xuống Để Làm Mới Trang Để Xem"
          }
        },
        "subPages": {
          "pickLog": {
            "baseIndex": {
              "5weyeh0dlbs0": "Nhật Ký Lấy Hàng"
            },
            "components": {
              "LogItem": {
                "5weygrs8su40": "Sản Phẩm SKU",
                "5weygrs8xvc0": "Số Lượng Lấy Hàng"
              }
            }
          },
          "picking": {
            "baseIndex": {
              "5wey0wt55a40": "Quét/Nhập Sku, Mã Sản Phẩm",
              "5wey0wt64ns0": "Các Loại Sku,",
              "5wey0wt650g0": "Tổng Cộng",
              "5wey0wt65b00": "Cái",
              "5wey1e5fupo0": "Số Lượng Còn Lại:"
            },
            "components": {
              "SetPickQuantityDialog": {
                "5wey82q92us0": "Điền Số Lượng Đã Chọn",
                "5wey82q99rg0": "Vui Lòng Nhập",
                "5wey82q9a100": "Vui Lòng Nhập Số Lượng Đã Chọn",
                "5wey82q9a1g0": "Số Lượng Được Chọn Không Được Lớn Hơn Số Lượng Được Chọn:{0}"
              },
              "SkuItem": {
                "5wey9z9uhxs0": "Mã Sản Phẩm",
                "5wey9z9uoe00": "Vị Trí",
                "5wey9z9uook0": "Đã Chọn",
                "5wey9z9uoyw0": "Để Chọn"
              },
              "WavePickFinishDialog": {
                "5weycylwol80": "Mẹo",
                "5weycylwsss0": "Tôi Đã Biết",
                "5weycylwt1c0": "Đợt Hiện Tại Đã Hoàn Thành!"
              }
            },
            "hooks": {
              "usePicking": {
                "5wey5ujb4xs0": "Sản Phẩm Này Không Tồn Tại Trong Sóng Hiện Tại",
                "5wey5ujbaeo0": "Sản Phẩm SKU:{0} Đã Hoàn Thành Việc Lấy Hàng"
              }
            }
          }
        }
      }
    }
  },
  "system": {
    "confirm": "Chắc Chắn",
    "cancel": "Hủy Bỏ",
    "pleaseSelect": "Vui Lòng Chọn",
    "5rnxe856we80": "Mẹo",
    "5rnxe856wl00": "Xác Nhận",
    "loading": "Đang Tải",
    "copySuccess": "Sao Chép Thành Công",
    "emptyShelfCell": "Không Có Vị Trí",
    "versionInfo": "Thông Tin Phiên Bản",
    "language": {
      "toggle": "Chuyển Đổi Ngôn Ngữ",
      "selectEmpty": "Vui Lòng Chọn Ngôn Ngữ"
    },
    "tabBar": {
      "stat": "Dữ liệu",
      "home": "Kho Hàng",
      "my": "Của Tôi"
    },
    "update": {
      "title": "Cập Nhật Mẹo",
      "forceUpdate": "Khám Phá Phiên Bản Mới, Khởi Động Lại Ngay Bây Giờ",
      "fail": "Cập Nhật Thất Bại",
      "newVersion": "Khám Phá Phiên Bản Mới",
      "updateNow": "Cập Nhật Ngay",
      "updateLater": "Cập Nhật Tiếp Theo"
    }
  }
}