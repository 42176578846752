import request from '@/utils/request.js';
import { getAesEncryptData } from '@/utils/crypto.ts';

const apiPrefix = `/account`;

export function login(data) {
  return request.post(
    `${apiPrefix}/login`,
    {
      ...data,
      ...getAesEncryptData(data, ['mobile', 'password']),
    },
    {
      custom: { isToast: false },
    }
  );
}
export function getAccountInfo(
  custom = { isToast: false, isCallLogout: false }
) {
  return request.get(`${apiPrefix}/getAccountInfo`, { custom });
}
export function loginOut() {
  return request.get(`${apiPrefix}/loginOut`, {
    custom: { isToast: false, isCallLogout: false },
  });
}
export function sendFindPasswordCode(data) {
  return request.post(`${apiPrefix}/sendFindPasswordCode`, {
    ...data,
    ...getAesEncryptData(data, ['mobile']),
  });
}
export function sendValidateCode(data) {
  return request.post(`${apiPrefix}/sendValidateCode`, {
    ...data,
    ...getAesEncryptData(data, ['mobile']),
  });
}
export function findPassword(data) {
  return request.post(`${apiPrefix}/findPassword`, {
    ...data,
    ...getAesEncryptData(data, ['password', 'mobile']),
  });
}
export function modifyPassword(data) {
  return request.post(`${apiPrefix}/modifyPassword`, data);
}
export function abroadRegister(data) {
  return request.post(`${apiPrefix}/abroadRegister`, {
    ...data,
    ...getAesEncryptData(data, ['email', 'mobile', 'password']),
  });
}
export function register(data) {
  return request.post(`${apiPrefix}/register`, {
    ...data,
    ...getAesEncryptData(data, ['email', 'mobile', 'password']),
  });
}
export function getShopGroupList(data) {
  return request.post(`${apiPrefix}/getShopGroupList`, data);
}
export function getPlatformInfiniteMap(data) {
  return request.post(`${apiPrefix}/getPlatformInfiniteMap`, data);
}

export function getSmsRegionList() {
  return request.get(`${apiPrefix}/getSmsRegionList`);
}

export function getAgreementUrl(params) {
  return request.get(`${apiPrefix}/getAgreementUrl`, { params });
}

export function sendEmailRegisterValidateCode(data) {
  return request.post(`${apiPrefix}/sendEmailRegisterValidateCode`, {
    ...data,
    ...getAesEncryptData(data, ['email']),
  });
}

export function getPlatformShopGroupList() {
  return request.get(`/auth/account/getPlatformShopGroupList`);
}

export function getAppAccountExtConfigOptions(data) {
  return request.post(`/auth/account/getAppAccountExtConfigOptions`, data);
}

export function sendRemoteLoginValidateCodeByVerifyId(data) {
  return request.post(
    `${apiPrefix}/sendRemoteLoginValidateCodeByVerifyId`,
    data
  );
}

export function loginByRemoteLoginVerifyId(data) {
  return request.post(`${apiPrefix}/loginByRemoteLoginVerifyId`, data);
}

export function saveAppAccountExtConfig(data) {
  return request.post('/auth/account/saveAppAccountExtConfig', data);
}

export function getAliCaptchaConfig(data) {
  return request.post(`${apiPrefix}/getAliCaptchaConfig`, data);
}

export function getClientIpGeo() {
  return request.get(`${apiPrefix}/getClientIpGeo`);
}
