const navigateTo = uni.navigateTo
const redirectTo = uni.redirectTo
const reLaunch = uni.reLaunch
const switchTab = uni.switchTab
const navigateBack = uni.navigateBack
uni.navigateTo = navigateTo
uni.redirectTo = redirectTo
uni.reLaunch = reLaunch
uni.switchTab = switchTab
uni.navigateBack = navigateBack
;
import App from './App';
import { createSSRApp } from 'vue';
import * as Pinia from 'pinia';
import uView from './uni_modules/vk-uview-ui';
import i18n, { loadLocaleMessages } from './locale/index.js';
import { setupRouter } from './router';

export function createApp() {
  loadLocaleMessages(i18n);

  const app = createSSRApp(App);
  app.use(Pinia.createPinia());
  app.use(uView);
  app.use(i18n);
  setupRouter(app);

  return {
    app,
    Pinia,
  };
}
