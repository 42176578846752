
import { createI18n } from 'vue-i18n';
import { LANGUAGE_MODE } from '../constants/localStorageKey.js';
import { LANGUAGE_LIST, DEFAULT_LANGUAGE, LANGUAGE_MAP } from '../constants/language.js';
// import ms from '@locale/ms/index.js';
import zhHans from '@locale/zh-Hans/index.js';
import zhHant from '@locale/zh-Hant/index.js';
import en from '@locale/en/index.js';
import id from '@locale/id/index.js';
import vi from '@locale/vi/index.js';
import th from '@locale/th/index.js';

let defaultLocale = uni.getLocale();
const hasLocale = LANGUAGE_LIST.find((item) => {
  return item.browerLanguage.includes(defaultLocale);
});
if (!hasLocale) {
  defaultLocale = LANGUAGE_MAP[DEFAULT_LANGUAGE].value
}

export const isEnMode = () => {
  const locale = uni.getStorageSync(LANGUAGE_MODE) || uni.getLocale();
  return locale === 'en';
};
export const isCnMode = () => {
  const locale = uni.getStorageSync(LANGUAGE_MODE) || uni.getLocale();
  return ['zh-Hans', 'zh-Hant'].includes(locale);
};

export async function loadLocaleMessages(i18n, locale) {
  const _locale = locale || uni.getStorageSync(LANGUAGE_MODE) || defaultLocale;
  const localeMap = {
    'zh-Hans': zhHans,
    'zh-Hant': zhHant,
    en,
    id,
    // ms,
    vi,
    th,
  };
  uni.setLocale(_locale);
  const messages = localeMap[_locale];
  i18n.global.setLocaleMessage(_locale, messages);
  i18n.global.locale.value = _locale;
}

function setupI18n() {
  const _locale = uni.getStorageSync(LANGUAGE_MODE) || defaultLocale;

  const i18n = createI18n({
    locale: _locale,
    fallbackLocale: defaultLocale,
    legacy: false,
    messages: {
      'zh-Hans': zhHans,
    },
  });

  return i18n;
}

export default setupI18n();
