export default {
  "components": {
    "Account": {
      "UnReceivedCodeLink": {
        "5xz3hmxq1380": "未收验证码？",
        "5xz3hmxq4ws0": "关闭",
        "5xz3hmxq5800": "可能被识别为垃圾邮件，请至垃圾邮件中查看",
        "5xz3hmxq5fc0": "请检查您输入的邮箱是否正确，或者尝试使用其他邮箱注册",
        "5xz3hmxq5lo0": "请检查当前网络问题是否正常",
        "5xz3hmxq5s40": "未收到验证码？"
      }
    },
    "Empty": {
      "5weyj6xvl9o0": "暂无数据"
    },
    "ProComponent": {
      "ProConfirmPopup": {
        "5weyne0t7t40": "提示",
        "5weyne0tctw0": "确定",
        "5weyne0td2o0": "取消"
      }
    },
    "ScanCodeView": {
      "ScanCodeView": {
        "5weysudo0zk0": "此快递单号存在非法字符，请重新扫描"
      },
      "ScanCameraApp": {
        "5weas1do0zk0": "正在获取相机信息"
      }
    },
    "SearchBackBar": {
      "5weyueldnb00": "筛选"
    },
    "SelectView": {
      "5weyy43t5v00": "请输入搜索关键词",
      "5weyy43tasw0": "暂无数据"
    },
    "ShopTag": {
      "60aatigyeig0": "跨境"
    },
    "Warehouse": {
      "ScanModeSelect": {
        "5wez10tdkk80": "请选择扫描方式",
        "5wez10tdpow0": "激光扫描",
        "5wez10tdpxg0": "相机扫描"
      },
      "StockModeSelect": {
        "5wez1zd63kw0": "请选择入库方式",
        "5wez1zd68ag0": "请选择出库方式"
      },
      "WarehouseSelect": {
        "5wez1mdf9v80": "请选择仓库",
        "5wez1mdfep80": "没有仓库权限，请联系主账号添加"
      }
    }
  },
  "constants": {
    "package": {
      "5wskfpirawg0": "待提交",
      "5wskfpirer00": "失败",
      "5wskfpirey40": "成功",
      "61y193pca400": "未使用库存",
      "61y193pcccg0": "使用库存",
      "61y193pccj40": "使用库存发货",
      "61y193pccnc0": "使用第三方库存",
      "61y193pccr00": "使用第三方库存发货",
      "61y193pccv40": "预占库存已取消",
      "62xov7r45u40": "使用供应商库存",
      "62xov7r488c0": "使用供应商库存发货",
      "62xov7r48g00": "全部",
      "62xov7r48k40": "未付款",
      "62xov7r48ns0": "待处理",
      "62xov7r48rs0": "待打单",
      "62xov7r48vs0": "待揽收",
      "62xov7r48zw0": "已发货",
      "62xov7r493g0": "售后中",
      "62xov7r49vg0": "已关闭",
      "62xov7r4aa80": "已搁置",
      "62xov7r4af40": "已完成",
      "62xov7r4akw0": "追加商品",
      "62xov7r4aps0": "赠品",
      "wait_seller_send": "待发货",
      "refunding": "退款中",
      "in_cancel": "取消中",
      "returned": "已退还",
      "lost": "已丢失",
      "failed": "妥投失败",
      "wait_confirmed": "待审核"
    },
    "platform": {
      "5zwog8ot6ow0": "手工订单",
      "5zwog8ot8zs0": "零售订单",
      "602uc1886b40": "lazada全托管"
    },
    "shipment": {
      "5wsjxwq908g0": "扫描1次",
      "5wsjxwq96nc0": "扫描搜寻包裹并提交发货",
      "5wsjxwq970k0": "扫描2次",
      "5wsjxwq97880": "扫描后，先搜寻包裹，再次扫描才自动发货",
      "5wsjxwq97ek0": "手动",
      "5wsjxwq97lo0": "自动",
      "5wsjxwq97s80": "忽略",
      "5wsjxwq97yo0": "强制",
      "5yjh2ncp5go0": "开启",
      "5yjh2ncp6p80": "关闭"
    },
    "warehouse": {
      "62xoyv9pqz40": "单品",
      "62xoyv9prkw0": "组合品",
      "62xoyv9prpc0": "多规格",
      "62xoyv9prsg0": "自营仓库",
      "62xoyv9pruw0": "第三方仓库",
      "62xoyv9prxg0": "供应商"
    }
  },
  "page_subject": {
    "order": {
      "components": {
        "SelectOrderPackagePopup": {
          "62xp0pvghso0": "请选择包裹",
          "62xp0pvgi6k0": "确定",
          "62xp1g1eos00": "包裹号：",
          "62xp1g1ep5s0": "运单号：",
          "6302m89rsa00": "扫描订单："
        }
      },
      "orderDetail": {
        "components": {
          "EditAppNotePopup": {
            "62xpaforad40": "取消",
            "62xpaforarg0": "拣货备注",
            "62xpaforatw0": "保存",
            "62xpaforavw0": "请输入拣货备注"
          },
          "OrderHeaderInfo": {
            "62xpa6a0ixw0": "订单状态",
            "62xpa6a0jdo0": "订单号",
            "62xpa6a0jgo0": "包裹号",
            "62xpa6a0jk00": "物流",
            "62xpa6a0jls0": "运单号"
          },
          "OrderItemsInfo": {
            "62xp9e9s1jw0": "有{0}个产品未配对仓库商品",
            "62xp9e9s26c0": "未配对商品SKU",
            "62xp9e9s2ao0": "平台SKU",
            "62xp9e9s2dg0": "商品SKU",
            "62xp9e9s2fs0": "包裹产品",
            "62xp9e9s2j00": "仓库商品",
            "62xp9e9s2l40": "组合品",
            "630xvmqtrsg0": "分销"
          },
          "OrderNoteInfo": {
            "62xp8zszea00": "买家留言",
            "62xp8zszes00": "商家备注",
            "62xp8zszevw0": "拣货备注"
          },
          "OrderTimeAndConsigneeInfo": {
            "62xp8jq79go0": "下单时间",
            "62xp8jq79wk0": "付款时间",
            "62xp8jq7a080": "收件人",
            "62xp8jq7a2w0": "联系电话",
            "62xp8jq7a580": "收件地址",
            "6303ysgdkgw0": "提醒：{0}官方要求，收件人信息脱敏展示"
          }
        },
        "hooks": {
          "useOrderDetail": {
            "62xp6xk9r7w0": "保存中",
            "62xp6xk9s0c0": "保存成功",
            "62xp6xk9s7k0": "加载中"
          }
        },
        "index": {
          "62xp5jhypsk0": "订单详情",
          "62xp5jhyqh00": "同订单其他包裹"
        }
      }
    },
    "warehouse": {
      "components": {
        "ScanOrderPackageStatusErrorPopup": {
          "62xpfn9iq1o0": "订单状态",
          "62xpfn9iqi80": "订单号",
          "62xpfn9iqmc0": "包裹号",
          "62xpfn9iqow0": "物流",
          "62xpfn9iqr40": "运单号",
          "62xpfn9iqxc0": "订单详情",
          "62xpfn9iqzw0": "关闭"
        }
      },
      "scanSearchOrder": {
        "index": {
          "62xpe5xweg40": "扫描查单",
          "62xpe5xwez80": "扫描或输入包裹号/运单号/订单号",
          "62xpe5xwf2s0": "请扫描包裹信息",
          "62xpe5xwf4o0": "加载中",
          "62xpe5xwf6c0": "未搜到该包裹"
        }
      }
    }
  },
  "pages": {
    "account": {
      "components": {
        "TwoFactorAuthenticationPopup": {
          "60ae807iv8k0": "异地登录安全验证",
          "60ae807ivnc0": "您正在",
          "60ae807ivq00": "异地登录",
          "60ae807ivq01": "，",
          "60ae807ivrw0": "为保障账号安全，登录前请完成验证。",
          "60ae807ivtg0": "获取验证码",
          "60ae807ivvs0": "立即验证",
          "60ae807ivys0": "请输入邮箱验证码",
          "60ae807iw0s0": "请输入短信验证码",
          "60ae807iw3o0": "请输入验证码!",
          "60ae807iw5w0": "正在获取验证码",
          "60ae807iw7s0": "验证码发送成功",
          "60ae8fkmn680": "重新发送{0}",
          "60gic918gtg0": "验证成功",
          "60gicqrsmqk0": "登录地"
        }
      },
      "currencyAndTimeZone": {
        "currencyAndTimeZone": {
          "index": {
            "60wmecfnlrg4": "选择币种与时区",
            "60wmecfnlrg0": "币种",
            "60wmecfnmfo0": "请选择币种",
            "60wmecfnmfo2": "系统内商品成本、利润等金额，将用{0}展示；选择后，不支持修改",
            "60wmecfnmjs0": "时区",
            "60wmecfnmms0": "请选择时区",
            "60wmecfnmpo0": "保存",
            "60wmecfnms40": "提示",
            "60wmecfnmuo0": "选择了币种后，不支持再修改，确定选择当前币种？",
            "60wmecfnmws0": "保存成功"
          }
        }
      },
      "forget-password": {
        "index": {
          "5weq2lrg6780": "手机号码",
          "5weq2lrg7a40": "请输入手机号",
          "5weq2lrg7fg0": "新密码",
          "5weq2lrg7io0": "请输入密码",
          "5weq2lrg7lk0": "密码至少8个字符，包含字符、数字、特殊符号",
          "5weq2lrg7os0": "确认密码",
          "5weq2lrg7s00": "验证码",
          "5weq2lrg7uw0": "请输入验证码",
          "5weq2lrg8580": "重置密码",
          "5weq2lrg88k0": "手机号格式不正确",
          "5weq2lrg8bg0": "两次输入的密码不一致",
          "5weq2lrg8eg0": "正在获取验证码",
          "5weq2lrg8h40": "验证码已发送",
          "5weq2lrg8jw0": "修改成功"
        },
        "page": {
          "forgetPassword": "忘记密码"
        }
      },
      "login": {
        "5rnx1lpiie80": "账号",
        "5rnx1lpiwp40": "密码",
        "5rnx1lpiwz40": "登录",
        "5rnx1lpix540": "忘记密码",
        "5rnx1lpix9s0": "注册账号",
        "5rnx1lpixes0": "请输入邮箱/手机号/子账号",
        "5rnx1lpixko0": "邮箱/手机号/子账号必填",
        "5rnx1lpixps0": "账号不存在，请重新输入",
        "5rnx1lpixus0": "密码必填",
        "5rnx1lpiy5g0": "密码错误，请重新输入",
        "5rnx1lpiyk00": "账号不存在",
        "5rnx1lpiyqc0": "密码不正确",
        "5rnx1lpiyv00": "获取验证码",
        "5rnx1lpiyyc0": "秒重新获取",
        "5rnx1lpiz2g0": "重新获取"
      },
      "register": {
        "register": {
          "placeholder": {
            "email": "请输入电子邮箱",
            "password": "请输入密码",
            "password2": "再次确认密码",
            "mobile": "请输入手机号",
            "emailValidateCode": "请输入邮箱验证码",
            "mobileValidateCode": "请输入手机验证码"
          },
          "getValidateCodeBtn": "获取验证码",
          "agreeCheckTipsBefore": "我已阅读并同意",
          "agreeCheckTipsAfter": {
            "userAgreement": "用户服务协议、",
            "privacyAgreement": "隐私协议"
          },
          "confirm": "免费注册",
          "success": {
            "message": "注册成功",
            "tips": "请按以下步骤登录并使用EasyBoss",
            "copyLink": "复制链接",
            "jumpToPc": "进入电脑端软件界面"
          },
          "validatorError": {
            "password": "密码至少8个字符，包含字符、数字、特殊符号",
            "email": "邮箱格式不正确",
            "mobile": "手机号格式不正确",
            "checkedAgree": "请选择协议",
            "passwordNotSame": "两次输入的密码不一致"
          },
          "gettingValidateCode": "正在获取验证码",
          "validateCodeSendSuccess": "验证码已发送",
          "mobileCountryCode": "请选择区号",
          "5weqrdhch7o0": "注册",
          "5weqrdhci4w0": "密码至少8个字符，包含字符、数字、特殊符号",
          "5weqrdhci9k0": "确认密码",
          "5weqrdhcicw0": "福州剑奇云网络科技有限公司",
          "5weqrdhcijs0": "版权所有",
          "5weqrdhcimw0": "闽ICP备2020020145号-5",
          "index": {
            "61f8z24qhjg0": "图形验证码不正确"
          }
        },
        "copy": {
          "success": "复制成功"
        },
        "page": {
          "register": "注册"
        },
        "greatboss": {
          "register": {
            "success": {
              "tips": "请按以下步骤登录并使用GreatBoss"
            }
          }
        }
      }
    },
    "distribution": {
      "bind": {
        "protocol": {
          "index": {
            "60j4x24i71g8": "分销邀请",
            "60j4x24i71g0": "《EasyBoss·分销合作须知》",
            "60j4x24i71g2": "尊敬的用户，欢迎您使用EasyBoss分销合作功能！在使用EasyBoss分销合作功能之前，您应当认真阅读并遵守《EasyBoss-分销合作须知》。",
            "60j4x24i71g3": "1.供应商、分销商可通过EasyBoss分销工具开展分销合作业务，双方均在自愿、自主基础上展开合作，对于合作中产生的铺货、订单、售后、资金等相关问题，请供分销双方友好解决，EasyBoss不提供任何担保且对双方的合作不负有任何义务。",
            "60j4x24i71g4": "2.在EasyBoss上传、编辑的商品信息（包括但不限于供货价、产品的描述和说明、相关图片、视频等）由供应商自行提供，供应商应保证商品信息及自身行为合法性且不侵犯任何第三方权益，如因供应商进行上述行为所产生的一切后果，由供应商自行承担，同时EasyBoss保留相关追诉权利。",
            "60j4x24i71g5": "3.若您使用“分销资金”功能，双方应线下支付钱款；EasyBoss仅提供记账功能，不对双方资金往来提供清算、分账及其他资金相关服务，亦不对因此产生的纠纷负责。",
            "60j4x24i71g6": "4.分销商将订单推送给供应商处理，供应商由于任何原因，导致订单延迟发货、取消，由此产生的影响，请供分销双方友好解决，EasyBoss不提供任何担保且对双方的合作不负有任何义务。"
          }
        },
        "bind": {
          "index": {
            "60j51capb2s0": "供应商{0}邀请您与他建立合作，请选择是否接受合作邀请",
            "5yg2sdxxi8t0": "供应商{0}邀请您与他建立合作，请先登录EasyBoss ERP",
            "60j51capb6s0": "接受邀请后，请耐心等待供应商审核，审核通过后可展示合作",
            "60j51capb9g0": "可填写申请信息，以便提供快速通过您的申请",
            "60j51capbcg0": "我已阅读并同意",
            "60j51capbfk0": "暂不接受",
            "60j51capbi40": "接受",
            "60j51capbks0": "点此登录",
            "60j51capbps0": "点击",
            "60j51capbps1": "加载中..."
          }
        },
        "hooks": {
          "useDistributionBind": {
            "5yg2wlqzuo00": "操作成功",
            "5yg2wlqzus40": "提示",
            "5yg2wlqzuus0": "我已知晓",
            "5yg2wlqzuxc0": "合作邀请链接已过期，请联系供应商索要新的邀请合作链接",
            "5yg2wlqzuxa0": "您已和供应商{0}建立合作!",
            "5yg2wlqzuxt0": "您已向供应商{0}发起合作申请！请等待供应商审核",
            "5yg2wlqzuxi0": "供应商{0}已与您终止合作，请您联系供应商",
            "5yg2wlqzuxp0": "供应商{0}状态异常，暂时无法合作",
            "5yg2wlqzuxh0": "供应商已关闭分销流程，无法合作",
            "5yg2wlqzuyr0": "请使用主账号登录建立合作关系",
            "5yg2wlqzuo01": "请阅读并同意分销合作须知"
          }
        },
        "DistributionCoopConfirmDialog": {
          "index": {
            "5zqoed6x0k40": "提示",
            "5zqoed6x2500": "我已阅读并同意{0}",
            "5zqoed6x2ds0": "请阅读并同意分销合作须知"
          }
        },
        "default": {
          "index": {
            "60j4x24i71g6-1": "操作成功，请前往PC端登录{0}查看",
            "60j4x24i71g6-2": "复制链接",
            "60j4x24i71g6-3": "复制成功"
          }
        }
      },
      "constant": {
        "constant": {
          "distribution": {
            "5yg7j4qkamo0": "合作中",
            "5yg7j4qkb0o0": "供应商已终止",
            "5yg7j4qkb3g0": "分销商已终止",
            "5yg7j4qkb6c0": "供应商已拒绝",
            "5yg7j4qkb800": "分销商已拒绝",
            "5yg7j4qkb9s0": "待供应商授权",
            "5yg7j4qkbco0": "待分销商接受",
            "5yg7j4qkbe80": "待授权",
            "5yg7j4qkbfs0": "已拒绝",
            "5yg7j4qkbhg0": "已终止",
            "5yg7j4qkbht0": "状态异常"
          }
        },
        "distribution": {
          "constant": {
            "61qjub0xbi00": "已分销",
            "61qjub0xbx80": "未分销",
            "61qjub0xc040": "中国台湾"
          }
        }
      },
      "site": {
        "Distribution": {
          "DistributeBtn": {
            "61qihbill1w0": "我要分销",
            "61qihbill1g0": "您尚未与供应商：{0}达成合作关系，请达成合作关系后，再操作！",
            "61qihbilleo0": "提示",
            "61qihbillh00": "达成合作关系，请达成合作关系后，再操作！",
            "61qihbillj00": "以后不再提示",
            "61qihbilllg0": "发起合作",
            "61qihbillng0": "关闭",
            "61qihbillpg0": "继续分销",
            "61qihbillr00": "取消",
            "61qihbill1g0-1": "确定要将此商品添加至分销商品列表？",
            "61qihbill1g0-2": "添加后，您可前往{0}电脑端 > 我已分销的商品列表查看"
          },
          "InviteApplyConfirmPopup": {
            "61qilfdymfs0": "提示",
            "61qilfdymyo0": "您已向供应商：{0}发起合作申请！请等待供应商审核",
            "61qilfdyn340": "我已知晓"
          }
        },
        "SkuList": {
          "index": {
            "61qinmmejwo0": "规格列表"
          },
          "SkuItem": {
            "61qioccekzk0": "库存",
            "61qioccelhw0": "供货价"
          }
        },
        "components": {
          "AttributeInfo": {
            "61qiz6nds7c0": "商品属性",
            "61qiz6ndsls0": "类目",
            "61qiz6ndspg0": "分销地区",
            "61qiz6ndsss0": "包裹重量",
            "61qiz6ndsv80": "包裹尺寸",
            "61qiz6ndsxw0": "供应商展架",
            "61qiz6ndt0g0": "不限"
          },
          "BaseInfo": {
            "61qj1kkx0lw0-2": "近七天销量 {0}",
            "61qj1kkx12s0-2": "月销 {0}"
          },
          "RichNotes": {
            "61qj2yrwj600": "详细描述"
          },
          "ShortNotes": {
            "61qj3nr7t3k0": "简易描述"
          },
          "MainPlatformsList": {
            "61qj5zmp8r40": "更多平台"
          },
          "DateTimePick": {
            "61qjpwjkg8o0": "开始时间",
            "61qjpwjkgr80": "结束时间"
          }
        },
        "goodsDetail": {
          "index": {
            "61qj4ddmz640-1": "关闭",
            "61qj4ddmz640": "商品详情"
          }
        },
        "supplier": {
          "index": {
            "61qj6pgipk80": "主营类目",
            "61qj6pgipxg0": "主营平台",
            "61qj6pgipzs0": "联系方式",
            "61qj6pgiq1g0": "发货地",
            "61qj6pgiq340": "简介",
            "61qj6pgiq4w0": "服务能力：",
            "61qj6pgiq6k0-1": "日均发货数",
            "61qj6pgiq840": "分销订单48h发货率",
            "61qj6pgiq4j0": "商品能力：",
            "61qj6pgiq9o0-1": "分销商数",
            "61qj6pgiqbc0": "可分销商品",
            "61qj6pgiqec0": "近30天上新",
            "61qj6pgiqg40": "平均分销价"
          }
        },
        "Category": {
          "CategoryPopup": {
            "61qjaj2r6r40": "类目选择",
            "61qjaj2rals0": "确定选中"
          },
          "ChildrenTree": {
            "61qjbnuzf9k0": "全部",
            "61qjbnuzf9k4-0": "类目选项",
            "61qjbnuzf9k4-1": "一级选项",
            "61qjbnuzf9k4-2": "二级选项",
            "61qjbnuzf9k4-3": "三级选项",
            "61qjbnuzf9k4-4": "四级选项",
            "61qjbnuzf9k4-5": "五级选项",
            "61qjbnuzf9k4-6": "六级选项",
            "61qjbnuzf9k4-7": "七级选项",
            "61qjbnuzf9k4-8": "八级选项",
            "61qjbnuzf9k4-9": "九级选项"
          },
          "index": {
            "61qjd2qzs340": "更多筛选"
          }
        },
        "CustomCategory": {
          "CategoryRow": {
            "61qjdxb7n900-0": "展架分类",
            "61qjdxb7n900-1": "一级展架",
            "61qjdxb7n900-2": "二级展架",
            "61qjdxb7n900-3": "三级展架",
            "61qjdxb7n900-4": "四级展架",
            "61qjdxb7n900-5": "五级展架",
            "61qjdxb7n900-6": "六级展架",
            "61qjdxb7n900-7": "七级展架",
            "61qjdxb7n900-8": "八级展架",
            "61qjdxb7n900-9": "九级展架"
          }
        },
        "DistributionFilter": {
          "FilterDetailPopup": {
            "61qjg2lg1000": "类目",
            "61qjg2lg1io0": "请选择类目",
            "61qjg2lg1mo0": "展架",
            "61qjg2lg1pg0": "上新时间",
            "61qjg2lg1ro0": "供货价",
            "61qjg2lg1u80": "最低供货价",
            "61qjg2lg1yc0": "最高供货价",
            "61qjg2lg2100": "重置",
            "61qjg2lg23g0": "查询"
          },
          "index": {
            "61qjjlbaqc40": "筛选"
          }
        },
        "DistributionGoodsList": {
          "GoodItem": {
            "61qjl9nof8c0-1": "库存 {0}",
            "61qjl9nof8c6-1": "月销 {0}"
          }
        },
        "hooks": {
          "useDistributionSite": {
            "61qjqy9ef800": "全部",
            "61qjqy9efks0": "请选择要分销的商品",
            "61qjqy9efmw0": "操作成功"
          }
        },
        "site": {
          "index": {
            "61qjsg77ovk0": "商品名称",
            "61qjsg77p8o0": "发起合作申请",
            "61qjsg77pas0": "接受邀请"
          }
        },
        "siteRelay": {
          "index": {
            "62xo50y17h00": "提示",
            "62xo50y18400": "请确认您的邀请链接是否正确",
            "62xo50y18a80": "确认"
          }
        }
      }
    },
    "my": {
      "my": {
        "components": {
          "InputAutoFocusSettingSelect": {
            "5yjh4axepj00": "输入框自动聚焦"
          },
          "ScanShipmentSettingSelect": {
            "5wsklmc59bk0": "扫描发货"
          }
        }
      },
      "myIndex": {
        "5rnxe856up00": "个人中心",
        "5rnxe856vpg0": "货代版",
        "5rnxe856vw00": "免费版",
        "5rnxe856vzo0": "付费版",
        "5rnxe856w380": "仓库",
        "5rnxe856w780": "扫描方式",
        "5rnxe856wao0": "退出登录",
        "5rnxe856whs0": "确认要退出登录吗？",
        "5rnxe856wq40": "语种",
        "5rnxe856wv40": "切换语言需要重启应用",
        "5rnxe856wha0": "扫描发货设置",
        "5rnxe856whb0": "加载中",
        "5rnxe856whb1": "使用相机扫描时，请不要再按压激光扫描按钮，设备可能会卡住",
        "5rnxe856whb2": "输入框自动聚焦"
      }
    },
    "permission": {
      "404": {
        "60cyyf5ngl80": "暂无权限",
        "60cyyf5nhjo0": "暂无权限，请联系主账号添加"
      }
    },
    "stat": {
      "HotGoodsSection": {
        "constant": {
          "5zmqkoscpn00": "按SKU(单品)",
          "5zmqkoscqlo0": "按SPU(产品)"
        },
        "index": {
          "5zmr5k20gnk0": "热销产品TOP10",
          "5zmr5k20i840": "规格",
          "5zmr5k20ifs0": "平台SKU",
          "5zmr5k20ikg0": "产品ID",
          "5zmr5k20iok0": "主货号",
          "5zmr5k20it00": "店铺",
          "5zmr5k20ixc0": "销售额",
          "5zmr5k20j1g0": "销量"
        }
      },
      "HotPlatformSection": {
        "index": {
          "5zmr5t9re380": "热销平台TOP5",
          "5zmr5t9rf3o0": "排名",
          "5zmr5t9rf7k0": "平台",
          "5zmr5t9rf9s0": "销售额"
        }
      },
      "HotShopSection": {
        "index": {
          "5zmr60rhpes0": "热销店铺TOP5",
          "5zmr60rhpyk0": "排名",
          "5zmr60rhq100": "店铺",
          "5zmr60rhq300": "平台",
          "5zmr60rhq4k0": "销售额"
        }
      },
      "StatShopSelect": {
        "index": {
          "5zmr8nz3oa40": "选择平台",
          "5zmr8nz3pac0": "选择站点",
          "5zmr8nz3pio0": "选择店铺"
        }
      },
      "TotalViewSection": {
        "constant": {
          "5zmqmu4tw1g0": "付款总额",
          "5zmqmu4twh40": "预计实收",
          "5zmqmu4twks0": "付款客户数",
          "5zmqmu4twns0": "较昨日",
          "5zmqmu4twq80": "付款订单量",
          "5zmqmu4twuk0": "退款客户数",
          "5zmqmu4twx00": "退款订单量",
          "5zmqmu4twzg0": "退款总额",
          "5zmqmu4tx3o0": "预估利润",
          "5zmqmu4tx6g0": "利润率"
        },
        "index": {
          "5zmr673frbk0": "当日数据，2小时更新一次"
        }
      },
      "common": {
        "60a99nlkohc0": "利润明细",
        "60a9dq4u54g0": "商品销售",
        "60a9d45degg0": "产品统计",
        "60a9eurfpww0": "包材统计",
        "60a9feawjog0": "店铺统计",
        "60a9ht0fpgc0": "日期筛选",
        "60a9lb8cd0w0": "货币单位",
        "60a9lb8cdko0": "按订单状态",
        "60a9rrnp5rs0": "数据统计",
        "5saylxfm3ps0": "最大区间为3个月，请重新选择查询时间"
      },
      "components": {
        "CoreIndicator": {
          "5zmr3p5s6280": "核心指标",
          "5zmr3p5s6s40": "销售额"
        },
        "CoreIndicatorChart": {
          "5zmr3x26fdo0": "核心指标趋势",
          "5zmr3x26g500": "产品统计",
          "5zmr3x26g8g0": "百分比(%)"
        },
        "DataStatisticsSection": {
          "5zmr2qhmwkw0": "数据统计"
        },
        "DisbursementChartCard": {
          "5zmr43sgo9o0": "支出分析",
          "5zmr43sgomg0": "{0}占比: {1}%",
          "5zmr43sgopc0": "总支出"
        },
        "GoodsItemSection": {
          "5zmr895tvq80": "商品SKU",
          "60kms1u2s700": "规格"
        },
        "GoodsSection": {
          "5zmr2yq2c0k0": "产品ID",
          "5zmr2yq2cek0": "主货号",
          "5zmr2yq2ch80": "平台",
          "5zmr2yq2cj00": "店铺",
          "5zmr2yq2ckk0": "付款订单量",
          "5zmr2yq2cm80": "销量",
          "5zmr2yq2cns0": "付款总额",
          "5zmr2yq2cpc0": "退款总额",
          "5zmr2yq2cr00": "规格",
          "5zmr2yq2css0": "平台SKU"
        },
        "OrderDistributionSection": {
          "5zmr7cert4c0": "付款订单分布"
        },
        "ProductDistributionSection": {
          "5zmr36t00b80": "有销量的产品分布"
        },
        "RankingTenList": {
          "5zmr4euc2940": "利润分析TOP10",
          "5zmr4euc2z40": "排名",
          "5zmr4euc3300": "利润",
          "5zmr4euc35s0": "销售额",
          "5zmr4euc3880": "销售利润率",
          "5zmr4euc3bs0": "总利润"
        },
        "StatDropdown": {
          "5zmr95mf3q00": "已选择",
          "5zwtjniwy940": "个"
        },
        "StatSearchBar": {
          "5zmr9lrwj5g0": "请输入",
          "5zmr9lrwknc0": "筛选"
        },
        "TableSection": {
          "5zmr7j0011s0": "日期",
          "5zmr7j001yw0": "客户数",
          "5zmr7j002340": "订单量",
          "5zmr7j002680": "订单总额",
          "5zmr7j0028s0": "退款金额"
        },
        "StatDetailPopup": {
          "5zmw7ihk8580": "重置",
          "5zmw7ihk9n80": "确定"
        },
        "QueryBar": {
          "5saylxfm3p80": "最大区间为3个月，请重新选择查询时间",
          "5saylxfm4400": "仅支持查询近3个月的数据，请重新选择查询时间"
        },
        "StatRadio": {
          "60aa4y1tqd40": "已选择",
          "60aa4y1tqq80": "个",
          "60aa4y1tqss0": "全部"
        },
        "StatEmpty": {
          "60krhr1rgwk0": "暂无数据"
        }
      },
      "constant": {
        "5zmp81p6xo00": "付款订单量",
        "5zmp81p6yuk0": "付款总额",
        "5zmp81p6yzs0": "退款订单量",
        "5zmp81p6z2w0": "退款总额",
        "5zmp81p6z5s0": "数据总览",
        "5zmp81p6z9k0": "利润概览",
        "5zmp81p6zcg0": "利润明细",
        "5zmp81p6zlk0": "产品统计",
        "5zmp81p6zug0": "销量统计",
        "5zmp81p70840": "订单统计",
        "5zmp81p70fk0": "店铺统计",
        "5zmp81p70j80": "商品销售",
        "5zmp81p70lg0": "出入统计",
        "5zmp81p70o00": "包材统计",
        "5zmp81p70q40": "按天",
        "5zmp81p70sc0": "按周",
        "5zmp81p70uk0": "按月",
        "5zmp81p70wg0": "昨天",
        "5zmp81p70y80": "近7天",
        "5zmp81p710g0": "近30天",
        "5zmp81p713o0": "自定义日期",
        "5zmp81p715c0": "退款金额",
        "5zmp81p719c0": "下单时间",
        "5zmp81p71b00": "结算时间",
        "5zmp81p71dk0": "付款时间",
        "5zmp81p71f80": "发货时间",
        "5zmp81p71jg0": "截至发货",
        "5zmp81p71ok0": "销售额",
        "5zmp81p71rg0": "预估回款金额",
        "5zmp81p71t80": "买家实付",
        "5zmp81p71v40": "商品成本",
        "5zmp81p71ws0": "其他成本",
        "5zmp81p71yg0": "包材费",
        "5zmp81p71zs0": "利润",
        "5zmp81p72180": "成本利润率",
        "5zmp81p72340": "销售利润率",
        "5zmp81p724w0": "按平台",
        "5zmp81p726c0": "按站点",
        "5zmp81p72800": "按店铺",
        "5zmp81p729c0": "店铺",
        "5zmp81p72aw0": "平台",
        "5zmp81p72f00": "站点",
        "6021uie62280": "销量"
      },
      "hooks": {
        "5zmp74as2c00": "加载中"
      },
      "incomeAndExpenditureStatistics": {
        "constant": {
          "5zmqp7c2t700": "期末库存",
          "5zmqp7c2u6c0": "期初库存",
          "5zmqp7c2udo0": "采购入库",
          "5zmqp7c2uhw0": "库存调整入库",
          "5zmqp7c2ulk0": "盘盈入库",
          "5zmqp7c2uq80": "调拨入库",
          "5zmqp7c2uu80": "退货入库",
          "5zmqp7c2uyg0": "手工退货入库",
          "5zmqp7c2v3o0": "其他入库",
          "5zmqp7c2v800": "销售出库",
          "5zmqp7c2vc40": "库存调整出库",
          "5zmqp7c2vgg0": "盘亏出库",
          "5zmqp7c2vkw0": "调拨出库",
          "5zmqp7c2vp40": "手工销售出库",
          "5zmqp7c2vtk0": "其他出库",
          "5zmqp7c2vzw0": "商品SKU",
          "5zmqp7c2w480": "商品名称"
        },
        "hooks": {
          "5zmr7yzqthc0": "日期筛选",
          "5zmr7yzqtsw0": "选择仓库"
        },
        "index": {
          "5zmr7qd04is0": "出入统计"
        }
      },
      "index": {
        "5zmp4zbj9s00": "实时数据",
        "5zmp4zbjazc0": "当日数据，2小时更新一次",
        "5zmp4zbjb3k0": "常用功能",
        "5zmvhhwoxlw0": "货币单位"
      },
      "orderDetail": {
        "constant": {
          "60a9oxflttc0": "订单收入",
          "60a9oxflu740": "订单原始金额",
          "60a9oxflu9o0": "产品价格",
          "60a9oxfluc40": "运费总额",
          "60a9oxfluds0": "买家支付的运费",
          "60a9oxflufk0": "卖家支付的运费",
          "60a9oxfluhc0": "回扣",
          "60a9oxfluiw0": "费用与收费",
          "60a9oxfluko0": "佣金",
          "60a9oxflum40": "服务费",
          "60a9oxflunw0": "交易手续费",
          "60a9oxflupk0": "买家实付金额",
          "60a9oxflur40": "运费",
          "60a9oxflut80": "包材费",
          "60a9oxfluus0": "其他成本",
          "60a9oxfluwg0": "商品成本",
          "60a9oxfluyc0": "预估利润",
          "60a9pmw41sg0": "Shopee运费补贴",
          "60a9qjl9dao0": "卖家 Voucher",
          "60a9qtr2gzk0": "Shopee币抵扣"
        },
        "index": {
          "60a9ncf9km00": "订单明细",
          "60a9ncf9kys0": "订单号：",
          "60a9ncf9l180": "当前金额单位：",
          "60a9ncf9l2w0": "站点：",
          "60a9ncf9l4o0": "汇率："
        }
      },
      "orderStatistics": {
        "constant": {
          "5zmqukt4k5w0": "按店铺",
          "5zmqukt4loo0": "价格区间",
          "5zmqukt4lu40": "日期",
          "5zmqukt4ly80": "客户数（个）",
          "5zmqukt4m1s0": "订单量（笔）",
          "5zmqukt4m600": "订单总额",
          "5zmqukt4m980": "付款总额",
          "5zmqukt4mcw0": "付款订单量（笔）",
          "5zmqukt4mfo0": "付款客户数（个）",
          "5zmqukt4mi40": "退款总额",
          "5zmqukt4mlw0": "退款订单量（笔）",
          "5zmqukt4mqs0": "退款客户数（个）",
          "5zmqukt4muc0": "客单价",
          "5zmqukt4nj40": "有效订单量",
          "5zmqukt4o100": "有效销售额",
          "5zmqukt4o134": "{0}个SKU品类",
          "5zmqukt4o155": "{0}个SKU品类及以上",
          "5zmqukt4o177": "SKU品类数"
        },
        "hooks": {
          "5zmr6ze850o0": "日期筛选"
        },
        "index": {
          "5zmr6pphrw00": "订单统计",
          "5zmr6pphsek0": "筛选",
          "5zmr6pphsi40": "数据指标趋势图"
        }
      },
      "overview": {
        "constant": {
          "5zmqj6xm7gs0": "实时数据",
          "5zmqj6xm8840": "热销平台",
          "5zmqj6xm8bs0": "热销店铺",
          "5zmqj6xm8ec0": "热销产品"
        },
        "index": {
          "5zmr4qhws3s0": "数据总览"
        }
      },
      "packagingMaterialsStatistics": {
        "components": {
          "60aa48gpg940": "数据统计"
        },
        "constant": {
          "60aa3i4w8840": "包材总数",
          "60aa3i4w8mk0": "订单使用量",
          "60aa3i4w91s0": "总金额",
          "60aa3i4w9940": "编码",
          "60aa3i4w9b00": "名称",
          "60aa3i4w9cs0": "排名",
          "60aa3i4w9e80": "平均单价",
          "60aa3i4w9g00": "小计"
        }
      },
      "productSales": {
        "components": {
          "60aa1gn2j3o0": "规格",
          "60aa1gn2jms0": "商品SKU",
          "60aa24ijr500": "数据统计"
        },
        "constant": {
          "60aa0cmsgck0": "商品种数",
          "60aa0cmsgwk0": "商品销量",
          "60aa0cmsh000": "商品成本",
          "60aa0cmsh280": "退货数量",
          "60aa0cmsh4g0": "退款金额",
          "60aa0cmsh780": "销售金额",
          "60aa0cmsh9s0": "退款成本",
          "60aa0cmshbw0": "销售订单量",
          "60aa0vmcdtw0": "商品SKU",
          "60aa0vmcec00": "商品名称"
        }
      },
      "productStatistics": {
        "components": {
          "60a9yyz55n00": "数据指标趋势图",
          "60a9yyz565o0": "百分比(%)"
        },
        "constants": {
          "60a9xumyah40": "日期",
          "60a9xumyav40": "产品总数",
          "60a9xumyay40": "在售产品数",
          "60a9xumyb000": "在售产品占比",
          "60a9xumyb1s0": "有销量产品数",
          "60a9xumyb5k0": "动销率",
          "60a9xumyb740": "新增在售产品数",
          "60a9xumyb8s0": "新增在售产品占比"
        }
      },
      "profitDetail": {
        "components": {
          "60a9u37djso0": "订单明细",
          "60a9u37dkc40": "店铺明细",
          "60a9vuvtmfs0": "店铺：",
          "60a9vuvtms80": "站点：",
          "60a9wn2oggk0": "产品ID",
          "60a9wn2ogv80": "平台SKU",
          "60a9wn2ogz40": "商品SKU",
          "60a9wn2oh1s0": "商品成本",
          "60a9wn2oh4s0": "平台回款金额：",
          "60a9wn2oh7g0": "利润：",
          "60a9wn2oha00": "销售利润率："
        },
        "constant": {
          "60a9mn7mnho0": "按订单明细",
          "60a9mn7mo0g0": "按店铺",
          "60a9mn7mo4c0": "订单号",
          "60a9mn7mo780": "包裹号",
          "60a9mn7mo9g0": "运单号",
          "60a9mn7mod80": "产品标题",
          "60a9mn7mofk0": "产品ID",
          "60a9mn7mohs0": "平台SKU",
          "60a9mn7mok40": "商品SKU",
          "60a9mn7momg0": "下单时间",
          "60a9mn7mook0": "结算时间",
          "60a9mn7mor40": "付款时间",
          "60a9mn7mouc0": "发货时间",
          "60a9mn7moww0": "截止发货时间",
          "60a9skxqv140": "订单数",
          "60a9skxqvhc0": "销售额",
          "60a9skxqvko0": "买家实付",
          "60a9skxqvn80": "预估回款金额",
          "60a9skxqvpo0": "已结算金额",
          "60a9skxqvs00": "待结算金额",
          "60a9skxqvug0": "成本",
          "60a9skxqvyo0": "商品成本",
          "60a9skxqw0o0": "广告成本",
          "60a9skxqw380": "运营成本",
          "60a9skxqw5g0": "包材成本",
          "60a9skxqw7w0": "其他成本",
          "60a9skxqwbg0": "利润",
          "60a9skxqwdw0": "成本利润率",
          "60a9skxqwgg0": "销售利润率",
          "60a9skxqwiw0": "今日数据",
          "60a9skxqwl40": "昨日数据",
          "60a9skxqwnw0": "汇总数据",
          "60a9v7pp1n00": "预估回款金额",
          "60a9v7pp2qs0": "订单原始金额",
          "60a9v7pp30g0": "买家实付",
          "60a9v7pp33g0": "包材费",
          "60a9v7pp3680": "其它成本",
          "60a9v7pp38w0": "利润",
          "60a9v7pp3bo0": "成本利润率",
          "60a9v7pp3fo0": "销售利润率",
          "60y9bytj9u80": "三方仓操作费",
          "60y9bytjb480": "三方仓运费"
        }
      },
      "profitsOverview": {
        "constant": {
          "5zmqie08y6k0": "核心指标",
          "5zmqie08yp40": "核心指标趋势",
          "5zmqie08ytg0": "支出分析",
          "5zmqie08ywk0": "利润分析",
          "5zmqie08yzo0": "平台回款金额",
          "5zmqie08z2o0": "环比",
          "5zmqie08z5c0": "包材费",
          "5zmqie08z7w0": "买家实付",
          "5zmqie08zak0": "利润",
          "5zmqie08zd40": "商品成本",
          "5zmqie08zfo0": "成本利润率",
          "5zmqie08zig0": "其他成本",
          "5zmqie08zks0": "销售利润率"
        },
        "index": {
          "5zmr3g7jskw0": "利润概览"
        }
      },
      "saleDetail": {
        "index": {
          "5zmr52c50ng0": "数据总览",
          "5zmr52c517g0": "产品销量统计详情",
          "5zmr52c51bk0": "数据指标趋势图",
          "5zmvji9oxrk0": "产品ID",
          "5zmvji9oz0w0": "平台SKU",
          "5zmvji9oz700": "平台",
          "5zmvji9ozao0": "店铺",
          "5zmvjnye99g0": "产品维度",
          "5zmvlw6cgzg0": "规格",
          "603i2ug6f3k0": "开始时间",
          "603i2ug6gbs0": "结束时间",
          "603i2ug6gjc0": "仅支持查询近6个月的数据，请重新选择查询时间"
        }
      },
      "salesStatistics": {
        "constant": {
          "5zmqf0amgts0": "按SPU(产品)",
          "5zmqf0ami0c0": "按SKU(单品)",
          "5zmqf0ami6o0": "按店铺",
          "5zmqf0amib40": "价格区间",
          "5zmqf0amifc0": "销售区间",
          "5zmqf0amilc0": "产品总数",
          "5zmqf0amiqg0": "付款订单量",
          "5zmqf0amiuo0": "销量",
          "5zmqf0amj000": "付款总额",
          "5zmqf0amj440": "退款订单量",
          "5zmqf0amj8c0": "退款金额",
          "5zmqf0amjcw0": "产品标题",
          "5zmqf0amjhc0": "产品ID",
          "5zmqf0amjkw0": "平台SKU"
        },
        "hooks": {
          "5zmr1xvtlyw0": "日期筛选"
        },
        "index": {
          "5zmr18mjbic0": "销量统计"
        }
      },
      "shopStatistics": {
        "components": {
          "60a9ixf7pqs0": "店铺明细",
          "60a9ixf7q7w0": "店铺：",
          "60a9ixf7qcg0": "客户数(个)：",
          "60a9ixf7qfc0": "订单量(笔)：",
          "60aacanra200": "数据统计"
        },
        "constant": {
          "60a9gbqw2w80": "客户数(个)",
          "60a9gbqw3os0": "订单量(笔)",
          "60a9gbqw3sk0": "订单总额",
          "60a9gbqw3v80": "付款总额",
          "60a9gbqw3xg0": "退款金额",
          "60a9gbqw3zw0": "客单价",
          "60a9gbqw42c0": "付款订单量",
          "60a9gbqw4d40": "付款客户数(个)",
          "60a9gbqw4i40": "退款订单量",
          "60a9gbqw4po0": "退款客户数(个)"
        }
      }
    },
    "warehouse": {
      "baseIndex": {
        "5wfvehriixa0": "仓库",
        "5wfvehrixdc0": "今日已入库包裹",
        "5wfvehrixtk0": "今日已上架SKU",
        "5wfvehrj1ts0": "请选择仓库",
        "5wfvehrj1zs0": "签收入库",
        "5wfvehrj24w0": "手工入库",
        "5wfvehrj29k0": "手工出库",
        "5wfvehrj2ig0": "单品拣货",
        "5wfvehrj2ng0": "商品查询",
        "5wfvehrj2na0": "扫描发货",
        "61s2n6t0kpc0": "扫描验货",
        "619ereolw8g0": "退货入库",
        "62xp0659vcg0": "扫描查单"
      },
      "components": {
        "packageItem": {
          "5rnxjrj9pt00": "请输入/扫描物流单号、采购单号",
          "5rnxl2ugdn80": "待入库种类",
          "5rnxl2uge480": "待入库数",
          "5rnxl2ugey80": "备注"
        }
      },
      "goodsSearch": {
        "baseIndex": {
          "5weqyxxhmtg0": "商品查询",
          "5weqyxxhnfg0": "扫描商品SKU/商品代码",
          "5weqyxxhnjw0": "请扫描商品信息",
          "5weqyxxhnms0": "请输入商品SKU/商品代码",
          "5weqyxxhnpk0": "加载中",
          "5weqyxxhnsk0": "未查到此商品"
        },
        "components": {
          "GoodsCard": {
            "GoodsInfo": {
              "5wer21zf7rk0": "商品代码",
              "5wer21zf8jg0": "近7/30/60天销量"
            },
            "index": {
              "5wer2vtlaf40": "子商品"
            },
            "StorageInfo": {
              "5wer3yv4lx40": "仓位",
              "5wer3yv4mgo0": "库存总量",
              "5wer3yv4o8g0": "可用",
              "5wer3yv4ofw0": "预占"
            }
          }
        }
      },
      "processStorage": {
        "baseIndex": {
          "5rny2tblyc00": "扫描商品",
          "5rny2tblz7g0": "请输入/扫描商品SKU、商品代码",
          "5rny2tblzds0": "结束本次入库",
          "5rny2tblzkw0": "出库",
          "5rny2tblzps0": "入库",
          "5rny2tblztk0": "未找到商品",
          "5rny2tblzx00": "组合品不可入库",
          "5rny2tbm00g0": "加载中",
          "5rny3yibbno0": "成功",
          "5rny51km7e40": "出库数量不能超过",
          "5rolykv54d00": "请输入商品SKU"
        },
        "components": {
          "GoodsPopup": {
            "5rnxodldrdc0": "种",
            "5rnxodlds640": "件",
            "5rnxodldsdc0": "提交"
          },
          "SetQuantityDialog": {
            "5rnxpb2qwsg0": "请输入数量",
            "5rnxu9u7r2c0": "本次",
            "5ron09jvyh80": "数",
            "5rnxpb2qxg00": "数量不能为空",
            "5rnxu9u7q4w0": "数量不能超过"
          }
        },
        "processGoodsWithStoragePage": {
          "baseIndex": {
            "5rnxzgpf7i00": "商品",
            "5rnxzgpf8co0": "名称：",
            "5rnxzgpf8io0": "规格：",
            "5rnxzgpf8mg0": "待入库数：",
            "5rnxzgpf8q40": "本次",
            "5rnxzgpf8tk0": "无仓位",
            "5rnxzgpf8ww0": "未选择仓位",
            "5rnxzgpf90g0": "删除",
            "5rnxzgpf93s0": "换仓位",
            "5rnxzgpf97c0": "出库",
            "5rnxzgpf9ao0": "入库",
            "5rnxzgpf9e80": "仓位不能为空，请检查仓位",
            "5rnxzgpf9hs0": "正在操作",
            "5rnxzgpf9l80": "数据异常，请联系客服",
            "5rny0rcmwbk0": "正在",
            "5werec5q0lc0": "确定"
          }
        },
        "scanStorageCellPage": {
          "baseIndex": {
            "5rny1og9cek0": "扫描仓位",
            "5rny1og9d740": "请扫描/输入仓位号",
            "5rny1og9ddc0": "请扫描仓位",
            "5rny1og9dgw0": "正在查询",
            "5roninktevk0": "该商品在此仓位无库存"
          }
        }
      },
      "putaway": {
        "baseIndex": {
          "5rnyj390bf00": "扫描包裹",
          "5rnyj390c1o0": "请输入/扫描物流单号、采购单号",
          "5rnxm6vsad80": "请扫描包裹",
          "5rnyj390c7g0": "待签收采购单",
          "5rnyj390cb40": "加载中",
          "5rnyj390ce80": "暂无数据",
          "5rnyj390cho0": "采购单签收成功"
        },
        "components": {
          "WaitReceivePurchaseOrderList": {
            "5rnyh09ydbg0": "暂无数据"
          }
        },
        "purchaseOrderInfoPage": {
          "baseIndex": {
            "5rnyibnc0h40": "扫描包裹",
            "5rnyibnc14g0": "名称：",
            "5rnyibnc1a00": "规格：",
            "5rnyibnc1dw0": "代码：",
            "5rnyibnc1hc0": "已经到底啦",
            "5rnyibnc1l00": "直接入库",
            "5rnyibnc1ow0": "扫描商品入库",
            "5rnyibnc1sg0": "直接入库后，库存将增加，确定入库？",
            "5rnyibnc1w80": "正在入库中..."
          }
        }
      },
      "scanInspection": {
        "components": {
          "GoBackConfirmPopup": {
            "61x928ywr8g0": "返回将会清空数据，确定返回？"
          },
          "LogisticsGroupPopup": {
            "61s2ulkrbng0": "请选择物流"
          },
          "PackageInfoContainer": {
            "61s2u6o99vg0": "买家留言",
            "61s2u6o9ad40": "商家备注",
            "61s2u6o9ag40": "本地备注",
            "61s2u6o9ahw0": "商品SKU",
            "61s2u6o9ak00": "商品数量",
            "61s2u6o9als0": "已校验"
          },
          "ResetConfirmPopup": {
            "61s2tn0lhys0": "保存后，会清空当前已扫描包裹信息，确定继续？",
            "61s2tn0lifc0": "暂不保存",
            "61s2tn0liig0": "继续保存"
          },
          "ScanSettingPopup": {
            "61s2t7h33f00": "扫描设置",
            "61s2t7h348w0": "验货成功后，自动发货",
            "61s2t7h34e40": "保存"
          }
        },
        "constant": {
          "61s2rr94g7o0": "扫描或输入包裹号/运单号",
          "61s2rr94jzk0": "扫描商品SKU/GTIN"
        },
        "hooks": {
          "useLogisticsGroupList": {
            "61s2s5zka4k0": "全部物流"
          },
          "useScanInspection": {
            "61s2sk0fjss0": "当前扫描包裹已完成校验",
            "61s2sk0fnpk0": "加载中",
            "61s2sk0fnwg0": "包裹内无该商品",
            "61s2sk0fo0w0": "该商品已完成校验",
            "61s2sk0fo4o0": "发货中",
            "61s2sk0fo840": "部分商品正在盘点，盘点结束后，将自动扣减预占库存",
            "61s2sk0fob00": "发货失败",
            "61s2sk0fod40": "发货成功",
            "61s2sk0fofs0": "保存中",
            "61wcj39boc80": "未搜到该包裹或订单包裹已发货",
            "62hjyf276wk0": "扫描验货成功"
          }
        },
        "index": {
          "61s2pxoyy5s0": "扫描验货",
          "61s2pxoz2380": "设置",
          "61s2pxoz29w0": "记录",
          "61s2pxoz2ds0": "请扫描包裹信息"
        },
        "verifiedListPage": {
          "index": {
            "61s304k9b880": "已校验记录",
            "61s304k9cfo0": "订单号/包裹号/物流方式/运单号",
            "61s304k9cm40": "包裹号",
            "61s304k9cos0": "订单号",
            "61s304k9d7g0": "物流信息",
            "61s304k9dck0": "发货状态",
            "61s304k9dkw0": "全部",
            "61s304k9dno0": "发货成功",
            "61s304k9dqc0": "发货失败"
          }
        }
      },
      "scanReturn": {
        "components": {
          "WaitReturnGoodsList": {
            "619evhn1suw0": "待入库种类",
            "619evhn1txg0": "待入库数",
            "619evhn1u580": "暂无数据"
          }
        },
        "hooks": {
          "useReturnGoods": {
            "619eub7jj1s0": "正在入库中...",
            "61wzjfwnawk0": "入库数量不能超过{0}个"
          },
          "useScan": {
            "619euwhlq5o0": "加载中",
            "619euwhlqyg0": "暂无数据"
          }
        },
        "returnOrderInfoPage": {
          "index": {
            "619etj4urs80": "退货入库",
            "619etj4usrk0": "名称：",
            "619etj4usxs0": "规格：",
            "619etj4ut1s0": "已经到底啦",
            "619etj4ut540": "直接入库",
            "619etj4ut980": "扫描商品入库",
            "619etj4uteg0": "直接入库后，库存将增加，确定入库？"
          }
        },
        "constant": {
          "619espdpd100": "扫描包裹",
          "619espdpe9k0": "待入库退货单"
        },
        "index": {
          "619es2vho3o0": "退货入库",
          "619es2vhoi80": "请输入/扫描运单号、订单号、包裹号",
          "619es2vhols0": "请扫描包裹",
          "619es2vhooo0": "退货单入库成功"
        }
      },
      "scanShipment": {
        "components": {
          "List": {
            "5wsld4f61gw0": "暂无数据"
          },
          "OrderPackageItemMedia": {
            "5wsld4f61gw0": "1、上传的图片/视频可在图片空间中查看",
            "5wsld4f61gw1": "2、视频不可超过30M，时长不可超过1分钟",
            "5wsld4f61gw2": "上传图片",
            "5wsld4f61gw3": "上传视频",
            "5wsld4f61gw4": "选择视频",
            "5wsld4f61gw5": "图片",
            "5wsld4f61gw6": "视频",
            "5wsld4f61gw7": "时长不可超过1分钟"
          },
          "PackageItemGoods": {
            "5wsldq8io7w0": "规格"
          },
          "ShipmentButton": {
            "5wsle2o8bvc0": "发货"
          }
        },
        "hooks": {
          "useList": {
            "5wslbuh0fts0": "该包裹本次已扫描",
            "5wslbuh0j1c0": "加载中",
            "5wslbuh0j8g0": "暂无数据"
          },
          "useShipmentPackage": {
            "5wslcgxgpio0": "操作中",
            "5wslcgxgu5g0": "发货失败",
            "5wslcgxgug00": "发货成功"
          }
        },
        "index": {
          "5wsl89cm3gw0": "扫描发货",
          "5wsl89cm7z80": "扫描或输入包裹号/运单号",
          "5wsl89cm8980": "已扫描",
          "5wsl89cm8ds0": "请扫描包裹信息",
          "5wsl89cm8hc0": "加载中",
          "5wsl89cm8hc1": "扫描后自动发货"
        },
        "scannedListPage": {
          "index": {
            "5wslasiw3rc0": "已扫描",
            "5wslasiw8r40": "确定要移除包裹？",
            "5wslasiw9200": "返回后，已扫描的包裹列表将清空，确定要继续？",
            "5wslasiw9940": "全部",
            "5wslasiw9f40": "暂无可发货包裹"
          }
        }
      },
      "singlePick": {
        "baseIndex": {
          "5wesij4wgig0": "单品拣货",
          "5wesij4whps0": "扫描/输入波次号",
          "5wesij4whv40": "确定要标记为完成状态？",
          "5wesij4wi0s0": "且波次将进入为待包装状态",
          "5wesij4whyk0": "为了避免重复拣货，建议和账号所属用户确认或查看操作日志后，再开始拣货",
          "5wesij4wi1s0": "标记后,波次{0}将无法拣货",
          "5wesij4wi5s0": "账号：{0}于{1}操作拣货"
        },
        "components": {
          "WaveList": {
            "WaveItem": {
              "5wesqu5fcgs0": "已拣/待拣",
              "5wesqu5fi8s0": "商品信息",
              "5wesqu5fihk0": "创建时间",
              "5wesqu5fimg0": "创建人",
              "5wesqu5fiv00": "更多",
              "5wesqu5fizs0": "开始拣货",
              "5wesqu5fj3w0": "标记完成",
              "5wesqu5fj800": "拣货日志",
              "5wesqu5fjck0": "{0}种SKU，共{1}个"
            }
          }
        },
        "hooks": {
          "useOperate": {
            "5wespf869ys0": "加载中",
            "5wespf86efs0": "继续拣货",
            "5wespf86et80": "拣货日志",
            "5wespf86eys0": "波次状态已更新，请下拉刷新页面查看"
          }
        },
        "subPages": {
          "pickLog": {
            "baseIndex": {
              "5weyeh0dlbs0": "拣货日志"
            },
            "components": {
              "LogItem": {
                "5weygrs8su40": "商品SKU",
                "5weygrs8xvc0": "拣货数量"
              }
            }
          },
          "picking": {
            "baseIndex": {
              "5wey0wt55a40": "扫描/输入SKU、商品代码",
              "5wey0wt64ns0": "种SKU,",
              "5wey0wt650g0": "共",
              "5wey0wt65b00": "个",
              "5wey1e5fupo0": "剩余拣货："
            },
            "components": {
              "SetPickQuantityDialog": {
                "5wey82q92us0": "填写已拣数量",
                "5wey82q99rg0": "请输入",
                "5wey82q9a100": "请输入已拣数量",
                "5wey82q9a1g0": "已拣数量不能大于待拣数量:{0}"
              },
              "SkuItem": {
                "5wey9z9uhxs0": "商品代码",
                "5wey9z9uoe00": "仓位",
                "5wey9z9uook0": "已拣",
                "5wey9z9uoyw0": "待拣"
              },
              "WavePickFinishDialog": {
                "5weycylwol80": "提示",
                "5weycylwsss0": "我已知晓",
                "5weycylwt1c0": "当前波次拣货已完成！"
              }
            },
            "hooks": {
              "usePicking": {
                "5wey5ujb4xs0": "当前波次不存在此商品",
                "5wey5ujbaeo0": "商品SKU:{0}已完成拣货"
              }
            }
          }
        }
      }
    }
  },
  "system": {
    "confirm": "确定",
    "cancel": "取消",
    "pleaseSelect": "请选择",
    "5rnxe856we80": "提示",
    "5rnxe856wl00": "确认",
    "loading": "正在加载",
    "copySuccess": "复制成功",
    "emptyShelfCell": "无仓位",
    "versionInfo": "版本信息",
    "language": {
      "toggle": "切换语言",
      "selectEmpty": "请选择语言"
    },
    "tabBar": {
      "stat": "数据",
      "home": "仓库",
      "my": "我的"
    },
    "update": {
      "title": "更新提示",
      "forceUpdate": "发现新版本，立即重启",
      "fail": "更新失败",
      "newVersion": "发现新版本",
      "updateNow": "立即更新",
      "updateLater": "下次更新"
    }
  }
}