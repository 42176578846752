// 第一版 1.10.1 版本发布于2021-11-18
let version = '1.10.1';

export default {
	v: version,
	version: version,
	// 主题名称
	type: [
		'primary',
		'success',
		'info',
		'error',
		'warning'
	]
}