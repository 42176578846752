import { isNumber } from 'lodash-es';
import { LANGUAGE_MODE } from '@/constants/localStorageKey';
import { useExchangeNumber } from '@/hooks';
import { resolveUnref } from '@vueuse/shared';

const defaultLocale = uni.getStorageSync(LANGUAGE_MODE) || uni.getLocale();

let numberFormatterByLocale = {};
// 简单替代Intl.NumberFormat
class NumberFormat {
  constructor(locale, options = {}) {
    this.locale = locale;
    this.fixed = options.maximumFractionDigits;
  }

  toFixed(number) {
    let numberStr = number.toString();
    if (isNumber(this.fixed)) {
      const str = number.toFixed(this.fixed);
      const parts = str.split('.');
      if (parts.length === 1) {
        numberStr = parts[0];
      } else {
        let decimal = parts[1];
        while (decimal.endsWith('0')) {
          decimal = decimal.slice(0, -1);
        }
        numberStr = parts[0] + (decimal.length > 0 ? '.' + decimal : '');
      }
    }
    return numberStr;
  }

  format(number) {
    if (!isNumber(number)) return number;

    const fixedNumber = this.toFixed(number);

    if (['zh-Hans', 'zh-Hant', 'en', 'th', 'ms'].includes(this.locale)) {
      return this.format1(fixedNumber);
    }

    if (['vi', 'id'].includes(this.locale)) {
      return this.format2(fixedNumber);
    }

    return fixedNumber;
  }

  format1(numberStr) {
    const parts = numberStr.split('.');
    const integerPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    const decimalPart = parts[1] ? '.' + parts[1] : '';
    return integerPart + decimalPart;
  }

  format2(numberStr) {
    const parts = numberStr.split('.');
    const integerPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    const decimalPart = parts[1] ? ',' + parts[1] : '';
    return integerPart + decimalPart;
  }
}

if (globalThis.Intl && globalThis.Intl.NumberFormat) {
  const numberFormat = new Intl.NumberFormat(defaultLocale, {
    style: 'decimal',
    maximumFractionDigits: 2,
  });
  numberFormatterByLocale.format = (num) => {
    if (!isNumber(num)) return num;
    return numberFormat.format(num);
  };
} else {
  numberFormatterByLocale = new NumberFormat(defaultLocale, {
    maximumFractionDigits: 2,
  });
}

/** 根据语言环境对数值格式化为千分位 */
export function thousandsByLocale(num) {
  return numberFormatterByLocale.format(num);
}

export const getFormattedNumberByCurrency = (value, currency) => {
  const { getDisplayNumber } = useExchangeNumber({ numberLimit: 100000 });
  return resolveUnref(currency) === 'CNY'
    ? getDisplayNumber(value, true, currency)
    : thousandsByLocale(value);
};
