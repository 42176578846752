import { defineConstants } from '../utils/constant';

export const DEFAULT_LANGUAGE = 'enUs';

export const { LANGUAGE, LANGUAGE_LIST, LANGUAGE_MAP } = defineConstants(
  [
    {
      label: '简体中文',
      value: 'zh-Hans',
      browerLanguage: ['zh', 'zh-CN', 'zh-SG', 'zh-Hans'],
      constantKey: 'zhCn',
      requestCode: 'zh-cn',
    },
    {
      label: '繁體中文',
      value: 'zh-Hant',
      browerLanguage: ['zh-TW', 'zh-HK', 'zh-MO', 'zh-Hant'],
      constantKey: 'zhTw',
      requestCode: 'zh-tw',
    },
    {
      label: 'English',
      value: 'en',
      browerLanguage: [
        'en',
        'en-AU',
        'en-BZ',
        'en-CA',
        'en-CB',
        'en-IE',
        'en-JM',
        'en-NZ',
        'en-PH',
        'en-ZA',
        'en-TT',
        'en-GB',
        'en-US',
        'en-ZW',
      ],
      constantKey: 'enUs',
      requestCode: 'en-us',
    },
    {
      label: 'Tiếng Việt',
      value: 'vi',
      browerLanguage: ['vi', 'vi-VN'],
      constantKey: 'vi',
      requestCode: 'vi',
    },
    {
      label: 'Indonesia',
      value: 'id',
      browerLanguage: ['id', 'id-ID'],
      constantKey: 'id',
      requestCode: 'id',
    },
    // {
    //   label: 'Melayu',
    //   value: 'ms',
    //   browerLanguage: ['ms', 'ms-MY', 'ms-BN'],
    //   constantKey: 'ms',
    //   requestCode: 'my',
    // },
    {
      label: 'ไทย',
      value: 'th',
      browerLanguage: ['th', 'th-TH'],
      constantKey: 'th',
      requestCode: 'th',
    },
  ],
  'LANGUAGE',
  'constantKey'
);


export const GREATBOSSLANGUAGE = ['zhCn', 'enUs']

export const AliLanguageMap = {
  [LANGUAGE.ZH_CN]: 'cn',
  [LANGUAGE.ZH_TW]: 'tw',
  [LANGUAGE.EN_US]: 'en',
  [LANGUAGE.VI]: 'vi',
  [LANGUAGE.ID]: 'in',
  [LANGUAGE.TH]: 'th',
};

