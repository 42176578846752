import { defineStore } from 'pinia';
import {
  getAccountInfo,
  login,
  getAppAccountExtConfigOptions,
} from '@/services/auth/account';
import { STORE_KEY } from '@/constants/store';
import base64 from '@/utils/base64';
import uuid from 'uuidjs';
import { CURRENT_SELECTED_WAREHOUSE_ID } from '@/constants/localStorageKey.js';
import { markRaw } from 'vue';
import { isEmpty } from 'lodash-es';
import { getDeviceId } from '@/utils/device';

let reLoginPromise = null;

function createUser() {
  const user = uni.getStorageSync(STORE_KEY.USER_INFO);
  return user || {};
}
export default defineStore('user', {
  state: () => {
    return {
      user: createUser(),
      accountInfo: {},
      isForwarderFreeVersion: false,
      isFreeVersion: false,
      isMainAccount: false,
      permissionCodeList: [],
      permissionCodeMaps: {},
      accountExtConfigOptionMap: {
        defaultOptions: {},
        supportedCurrencyList: [],
        supportedCountryAndTimezoneList: [],
        supportedCountryMap: {},
        supportedTimezoneMap: {},
      },
    };
  },
  actions: {
    updateUser(user) {
      this.user = user;
      uni.setStorageSync(STORE_KEY.USER_INFO, user);
    },
    async getUserInfo(custom) {
      return getAccountInfo(custom).then((res) => {
        const { result, ...accountInfo } = res;
        this.setAccountInfo(accountInfo);
      });
    },
    setUserAccount(option) {
      const { mobile = '', password = '' } = option;
      uni.setStorageSync(STORE_KEY.USER_ACCOUNT, mobile);
      uni.setStorageSync(
        STORE_KEY.USER_PASSWORD,
        password ? base64.encode(password) : password
      );
    },
    // 重新登录
    async reLogin() {
      if (reLoginPromise) return reLoginPromise;

      const handle = async () => {
        if (!this.user?.token) return false;

        const mobile = uni.getStorageSync(STORE_KEY.USER_ACCOUNT);
        const password = uni.getStorageSync(STORE_KEY.USER_PASSWORD);
        const custom = {
          isToast: false,
          isCallLogout: false,
        };

        // 判断是否在线
        try {
          await this.getUserInfo(custom);
          await this.getAppAccountExtConfigOptions();
          return true;
        } catch {}

        if (!mobile || !password) return false;

        try {
          const data = await login(
            {
              mobile,
              password: base64.decode(password),
              clientId: uuid.generate(),
              bsClientId: getDeviceId(),
            },
            custom
          );
          updateUser(data);
          await this.getUserInfo();
          await this.getAppAccountExtConfigOptions();
          uni.$emit('relogin');
          return true;
        } catch {
          return false;
        }
      };
      reLoginPromise = handle();
      const result = await reLoginPromise;
      reLoginPromise = null;
      return result;
    },
    async logout() {
      this.clear();
      // loginOut().catch(() => {});
    },
    clear() {
      uni.setStorageSync(STORE_KEY.USER_INFO, '');
      uni.setStorageSync(CURRENT_SELECTED_WAREHOUSE_ID, '');
      this.$reset();
    },
    setAccountInfo(accountInfo) {
      const { registerSource, isFreeAccount, extConfig } = accountInfo;
      this.isForwarderFreeVersion =
        registerSource === 'forwarder' && isFreeAccount;
      this.isFreeVersion = isFreeAccount;
      if ('subAccountId' in accountInfo) {
        const { subAccountPermissionCodeList = [], ...otherInfo } = accountInfo;
        this.accountInfo = markRaw(otherInfo);
        this.permissionCodeList = markRaw(subAccountPermissionCodeList);
        const permissionCodeMaps = subAccountPermissionCodeList.reduce(
          (obj, code) => {
            obj[code] = 1;
            return obj;
          },
          {}
        );
        this.permissionCodeMaps = markRaw(permissionCodeMaps);
      } else {
        this.isMainAccount = true;
        this.accountInfo = markRaw(accountInfo);
      }
    },
    async getAppAccountExtConfigOptions() {
      return getAppAccountExtConfigOptions({
        isNeedDefaultOptions: false,
      }).then((res) => {
        const {
          defaultOptions = {},
          supportedCountryList,
          supportedTimezoneList,
          supportedCurrencyList,
          supportedCountryAndTimezoneList,
        } = res || {};
        this.accountExtConfigOptionMap.defaultOptions = defaultOptions;
        this.accountExtConfigOptionMap.supportedCurrencyList =
          supportedCurrencyList;
        this.accountExtConfigOptionMap.supportedCountryAndTimezoneList =
          supportedCountryAndTimezoneList.map((item) => {
            const { countryLabel, timezoneLabel } = item;
            return {
              ...item,
              label: `${timezoneLabel}（${countryLabel}）`,
            };
          });
        this.accountExtConfigOptionMap.supportedCountryMap =
          supportedCountryList.reduce((obj, item) => {
            const { value } = item;
            obj[value] = item;
            return obj;
          }, {});
        this.accountExtConfigOptionMap.supportedTimezoneMap =
          supportedTimezoneList.reduce((obj, item) => {
            const { value } = item;
            obj[value] = item;
            return obj;
          }, {});
      });
    },
    hasExtConfig() {
      const extConfig = this.accountInfo.extConfig || {};
      return !isEmpty(extConfig);
    },
  },
});
