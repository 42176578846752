import useUserStore from '@/store/useUserStore';
import { logout } from '@/store';
import { distributionSiteUrl } from '@/router/config';

export default {
  async onLaunch(options = {}) {
    const userStore = useUserStore();
    const errorHandler = () => {
      // 使用redirectTo跳转 防止后退白屏
      logout('reLaunch').then(() => this.closeSplashscreen());
    };

    // 每次打开应用 重新登录
    // #ifndef H5
    let isToDistributionSite = false;
    // #ifdef MP-WEIXIN
    const scene = options.scene;
    const path = options.path || '';
    const query = options.query || {};
    const availableScene = [1065, 1286];
    // 小程序扫二维码跳转分销小站不需要重登陆，场景值为URL Scheme打开小程序场景值
    isToDistributionSite =
      availableScene.includes(scene) && path === distributionSiteUrl;
    if (isToDistributionSite) {
      const code = query.code || '';
      if (code) {
        return uni.redirectTo({
          url: `/${distributionSiteUrl}?code=${code}`,
        });
      }
    }
    // #endif
    const isReLoginSuccess = await userStore.reLogin();
    if (!isReLoginSuccess) {
      return errorHandler();
    }
    // #endif
    const pages = getCurrentPages();
    const currentPage = pages[pages.length - 1];
    const pagePath = currentPage?.$page?.fullPath;
    const routerUrl = '/pages/warehouse/index';
    if (routerUrl !== pagePath) {
      uni
        .switchTab({
          url: routerUrl,
        })
        .finally(() => {
          this.closeSplashscreen();
        });
    } else {
      this.closeSplashscreen();
    }
  },
  methods: {
    closeSplashscreen() {
      // #ifdef APP-PLUS
      plus.navigator.closeSplashscreen();
      // #endif
    },
  },
};
